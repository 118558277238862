import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  name_fr: string
  name_en: string
  loading: boolean
  title: string
  currency: string
  alpha3: string
  alpha2: string
  minPrice: string
  ceedowCommission: string
  isOpen: "yes" | "no"
  isEdit?: boolean
}

export default function FormCountry({
  handleSubmit,
  handleInputChange,
  name_fr,
  name_en,
  loading,
  title,
  alpha3,
  alpha2,
  currency,
  minPrice,
  ceedowCommission,
  isOpen = "no",
  isEdit = true,
}: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name_fr"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom de pays en FR"
                  fullWidth
                  variant="outlined"
                  value={name_fr}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name_en"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom de pays en EN"
                  fullWidth
                  value={name_en}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="alpha2"
                  type="text"
                  onChange={handleInputChange}
                  label="Alpha2 de pays"
                  fullWidth
                  value={alpha2}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="alpha3"
                  type="text"
                  onChange={handleInputChange}
                  label="Alpha3 de pays"
                  fullWidth
                  value={alpha3}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="currency"
                  type="text"
                  onChange={handleInputChange}
                  label="Devise"
                  fullWidth
                  value={currency}
                  variant="outlined"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="minPrice"
                  type="number"
                  onChange={handleInputChange}
                  label="Minimum de prix pour Paiment Pro"
                  fullWidth
                  value={minPrice}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="ceedowCommission"
                  type="number"
                  onChange={handleInputChange}
                  label="Commission de Ceedow"
                  fullWidth
                  value={ceedowCommission}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel
                    style={{ color: "white" }}
                    id="demo-radio-buttons-group-label"
                  >
                    Ouverture Pays
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="no"
                    name="isOpen"
                    value={isOpen}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "white",
                            },
                          }}
                        />
                      }
                      label="OUI"
                      color="white"
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "white",
                            },
                          }}
                        />
                      }
                      label="NON"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
}
