import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"
import { InfinityListItem } from "../../../types/InfinityListItem"

export interface transporterPriceState {
  pagination: Pagination
  query: string
  zones: InfinityListItem[]
  transporters: InfinityListItem[]
}

const initialState: transporterPriceState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
  transporters: [],
  zones: [],
}

export const transporterPriceSlice = createSlice({
  name: "transporterPrice",
  initialState,
  reducers: {
    settransporterPricePagination: (
      state,
      action: PayloadAction<Pagination>,
    ) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    settransporterPriceQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    setTransporterPriceZones: (
      state,
      action: PayloadAction<InfinityListItem[]>,
    ) => {
      state.zones = action.payload
    },
    setTransporterPriceTransporters: (
      state,
      action: PayloadAction<InfinityListItem[]>,
    ) => {
      state.transporters = action.payload
    },
  },
})
export const selecttransporterPricePagination = (state: RootState) =>
  state.transporterPrice.pagination
export const selecttransporterPriceQuery = (state: RootState) =>
  state.transporterPrice.query
export const selecttransporterPriceZones = (state: RootState) =>
  state.transporterPrice.zones
export const selecttransporterPriceTransporters = (state: RootState) =>
  state.transporterPrice.transporters
export const {
  settransporterPricePagination,
  settransporterPriceQuery,
  setTransporterPriceTransporters,
  setTransporterPriceZones,
} = transporterPriceSlice.actions
export default transporterPriceSlice.reducer
