/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Paper, Typography, colors } from "@mui/material"
import { graphql } from "../../../gql"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { Order, SortOrder } from "../../../gql/graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import Loader from "../../Common/Loader/Loader"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { useAppDispatch } from "../../../store/app/hooks"
import NoDataStatistic from "../../Common/NoDataStatistic/NoDataStatistic"
import Chart from "../../Common/Chart/Chart"
import CustomTable from "../../Common/CustomTable/CustomTable"
import { MRT_ColumnDef } from "material-react-table"
import StarRating from "./StarRating/StarRating"
import moment from "moment"
import { havePermissions, isSuperAdmin } from "../../../utils/permissions"
import ToggleSwitch from "../../Admin/FormAdmin/TableRoles/SwitchButton/SwitchButton"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getUserCountryId } from "../../../utils/authToken"

const GET_CRAFTMAN = graphql(`
  query GetDetailCraftmanQuery(
    $where: UserWhereUniqueInput!
    $aggregateOrderWhere2: OrderWhereInput
    $aggregateNoticeOrderWhere2: NoticeOrderWhereInput
  ) {
    user(where: $where) {
      id
      phoneNumber
      email
      role
      name
      lastName
      lang
      active
      yearOfExperience
      ProfilePhoto {
        url
      }
      PhotoAchievements {
        name
        url
      }
      Categories {
        id
        name_fr
      }
      ItemTypes {
        id
        name_fr
      }
      MaterialTypes {
        id
        name_fr
      }
      country {
        id
        name_fr
        currency
      }
    }
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        _all
      }
    }
    aggregateNoticeOrder(where: $aggregateNoticeOrderWhere2) {
      _count {
        _all
      }
      _avg {
        note
      }
    }
  }
`)
const GET_ORDERS = graphql(`
  query OrdersCraftman(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    orders(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      createdAt
      Customer {
        lastName
      }
      status
      Categories {
        name_fr
      }
      ItemTypes {
        name_fr
      }
      MaterialTypes {
        name_fr
      }
    }
  }
`)

const updateCraftmanMutation = graphql(`
  mutation UpdateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
      active
    }
  }
`)

const DetailCraftman = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const id = params.id ? parseInt(params.id, 10) : 0
  const size = 5

  const [getCraftman, { data, loading }] = useLazyQuery(GET_CRAFTMAN)
  const [active, setActive] = useState(false)
  const [getOrders, { data: dataOrders, loading: loadingOrders, refetch }] =
    useLazyQuery(GET_ORDERS)

  const [updatecraftman] = useMutation(updateCraftmanMutation)

  const colorsGlobal = [colors.blue[400], colors.green[400]]
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(0)

  const queryName = useCallback(() => {
    const someQuery = {
      some: {
        name_fr: {
          search: query,
        },
      },
    }
    return query
      ? {
          OR: [
            {
              Categories: {
                ...someQuery,
              },
            },
            {
              ItemTypes: {
                ...someQuery,
              },
            },
            {
              MaterialTypes: {
                ...someQuery,
              },
            },
          ],
        }
      : {}
  }, [query])

  useEffect(() => {
    getOrders({
      variables: {
        take: size,
        skip: page * size,
        orderBy: {
          NoticeOrders: {
            _count: SortOrder.Desc,
          },
        },
        where: {
          craftmanId: {
            equals: id,
          },
          ...queryName(),
        },
      },
    })
  }, [id, page, queryName])

  useEffect(() => {
    getCraftman({
      variables: {
        where: {
          id,
        },
        aggregateNoticeOrderWhere2: {
          Order: {
            is: {
              craftmanId: {
                equals: id,
              },
            },
          },
        },
        aggregateOrderWhere2: {
          craftmanId: {
            equals: id,
          },
        },
      },
      onCompleted(data) {
        if (!isSuperAdmin()) {
          if (data.user?.country?.id != getUserCountryId()) {
            navigate("/")
            return
          }
        }
        setActive(data.user?.active as boolean)
      },
    })
  }, [id])

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un artisan"))
    navigate(`/craftman/edit/${id}`)
  }

  const handleWallet = (id: number) => {
    dispatch(setTitle("Cagnotte artisan"))
    navigate(`/craftman/wallet/${id}`)
  }

  const getStatistic = useCallback(() => {
    const value_order = data?.aggregateOrder?._count?._all || 0
    const value_rate = data?.aggregateNoticeOrder?._count?._all || 0
    return !value_order && !value_rate
      ? []
      : [
          {
            label: "Nombre commande",
            value: value_order,
            color: colorsGlobal[0],
          },
          {
            label: "Nombre avis",
            value: value_rate,
            color: colorsGlobal[1],
          },
        ]
  }, [data])

  const handleGoDetailOrder = (id: number) => {
    dispatch(setTitle("Detail d'une commande"))
    navigate(`/order/detail/${id}`)
  }

  const columns = useMemo<MRT_ColumnDef<Order>[]>(
    () => [
      { accessorKey: "id", header: "ID", enableEditing: false, size: 80 },
      {
        accessorFn: (row) => moment(row.createdAt).format("DD MMM YYYY"),
        header: "Date de demande",
      },
      {
        accessorKey: "Customer.lastName",
        header: "Prénom client",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    [],
  )

  const handleAddOrder = () => {
    dispatch(setTitle("Ajouter une commande"))
    navigate(`/order/add`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleChange = () => {
    setActive(!active)
    updatecraftman({
      variables: {
        data: {
          active: {
            set: !active,
          },
        },
        where: {
          id,
        },
      },
      update(cache, { errors, data }) {
        if (!errors && data) {
          const active = data.updateOneUser?.active
          cache.modify({
            id: cache.identify({
              __typename: "User",
              id,
            }),
            fields: {
              active(existing = false) {
                return existing != active ? active : existing
              },
            },
          })
        }
      },
      onCompleted(data) {
        dispatch(
          setOpenSnackbar({
            message:
              "La disponibilité de l'artisan a été mise à jour avec succès.",
            status: "success",
          }),
        )
      },
      onError: (error) => {
        setActive(!active)
        dispatch(
          setOpenSnackbar({
            message:
              "L'action a été refusée. Veuillez réessayer s'il vous plaît.",
          }),
        )
      },
    })
  }

  if (loading && !page && !query) return <Loader />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HeaderAdd title="Information de l'artisan" variant="h4" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>ID:</b> {data?.user?.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Prénom:</b> {data?.user?.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Catégorie:</b>{" "}
                        {data?.user?.Categories.flatMap(
                          (el) => el.name_fr,
                        ).toString()}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      direction={"row"}
                      display={"flex"}
                      alignItems={"normal"}
                    >
                      <Typography mr={1}>
                        <b>Avis des utilisateurs: </b>{" "}
                      </Typography>
                      <StarRating
                        note={data?.aggregateNoticeOrder?._avg?.note || 1}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      direction={"row"}
                      display={"flex"}
                      alignItems={"normal"}
                    >
                      <Box>
                        <Typography mr={1}>
                          <b>Disponibilité: </b>{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <ToggleSwitch
                          checked={active}
                          handleChange={handleChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      {havePermissions(["craftman.change"]) && (
                        <Button
                          onClick={() => handleEdit(data?.user?.id || 0)}
                          sx={{ mr: 1 }}
                          color="info"
                          variant="outlined"
                          size="small"
                        >
                          Modifier
                        </Button>
                      )}
                      {havePermissions(["craftman.view"]) && (
                        <Button
                          onClick={() => handleWallet(data?.user?.id || 0)}
                          sx={{ mr: 1 }}
                          color="warning"
                          variant="outlined"
                          size="small"
                        >
                          Voir sa cagnotte
                        </Button>
                      )}
                      {havePermissions(["order.create"]) && (
                        <Button
                          onClick={handleAddOrder}
                          color="success"
                          variant="outlined"
                          size="small"
                        >
                          Ajouter une commande
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <HeaderAdd title="Statistique de l'artisan" variant="h4" />
                </Grid>
                <Grid item xs={12}>
                  {getStatistic().length ? (
                    <Chart data={getStatistic()} />
                  ) : (
                    <NoDataStatistic />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <HeaderAdd title="Liste des commandes" variant="h4" />
            </Grid>
            <Grid item xs={12} mt={2}>
              <CustomTable
                columns={columns}
                data={dataOrders?.orders || []}
                lableAddNew=""
                rootLisName={""}
                isLoading={loadingOrders}
                showProgressBars={loadingOrders}
                rowCount={data?.aggregateOrder._count?._all || 0}
                handleChangePagination={(value) => setPage(value.page)}
                pageIndex={page}
                pageSize={size}
                searchValue={query}
                onChangeSearchValue={setQuery}
                isHaveView={true}
                handleView={handleGoDetailOrder}
                rowsPerPageOptions={[size]}
                handleRefresh={handleRefresh}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DetailCraftman
