import { Button, Grid, Paper, TextField } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import CustomListCategory from "../../Common/CustomListCategory/CustomListCategory"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  getCategory: (category: InfinityListItem) => void
  name_fr: string
  name_en: string
  weight: string
  loading: boolean
  title: string
  category: InfinityListItem
  getCountry: (segmentation: InfinityListItem[]) => void
  countryDefault: InfinityListItem[]
}

export default function FormItemType({
  handleSubmit,
  handleInputChange,
  name_fr,
  name_en,
  loading,
  title,
  category,
  weight,
  countryDefault,
  getCountry,
  getCategory,
}: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name_fr"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom de type article en FR"
                  fullWidth
                  variant="outlined"
                  value={name_fr}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name_en"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom de type article en EN"
                  fullWidth
                  value={name_en}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="weight"
                  type="number"
                  onChange={handleInputChange}
                  label="Poids d'article"
                  fullWidth
                  variant="outlined"
                  value={weight}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomListCategory
                  categoryDefault={category}
                  getCategory={getCategory}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomListCountry
                  countryDefault={countryDefault}
                  getCountry={(value) =>
                    getCountry(value as InfinityListItem[])
                  }
                  multiple={true}
                  label="Pays*"
                  required={false}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
}
