interface Image {
  name: string
}

const useImage = () => {
  const url = "https://webservice.ceedow.world"
  const noImage =
    "https://ceedow.fr/preprod/statics/images/wavy-bodies-blush-design-opps.png"

  const getUri = (uri: string | Image | null | undefined): string => {
    if (!uri) return noImage

    if (typeof uri === "string") {
      if (uri.startsWith("https") || uri.startsWith("data:")) {
        return uri.startsWith("/") ? uri.substring(1) : uri
      } else {
        return `${url}/images/${uri}`
      }
    }

    if (typeof uri === "object" && "name" in uri && uri.name) {
      if (uri.name.startsWith("https") || uri.name.startsWith("data:")) {
        return uri.name
      } else {
        return `${url}/images/${uri}`
      }
    }
    return noImage
  }
  return {
    getUri,
    noImage,
  }
}

export default useImage
