import React from "react"
import { Box, Typography } from "@mui/material"
import MessageImage from "../MessageImage/MessageImage"
import { ValidOrderEvidence } from "../../../../../../gql/graphql"

interface Props {
  validOrderEvidence: ValidOrderEvidence
}

const MessageValidOrder = ({ validOrderEvidence }: Props) => {
  return (
    <Box>
      <Box sx={{ marginY: 2 }}>
        <Typography>
          J'ai bien reçu la commande et voici les photos qui illustrent la
          commande.
        </Typography>
      </Box>
      <Box>
        <Box sx={{ marginY: 2 }}>
          <Typography variant="body2" fontWeight="bold">
            Photos de la réalisation reçue:
          </Typography>
        </Box>
        <Box sx={{ marginY: 2 }}>
          <MessageImage files={validOrderEvidence.photos} />
        </Box>
      </Box>
    </Box>
  )
}

export default MessageValidOrder
