import { Checkbox, FormControlLabel } from "@mui/material"

interface Props {
  value: string
  handleUpdate: (value: string) => void
  label: string
}

const HeaderChecked = ({ handleUpdate, value, label }: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value === "yes"}
          onChange={() => handleUpdate(value)}
          sx={{
            color: "white",
            "&.Mui-checked": {
              color: "white",
            },
          }}
        />
      }
      label={label}
    />
  )
}

export default HeaderChecked
