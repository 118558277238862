import { gql } from "@apollo/client"

export const CUSTOMER_FRAGMENT = gql(`
  fragment CustomerFragment on User {
    id
    phoneNumber
    email
    role
    name
    lastName
    lang
    ProfilePhoto {
      url
    }
    country {
        id
        name_fr
        currency
    }
  }
`)
