import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import {
  NewFileInput,
  UpdateUserInput,
  UserRoleType,
} from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import FormUser from "../FormUser/FormUser"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { uploadFiles } from "../../../utils/uploadFiles"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"
import { updateCacheCustomer } from "../../../caches/updateCacheCustomer"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"

const updateCustomeMutation = graphql(`
  mutation updateCustomer($updateUserInput: UpdateUserInput!) {
    updateUserAccount(updateUserInput: $updateUserInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        lang
        ProfilePhoto {
          url
        }
        country {
          id
          name_fr
        }
      }
    }
  }
`)

const getOneCustomerQuery = graphql(`
  query GetOneCustomerQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      phoneNumber
      email
      role
      name
      lastName
      lang
      ProfilePhoto {
        id
        name
        url
      }
      country {
        id
        name_fr
        currency
      }
    }
  }
`)

export default function EditUser() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [updatecustomer, { loading }] = useMutation(updateCustomeMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [rePassword, setRePassword] = useState<string>("")
  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )
  const [profileIdDelete, setProfileIdDelete] = useState<number | undefined>(
    undefined,
  )

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [country, setCountry] = useState<InfinityListItem>(defaultValue)

  const [user, setUser] = useState<UpdateUserInput>({
    id,
    email: "",
    lastName: "",
    role: UserRoleType.Customer,
    phoneNumber: "",
    name: "",
    lang: "fr",
    description: "",
  })

  const [getCustomer, { loading: loadingInit, error }] = useLazyQuery(
    getOneCustomerQuery,
    {
      onCompleted(data) {
        const newUser = data.user
        if (!isSuperAdmin()) {
          if (data.user?.country?.id != getUserCountryId()) {
            navigate("/")
            return
          }
        }
        setUser({
          id: newUser?.id || 0,
          email: newUser?.email || "",
          lastName: newUser?.lastName || "",
          phoneNumber: newUser?.phoneNumber || "",
          name: newUser?.name || "",
          lang: newUser?.lang || "fr",
          role: UserRoleType.Customer,
        })

        if (data.user?.ProfilePhoto) {
          const { url, id, name } = data.user?.ProfilePhoto
          setDefaulPhoto({ url, id, name })
        }
        if (newUser?.country) {
          const newcountry: InfinityListItem = {
            value: newUser?.country.id.toString() || "",
            label: newUser?.country.name_fr || "",
          }
          setCountry(newcountry)
        }
      },
    },
  )

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleSignup = ({ profilePhoto }: { profilePhoto?: NewFileInput }) => {
    updatecustomer({
      variables: {
        updateUserInput: {
          ...user,
          id,
          profilePhoto,
          countryId: Number(country.value),
          profilePhotoDeleteId: profileIdDelete,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/user/list")
      },
      update: (cache, { data }) => {
        updateCacheCustomer({
          action: "update",
          cache,
          entryData: data?.updateUserAccount.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (file) {
      setIsLoading(true)
      const filesToUploads = [file]
      uploadFiles({
        files: filesToUploads,
        cbErr(err) {
          setOpenSnackbar({
            message:
              "Veuillez choisir des fichiers plus léger, s'il vous plait",
          })
          setIsLoading(false)
        },
      }).then((files) => {
        const profilePhoto = files?.find((el) => el.name === file?.name)
        handleSignup({
          profilePhoto: profilePhoto,
        })
      })
    } else {
      handleSignup({})
    }
  }

  const onFileUpload = (file: File) => {
    setFile(file)
  }

  const onClearUpload = (file: MinTypeFile) => {
    setFile(null)
    if (file.id) {
      setProfileIdDelete(file.id)
    }
  }

  useEffect(() => {
    getCustomer({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormUser
      getPhoneNumber={(value) => setUser({ ...user, phoneNumber: value })}
      getRePassword={(value) => setRePassword(value)}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      onClearUpload={onClearUpload}
      onFileUpload={onFileUpload}
      rePassword={rePassword}
      title={"Modifier un client"}
      email={user.email}
      lastName={user.lastName}
      name={user.name}
      password={""}
      phoneNumber={user.phoneNumber || ""}
      defaulPhoto={defaulPhoto}
      havePassword={false}
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
