import { gql } from "@apollo/client"

export const TRANSPORTERPRICE_FRAGMENT = gql(`
  fragment TransporterPriceFragment on TransporterPrice {
    id
    weightMin        
    weightMax        
    price
    TransporterZones {
      id
      name
      Countries {
        id
        name_fr
      }
    }
    Transporters {
      id
      name
    }
  }
`)
