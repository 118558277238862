import { useCallback, useMemo, useState } from "react"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import {
  ActionTransaction,
  CraftmanWallet,
  SortOrder,
  WalletType,
} from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Box, Button, Tooltip } from "@mui/material"
import { useAppDispatch } from "../../../store/app/hooks"
import {
  setCloseAlert,
  setLoadingDelete,
  setOpenAlert,
} from "../../../store/features/alert/alertSlice"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { getUserCountryId } from "../../../utils/authToken"
import { whereInputClause } from "../../../utils/permissions"

export const CRAFTMANWALLETS = graphql(`
  query CraftmanWallets(
    $where: CraftmanWalletWhereInput
    $orderBy: [CraftmanWalletOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregateCraftmanWalletWhere2: CraftmanWalletWhereInput
  ) {
    craftmanWallets(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      craftmanId
      operator
      orderId
      createdAt
      price
      phoneNumber
      rib
      walletType
      accountNumber
      username
      action
      description
      Craftman {
        id
        country {
          id
          currency
        }
      }
    }
    aggregateCraftmanWallet(where: $aggregateCraftmanWalletWhere2) {
      _count {
        _all
      }
    }
  }
`)

const VALIDATE_CRAFTMAN_PAIMENT = graphql(`
  mutation ValidCraftmanPaiment($paimentId: Float!, $craftmanId: Float!) {
    validCraftmanPaiment(paimentId: $paimentId, craftmanId: $craftmanId) {
      id
    }
  }
`)

const ListAskVersment = () => {
  const countryId = getUserCountryId()
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  })
  const [query, setQuery] = useState("")
  const dispatch = useAppDispatch()

  const queryName = useCallback(() => {
    return query
      ? {
          AND: [
            {
              action: {
                in: [ActionTransaction.Asking, ActionTransaction.Debit],
              },
            },
            {
              OR: [
                {
                  Craftman: {
                    is: {
                      OR: [
                        {
                          id: {
                            equals: Number(query),
                          },
                        },
                        {
                          lastName: {
                            search: query,
                          },
                        },
                        {
                          workshopName: {
                            search: query,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  id: {
                    equals: Number(query),
                  },
                },
                {
                  Order: {
                    is: {
                      OR: [
                        {
                          id: {
                            equals: Number(query),
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        }
      : {
          action: {
            in: [ActionTransaction.Asking, ActionTransaction.Debit],
          },
        }
  }, [query])

  const { loading, data, refetch } = useQuery(CRAFTMANWALLETS, {
    variables: {
      take: pagination.size,
      skip: pagination.page * pagination.size,
      orderBy: { createdAt: SortOrder.Desc },
      where: {
        ...queryName(),
        ...whereInputClause({
          Craftman: {
            is: {
              country: {
                is: {
                  id: {
                    equals: countryId,
                  },
                },
              },
            },
          },
        }),
      },
    },
  })

  const [validatePaimentCraftman] = useMutation(VALIDATE_CRAFTMAN_PAIMENT)

  const style = { backgroundColor: "black", padding: 5 }

  const columns = useMemo<MRT_ColumnDef<CraftmanWallet>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.createdAt
            ? moment(row.createdAt).format("DD MMM YYYY")
            : "Pas définie",
        header: "Date de demande",
        enableClickToCopy: true,
      },
      {
        accessorKey: "craftmanId",
        header: "Id artisan",
        enableClickToCopy: true,
      },
      {
        accessorKey: "price",
        header: "Montant",
        enableClickToCopy: true,
      },
      {
        accessorKey: "Craftman.country.currency",
        header: "Devise",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.walletType == WalletType.Bank
            ? "Banque"
            : row.walletType == WalletType.Mobile
            ? "Mobile money"
            : "Paypal",
        header: "Mode de paiment",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.walletType == WalletType.Bank ? (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Numero de compte:{" "}
                  <span style={style}>{row.accountNumber}</span>
                </li>
                <li>
                  RIB: <span style={style}>{row.rib}</span>
                </li>
              </ul>
            </div>
          ) : row.walletType == WalletType.Mobile ? (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Numéro de téléphone:{" "}
                  <span style={style}>{row.phoneNumber}</span>
                </li>
                <li>
                  Opérateur: <span style={style}>{row.operator}</span>
                </li>
              </ul>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Pseudo: <span style={style}>{row.username}</span>
                </li>
              </ul>
            </div>
          ),
        header: "Infomation de dépôt",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    setPagination(pagination)

  const onChangeSearchValue = (value: string) => setQuery(value)

  const handleValidate = (craftmanId: number, paimentId: number) => {
    dispatch(setLoadingDelete(true))
    validatePaimentCraftman({
      variables: {
        craftmanId,
        paimentId,
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le versment a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        refetch({
          take: pagination.size,
          skip: pagination.page * pagination.size,
          orderBy: [{ createdAt: SortOrder.Desc }],
          where: {
            ...queryName(),
            ...whereInputClause({
              Craftman: {
                is: {
                  country: {
                    is: {
                      id: {
                        equals: countryId,
                      },
                    },
                  },
                },
              },
            }),
          },
        })
      },
    })
  }

  const handleShow = (craftmanId: number, paimentId: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidate(craftmanId, paimentId),
        message:
          "Êtes-vous vraiment sûr de vouloir valider cette de demande de versment ?",
        isLoading: false,
      }),
    )
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.craftmanWallets || []}
      lableAddNew=""
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateCraftmanWallet._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={pagination.page}
      pageSize={pagination.size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleRefresh={handleRefresh}
      isHaveAction={false}
      isCustomAction={true}
      renderAction={({ row, table }) => (
        <Box sx={{ display: "flex" }}>
          {row.original.action === ActionTransaction.Asking ? (
            <Tooltip title="Valider la demande">
              <Button
                variant="contained"
                color="success"
                style={{ background: "green" }}
                onClick={() =>
                  handleShow(row.original.craftmanId, row.original.id)
                }
              >
                Valider
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Demande de versement est déjà validé">
              <p>c'est déjà validé</p>
            </Tooltip>
          )}
        </Box>
      )}
    />
  )
}

export default ListAskVersment
