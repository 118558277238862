import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListTransporterZone from "../../Common/CustomListTransporterZone/CustomListTransporterZone"
import { useState } from "react"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  getTransporterZone: (transporterZones: InfinityListItem[]) => void
  transporterZoneDefault: InfinityListItem[]
  phoneNumber: string
  webSite: string
  address: string
  email: string
  name: string
  loading: boolean
  title: string
  isLocal: "yes" | "no"
}

export default function FormTransporter({
  handleSubmit,
  handleInputChange,
  email,
  phoneNumber,
  name,
  loading,
  title,
  webSite,
  address,
  getTransporterZone,
  transporterZoneDefault,
  isLocal,
}: Props) {
  const [countries, setCountries] = useState<string[]>([])
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom de transporteur"
                  fullWidth
                  variant="outlined"
                  value={name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  type="email"
                  onChange={handleInputChange}
                  label="Email de transporteur"
                  fullWidth
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phoneNumber"
                  type="text"
                  onChange={handleInputChange}
                  label="Téléphone de transporteur"
                  fullWidth
                  value={phoneNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="webSite"
                  type="url"
                  onChange={handleInputChange}
                  label="Lien de transporteur"
                  fullWidth
                  value={webSite}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address"
                  type="text"
                  onChange={handleInputChange}
                  label="Adresse de transporteur"
                  fullWidth
                  value={address}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel
                    style={{ color: "white" }}
                    id="demo-radio-buttons-group-label"
                  >
                    C'est un transporteur local ?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="no"
                    name="isLocal"
                    value={isLocal}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "white",
                            },
                          }}
                        />
                      }
                      label="OUI"
                      color="white"
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "white",
                            },
                          }}
                        />
                      }
                      label="NON"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomListTransporterZone
                  transporterZoneDefault={transporterZoneDefault}
                  getTransporterZone={(value) =>
                    getTransporterZone(value as InfinityListItem[])
                  }
                  getCountries={(names) => {
                    const uniqueNames = new Set(names)
                    setCountries(Array.from(uniqueNames))
                  }}
                  multiple
                  required={false}
                />
              </Grid>
              {Boolean(countries.length) && (
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ textDecorationLine: "underline" }}
                    >
                      Pays inclus dans la zone:
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography>{countries.join(", ")}</Typography>
                  </Box>
                </Grid>
              )}
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
}
