import { gql } from "@apollo/client"

export const CATEGORY_FRAGMENT = gql(`
  fragment CategoryFragment on Category {
    id
    name_fr
    name_en
    Segmentation {
      id
      name_fr
      name_en
    }
    Countries {
      id
      name_fr
    }
  }
`)
