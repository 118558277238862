import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./store/app/store"
import App from "./App"
import "./index.css"
import { IntlProvider } from "react-intl"
// load locale json files
import en from "./lang/en-US.json"
import fr from "./lang/fr.json"
import { getNativatorLanguage } from "./utils/language"

import client from "./apollo/client"
import { ApolloProvider } from "@apollo/client"


const messages: any = {
  en,
  fr,
}

// Detect user's locale
let locale = getNativatorLanguage()

// If locale is not supported, use English
if (!messages[locale]) {
  locale = "fr"
}

const localeMessages = messages[locale]

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client()}>
        <IntlProvider
          defaultLocale="fr"
          messages={localeMessages}
          locale={"fr"}
        >
          <App />
        </IntlProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
)
