import { getToken } from "firebase/messaging"
import { messaging } from "./firebase"
import config from "../config/config"

export const getOrRegisterServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          },
        )
      })
  }
  throw new Error("The browser doesn`t support service worker.")
}

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey: config.nofitication.vapidKey,
      serviceWorkerRegistration,
    }),
  )
