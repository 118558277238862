import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheTransporter } from "../../../caches/updateCacheTransporter"
import FormTransporter from "../FormTransporter/FormTransporter"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"

const editTransporterMutation = graphql(`
  mutation updateOneTransporter(
    $data: TransporterUpdateInput!
    $where: TransporterWhereUniqueInput!
  ) {
    updateOneTransporter(data: $data, where: $where) {
      id
      name
      phoneNumber
      email
      webSite           
      address
      isLocal
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
    }
  }
`)

const getOneTransporterQuery = graphql(`
  query getOneTransporterQuery($where: TransporterWhereUniqueInput!) {
    transporter(where: $where) {
      id
      name
      phoneNumber
      email
      webSite           
      address
      isLocal
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
    }
  }
`)

export default function EditTransporter() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0
  const [transporterZones, setTransporterZones] = useState<InfinityListItem[]>(
    [],
  )
  const [oldTransporterZones, setOldTransporterZones] = useState<
    InfinityListItem[]
  >([])

  const [transporter, setTransporter] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    webSite: "",
    address: "",
    isLocal: "no"
  })

  const [getTransporter, { loading: loadingInit, error }] = useLazyQuery(
    getOneTransporterQuery,
    {
      onCompleted(data) {
        setTransporter({
          name: data.transporter?.name || "",
          phoneNumber: data.transporter?.phoneNumber || "",
          email: data.transporter?.email || "",
          webSite: data.transporter?.webSite || "",
          address: data.transporter?.address || "",
          isLocal: data?.transporter?.isLocal ? "yes" : "no",
        })
        if (data.transporter?.TransporterZones) {
          const transporterZones = data.transporter?.TransporterZones.map(
            (el) => ({
              value: el.id.toString(),
              label: el.name,
            }),
          )
          setOldTransporterZones(transporterZones)
          setTransporterZones(transporterZones)
        }
      },
    },
  )

  const [updateTransporter, { loading }] = useMutation(editTransporterMutation)

  const handleInputChange = (event: any) => {
    setTransporter({
      ...transporter,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateTransporter = (e: any) => {
    e.preventDefault()
    if (!transporterZones.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir les zones de ce transporteur.",
        }),
      )
      return
    }
    updateTransporter({
      variables: {
        data: {
          name: {
            set: transporter.name,
          },
          phoneNumber: {
            set: transporter.phoneNumber,
          },
          email: {
            set: transporter.email,
          },
          webSite: {
            set: transporter.webSite,
          },
          address: {
            set: transporter.address,
          },
          isLocal: {
            set: transporter.isLocal === "yes",
          },
          TransporterZones: {
            connect: transporterZones.map((el) => ({ id: +el.value })),
            disconnect: oldTransporterZones.map((el) => ({ id: +el.value })),
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/transporter/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporter({
          action: "update",
          cache,
          entryData: data?.updateOneTransporter,
        })
      },
    })
  }

  useEffect(() => {
    getTransporter({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getTransporterZone = (transporterZones: InfinityListItem[]) => {
    setTransporterZones(transporterZones)
  }

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormTransporter
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateTransporter}
      loading={loading}
      email={transporter.email}
      phoneNumber={transporter.phoneNumber}
      name={transporter.name}
      title="Modifier un transporteur"
      getTransporterZone={getTransporterZone}
      transporterZoneDefault={transporterZones}
      webSite={transporter.webSite}           
      address={transporter.address}
      isLocal={transporter.isLocal as "yes" | "no"}
    />
  )
}
