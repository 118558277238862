import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selectOrderPagination,
  selectOrderQuery,
  setOrderPagination,
  setOrderQuery,
} from "../../../store/features/order/orderSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  Action,
  Order,
  OrderWhereInput,
  SortOrder,
  Status,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheOrder } from "../../../caches/updateCacheOrder"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import moment from "moment"
import useOrder from "../../../hooks/useOrder"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import OrderImages from "./OrderImages/OrderImages"
import { getUserCountryId } from "../../../utils/authToken"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"

export const LIST_ORDERS = graphql(`
  query OrdersList(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $pricesOrderBy2: [OrderPriceOrderByWithRelationAndSearchRelevanceInput!]
    $aggregateOrderWhere2: OrderWhereInput
  ) {
    orders(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      oldID
      deadline
      status
      createdAt
      updatedAt
      delivryDate
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
      orderInvoice {
        matiere
        materiale
        quantity
        craftsmanAmount
        shippingCost
        serviceCharge
        orderFee
        currency
        paimenproPrice
      }
      Customer {
        id
        email
        name
        lastName
        phoneNumber
        country {
          currency
        }
      }
      Craftman {
        id
        email
        name
        lastName
        phoneNumber
      }
      Country {
        id
        name_fr
        currency
      }
      Categories {
        id
        name_fr
      }
      ItemTypes {
        id
        name_fr
      }
      MaterialTypes {
        id
        name_fr
      }
      Photos {
        id
        name
        url
      }
      Prices(orderBy: $pricesOrderBy2) {
        price
      }
    }
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_ORDER = graphql(`
  mutation DeleteOneOrder($where: OrderWhereUniqueInput!) {
    deleteOneOrder(where: $where) {
      id
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListOrder {
    categories {
      id
      name_fr
    }
    itemTypes {
      name_fr
      id
    }
    materialTypes {
      name_fr
      id
    }
  }
`)

const ARCHIVE_MANY = graphql(`
  mutation UpdateManyOrder(
    $data: OrderUpdateManyMutationInput!
    $where: OrderWhereInput
  ) {
    updateManyOrder(data: $data, where: $where) {
      count
    }
  }
`)

interface Props {
  extraQuery?: OrderWhereInput
}

const ListOrder = ({ extraQuery }: Props) => {
  const { page, size } = useAppSelector(selectOrderPagination)
  const query = useAppSelector(selectOrderQuery)
  // eslint-disable-next-line no-restricted-globals
  const href = location.href

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const url = new URL(href)

  const countryId = getUserCountryId()

  // Get the search parameter value
  var status = url.searchParams.get("status")

  const dispatch = useAppDispatch()
  const { getCustomerOrderPrice } = useOrder()
  const navigate = useNavigate()

  const { loading: loadingFilter, data: dataFilter } = useQuery(LIST_FILTER)
  const [archiveMany] = useMutation(ARCHIVE_MANY)

  const getValueFilter = (el: { name_fr: string; id: number }) => {
    return {
      label: el.name_fr,
      value: el.id.toString(),
      text: el.name_fr,
    }
  }

  const [filterMobile, setFilterMobile] = useState("")
  const [ids, setIds] = useState<number[]>([])

  const handleChangeFilterMobile = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string,
  ) => {
    setFilterMobile(newFilter)
  }

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const getFilters: () => OrderWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: OrderWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Date de livraison":
              accumulator = {
                ...accumulator,
                delivryDate: {
                  gte: value.toDate(),
                },
              }
              break
            case "Date de commande":
              accumulator = {
                ...accumulator,
                createdAt: {
                  gte: value.toDate(),
                },
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "oldID":
              accumulator = {
                ...accumulator,
                oldID: {
                  equals: Number(value),
                },
              }
              break
            case "Prix du client":
              accumulator = {
                ...accumulator,
                Prices: {
                  some: {
                    price: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "Devise":
              accumulator = {
                ...accumulator,
                Customer: {
                  is: {
                    country: {
                      is: {
                        currency: {
                          search: value,
                        },
                      },
                    },
                  },
                },
              }
              break
            case "Catégorie":
              accumulator = {
                ...accumulator,
                Categories: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "Type article":
              accumulator = {
                ...accumulator,
                ItemTypes: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "Type matière":
              accumulator = {
                ...accumulator,
                MaterialTypes: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "ID du client":
              accumulator = {
                ...accumulator,
                customerId: {
                  equals: Number(value),
                },
              }
              break
            case "ID de l'artisan":
              accumulator = {
                ...accumulator,
                craftmanId: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              Customer: {
                is: {
                  name: {
                    search: query,
                  },
                },
              },
            },
            {
              Customer: {
                is: {
                  lastName: {
                    search: query,
                  },
                },
              },
            },
            {
              Craftman: {
                is: {
                  name: {
                    search: query,
                  },
                },
              },
            },
            {
              Craftman: {
                is: {
                  lastName: {
                    search: query,
                  },
                },
              },
            },
            {
              id: {
                equals: Number(query),
              },
            },
            {
              oldID: {
                equals: Number(query),
              },
            },
          ],
        }
      : {}
  }, [query])

  const whereCluase = whereInputClause({
    country: {
      is: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const [getOrders, { loading, data, refetch }] = useLazyQuery(LIST_ORDERS)

  useEffect(() => {
    getOrders({
      variables: {
        take: size,
        skip: page * size,
        orderBy: [
          ...(filterMobile === "order_current"
            ? [
                {
                  createdAt: SortOrder.Desc,
                },
              ]
            : [
                {
                  id: SortOrder.Desc,
                },
                {
                  NoticeOrders: {
                    _count: SortOrder.Desc,
                  },
                },
              ]),
        ],
        pricesOrderBy2: {
          createdAt: SortOrder.Desc,
        },
        where: {
          ...(status === "all_paid"
            ? {
                ActionOrders: {
                  some: {
                    action: {
                      equals: Action.Paid,
                    },
                  },
                },
              }
            : {
                status: {
                  in:
                    status === "paid" || filterMobile === "order_paid"
                      ? [Status.Paid, Status.Delivry]
                      : status === "archive"
                      ? [Status.Archive]
                      : status === "pending"
                      ? [Status.Paid, Status.Pending]
                      : [Status.Paid, Status.Delivry, Status.Pending],
                },
              }),
          Craftman: {
            is: {
              ...whereCluase,
            },
          },
          ...(filterMobile === "order_have_price" && {
            Prices: {
              some: {},
            },
          }),
          ...queryName(),
          ...getFilters(),
          ...extraQuery,
        },
        aggregateOrderWhere2: {
          Craftman: {
            is: {
              ...whereCluase,
            },
          },
          status: {
            in:
              status === "paid" || filterMobile === "order_paid"
                ? [Status.Paid, Status.Delivry]
                : [Status.Paid, Status.Delivry, Status.Pending],
          },
          ...queryName(),
          ...extraQuery,
        },
      },
    })
  }, [
    getOrders,
    page,
    query,
    queryName,
    size,
    status,
    getFilters,
    filterMobile,
  ])

  const [deleteOrder] = useMutation(DELETE_ORDER)

  const style = { backgroundColor: "black", padding: 5 }
  const columns = useMemo<MRT_ColumnDef<Order>[]>(
    () => [
      {
        accessorFn: (row) => {
          return (
            <div style={{ width: "100%" }}>
              <ul>
                <li>
                  Status de paiment:{" "}
                  <span style={style}>
                    {row.status == Status.Paid ||
                    row.status == Status.Delivry ||
                    status == "all_paid" ||
                    status === "paid" ||
                    filterMobile === "order_paid"
                      ? "Payé"
                      : "En attente"}
                  </span>
                </li>
                <li>
                  Quantité(s):{" "}
                  <span style={style}>{row.orderInvoice.quantity}</span>
                </li>
                <li>
                  Montant artisan:{" "}
                  <span style={style}>
                    {row.orderInvoice.craftsmanAmount.toFixed(0)}
                  </span>
                </li>
                <li>
                  Montant transport:{" "}
                  <span style={style}>
                    {row.orderInvoice.shippingCost.toFixed(0)}
                  </span>
                </li>
                <li>
                  Montant commission:{" "}
                  <span style={style}>
                    {row.orderInvoice.serviceCharge.toFixed(0)}
                  </span>
                </li>
                <li>
                  Montant Commande:{" "}
                  <span style={style}>
                    {row.orderInvoice.orderFee.toFixed(0)}
                  </span>
                </li>
                <li>
                  Devise: <span style={style}>{row.orderInvoice.currency}</span>
                </li>
              </ul>
            </div>
          )
        },
        header: "Paiment",
        enableClickToCopy: true,
        enableColumnFilter: false,
        size: 300,
      },
      {
        accessorFn: (row) => {
          return (
            <div>
              <OrderImages images={row.Photos} />
            </div>
          )
        },
        header: "Photos commande",
        enableClickToCopy: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.createdAt
            ? moment(row.createdAt).format("DD MMM YYYY [à] HH[h]mm")
            : "Pas definie",
        header: "Date de commande",
        enableClickToCopy: true,
        filterVariant: "date",
      },
      {
        accessorFn: (row) => getCustomerOrderPrice(row.Prices),
        header: "Prix du client",
        enableClickToCopy: true,
        filterVariant: "text",
      },
      {
        accessorFn: (row) =>
          row.delivryDate
            ? moment(row.delivryDate).format("DD MMM YYYY [à] HH[h]mm")
            : "Pas definie",
        header: "Date de livraison",
        enableClickToCopy: true,
        filterVariant: "date",
      },
      {
        accessorFn: (row) => `${row.Customer?.country?.currency}`,
        header: "Devise",
        enableClickToCopy: true,
        filterVariant: "text",
      },
      {
        accessorFn: (row) =>
          `${row?.Categories?.flatMap((el) => el.name_fr)?.join("; ")}`,
        header: "Catégorie",
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.categories.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.ItemTypes?.flatMap((el) => el.name_fr)?.join("; ")}`,
        header: "Type article",
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.itemTypes.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.MaterialTypes?.flatMap((el) => el.name_fr)?.join("; ")}`,
        header: "Type matière",
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.materialTypes.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) => `${row.Customer?.id}`,
        header: "ID du client",
        enableClickToCopy: true,
        filterVariant: "text",
      },
      {
        accessorFn: (row) => row?.Craftman?.id || "Pas définie",
        header: "ID de l'artisan",
        enableClickToCopy: true,
        filterVariant: "text",
      },
    ],
    [dataFilter],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setOrderPagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteOrder({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La commande a été supprimée avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheOrder({
          action: "delete",
          cache,
          entryData: {
            ...data?.deleteOneOrder,
            __typename: "Order",
          },
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cette commande ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) => dispatch(setOrderQuery(value))

  const handleEdit = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => {
          dispatch(setTitle("Modifier une commande"))
          dispatch(setCloseAlert())
          navigate(`/order/edit/${id}`)
        },
        message: "Êtes-vous vraiment sûr de vouloir modifier cette commande ?",
        isLoading: false,
      }),
    )
  }

  const handleView = (id: number) => {
    dispatch(setTitle("Detail d'une commande"))
    navigate(`/order/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleValidArchiveMany = () => {
    dispatch(setLoadingDelete(true))
    archiveMany({
      variables: {
        data: {
          status: {
            set: Status.Archive,
          },
        },
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "Les commandes ont été archivé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleArchiveGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidArchiveMany(),
        message: "Êtes-vous vraiment sûr de vouloir achiver ces commandes ?",
        isLoading: false,
      }),
    )
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters={true}
      columns={columns}
      data={data?.orders || []}
      lableAddNew="Créer une nouvelle commande"
      rootLisName={"/order/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateOrder._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      selectedIds={ids}
      handleSelected={handleSelected}
      handleArchiveGroup={handleArchiveGroup}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["order.delete"]) ? handleOpenDeleteDialog : undefined
      }
      isHaveHeaderAction={havePermissions(["order.create"])}
      handleEdit={havePermissions(["order.change"]) ? handleEdit : undefined}
      isHaveView={havePermissions(["order.view"])}
      handleView={havePermissions(["order.view"]) ? handleView : undefined}
      isHaveAction={havePermissions([
        "order.view",
        "order.change",
        "order.delete",
      ])}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      handleRefresh={handleRefresh}
      headerMobileFilter={
        <div
          style={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            WebkitOverflowScrolling: "touch", // Pour une meilleure performance sur iOS
            marginBottom: "10px",
          }}
        >
          <ToggleButtonGroup
            color="info"
            value={filterMobile}
            exclusive
            onChange={handleChangeFilterMobile}
            aria-label="Filtre"
            style={{
              display: "inline-flex", // Assure que les boutons restent sur une ligne
            }}
          >
            <ToggleButton value="order_current">Date récente</ToggleButton>
            <ToggleButton value="order_paid">Payé</ToggleButton>
            <ToggleButton value="order_have_price">
              Proposition de prix
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      }
    />
  )
}

export default ListOrder
