import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheFaqs } from "../../../caches/updateCacheFaqs"
import FormFaqs from "../FormFaqs/FormFaqs"
import Loader from "../../Common/Loader/Loader"

const editFaqsMutation = graphql(`
  mutation UpdateOneFaq($data: FaqUpdateInput!, $where: FaqWhereUniqueInput!) {
    updateOneFaq(data: $data, where: $where) {
      id
      createdAt
      description_en
      title_en
      title_fr
      updatedAt
      description_fr
    }
  }
`)

const getOneFaqsQuery = graphql(`
  query GetFaq($where: FaqWhereUniqueInput!) {
    getFaq(where: $where) {
      id
      createdAt
      description_en
      title_en
      title_fr
      updatedAt
      description_fr
    }
  }
`)

export default function EditFaqs() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [faqs, setFaqs] = useState({
    title_fr: "",
    title_en: "",
    description_fr: "",
    description_en: "",
  })

  const [getFaqs, { loading: loadingInit, error }] = useLazyQuery(
    getOneFaqsQuery,
    {
      onCompleted(data) {
        setFaqs({
          title_en: data?.getFaq?.title_en || "",
          title_fr: data?.getFaq?.title_fr || "",
          description_en: data?.getFaq?.description_en || "",
          description_fr: data?.getFaq?.description_fr || "",
        })
      },
    },
  )

  const [updateFaqs, { loading }] = useMutation(editFaqsMutation)

  const handleInputChange = (event: any) => {
    setFaqs({
      ...faqs,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateFaqs = (e: any) => {
    e.preventDefault()
    updateFaqs({
      variables: {
        data: {
          title_fr: {
            set: faqs.title_fr,
          },
          title_en: {
            set: faqs.title_en,
          },
          description_en: {
            set: faqs.description_en,
          },
          description_fr: {
            set: faqs.description_fr,
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/faqs/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheFaqs({
          action: "update",
          cache,
          entryData: data?.updateOneFaq,
        })
      },
    })
  }

  useEffect(() => {
    getFaqs({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormFaqs
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateFaqs}
      loading={loading}
      description_fr={faqs.description_fr}
      description_en={faqs.description_en}
      title_fr={faqs.title_fr}
      title_en={faqs.title_en}
      title="Modifier un faqs"
    />
  )
}
