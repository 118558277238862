// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { MessagePayload, getMessaging, onMessage } from "firebase/messaging"
import config from "../config/config"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC90_-wdTZ_ARZ-qL0EuIRTpv9fVf9QCZ0",
  authDomain: "ceedow-5b718.firebaseapp.com",
  projectId: "ceedow-5b718",
  storageBucket: "ceedow-5b718.appspot.com",
  messagingSenderId: "365702570752",
  appId: "1:365702570752:web:f14c9b2eb365cbe428c9e5",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

// Messaging service
export const messaging = getMessaging(app)
export default storage

export const onForegroundMessage: () => Promise<MessagePayload> = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)))
