import { gql } from "@apollo/client"

export const CRAFTMAN_FRAGMENT = gql(`
  fragment CraftmanFragment on User {
    id
    phoneNumber
    oldID
    email
    role
    name
    lastName
    lang
    yearOfExperience
    ProfilePhoto {
      url
    }
    PhotoAchievements {
      url
    }
    Categories {
      id
      name_fr
    }
    ItemTypes {
      id
      name_fr
    }
    MaterialTypes {
      id
      name_fr
    }
    country {
        id
        name_fr
        currency
    }
  }
`)
