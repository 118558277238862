import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getAuthToken } from "../../utils/authToken"
import { havePermissions, isSuperAdmin } from "../../utils/permissions"

interface Props {
  children: React.ReactNode
  isProtected?: boolean
  isInitRoute?: boolean
  permissions: string[]
  isOnlySuperAdmin?: boolean
}

export function ProtectedRoute({
  children,
  isProtected = true,
  isInitRoute,
  permissions,
  isOnlySuperAdmin,
}: Props) {
  const navigate = useNavigate()

  const conditionView = () => {
    const token = getAuthToken()
    if (isProtected && !token) {
      navigate("/login")
    } else if (
      (isProtected && token && !isInitRoute && !havePermissions(permissions)) ||
      (!isProtected && token)
    ) {
      navigate("/")
    }
  }

  useEffect(() => {
    const token = getAuthToken()
    if (isOnlySuperAdmin) {
      if (isProtected && token && !isSuperAdmin()) {
        navigate("/")
      } else {
        conditionView()
      }
    } else {
      conditionView()
    }
  }, [isProtected, isInitRoute, permissions, navigate])

  return <>{children}</>
}
