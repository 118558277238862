import * as React from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ListItem, { ListItemButtonStyled, Menu } from "./ListItem/ListItem"
import ListIcon from "@mui/icons-material/List"
import PublicIcon from "@mui/icons-material/Public"
import FlagCircleIcon from "@mui/icons-material/FlagCircle"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import CategoryIcon from "@mui/icons-material/Category"
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PersonIcon from "@mui/icons-material/Person"
import PeopleIcon from "@mui/icons-material/People"
import { ListItemIcon, ListItemText } from "@mui/material"
import { useAppDispatch } from "../../../../store/app/hooks"
import { Logout } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import {
  setCloseAlert,
  setOpenAlert,
} from "../../../../store/features/alert/alertSlice"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import ClassIcon from "@mui/icons-material/Class"
import EmailIcon from "@mui/icons-material/Email"
import SegmentIcon from "@mui/icons-material/Segment"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"

const ListMain = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const menus = [
    {
      name: "Tableau de board",
      title: "Tableau de board",
      icon: <DashboardIcon color="inherit" />,
      route: "/",
      permissions: [
        "askPaiment.view",
        "deliveryMan.view",
        "order.view",
        "craftman.view",
        "customer.view",
      ],
      isBoard: true,
    },
    {
      name: "Clients",
      title: "Clients",
      icon: <PersonIcon />,
      route: "/user/list",
      isMain: true,
      permissions: ["customer.create", "customer.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un client",
          icon: <FlagCircleIcon />,
          route: "user/add",
          permissions: ["customer.create"],
        },
        {
          name: "Listes",
          title: "Liste des utilisateurs",
          icon: <RecentActorsIcon />,
          route: "user/list",
          permissions: ["customer.view"],
        },
      ],
    },
    {
      name: "Commandes",
      title: "Commandes",
      icon: <ShoppingCartIcon />,
      route: "/order/list",
      isMain: true,
      permissions: ["order.create", "order.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une commande",
          icon: <FlagCircleIcon />,
          route: "order/add",
          permissions: ["order.create"],
        },
        {
          name: "Listes",
          title: "Liste des commandes",
          icon: <ListIcon />,
          route: "order/list",
          permissions: ["order.view"],
        },
      ],
    },
    // {
    //   name: "Appel d'offre",
    //   title: "Appel d'offre",
    //   icon: <ShoppingCartIcon />,
    //   route: "/tender/list",
    //   isMain: true,
    //   permissions: ["tender.create", "tender.view"],
    //   subMenu: [
    //     {
    //       name: "Ajouter",
    //       title: "Ajouter un appel d'offre",
    //       icon: <FlagCircleIcon />,
    //       route: "tender/add",
    //       permissions: ["tender.create"],
    //     },
    //     {
    //       name: "Listes",
    //       title: "Liste des appels d'offres",
    //       icon: <ListIcon />,
    //       route: "tender/list",
    //       permissions: ["tender.view"],
    //     },
    //   ],
    // },
    {
      name: "Artisans",
      title: "Artisans",
      icon: <PersonIcon />,
      route: "/craftman/list",
      isMain: true,
      permissions: ["craftman.create", "craftman.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un artisant",
          icon: <FlagCircleIcon />,
          route: "craftman/add",
          permissions: ["craftman.create"],
        },
        {
          name: "Listes",
          title: "Liste des artisants",
          icon: <ListIcon />,
          route: "craftman/list",
          permissions: ["craftman.view"],
        },
      ],
    },
    {
      name: "Pays",
      title: "Pays",
      icon: <PublicIcon />,
      route: "/country/list",
      isMain: true,
      permissions: ["country.create", "country.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un pays",
          icon: <FlagCircleIcon />,
          route: "country/add",
          permissions: ["country.create"],
        },
        {
          name: "Listes",
          title: "Liste des pays",
          icon: <ListIcon />,
          route: "country/list",
          permissions: ["country.view"],
        },
        {
          name: "Pays des artisans",
          title: "Pays des artisans",
          icon: <ListIcon />,
          route: "country/craftman-list",
          permissions: ["country.view"],
        },
      ],
    },
    {
      name: "Segmentation",
      icon: <SegmentIcon />,
      title: "Segmentations",
      route: "/segmentation/list",
      isMain: true,
      permissions: ["segmentation.create", "segmentation.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une segmentation",
          icon: <ClassIcon />,
          route: "segmentation/add",
          permissions: ["segmentation.create"],
        },
        {
          name: "Listes",
          title: "Liste des segmentations",
          icon: <ListIcon />,
          route: "segmentation/list",
          permissions: ["segmentation.view"],
        },
      ],
    },
    {
      name: "Faqs",
      icon: <SegmentIcon />,
      title: "Faqs",
      route: "/faqs/list",
      isMain: true,
      permissions: ["faqs.create", "faqs.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un FAQS",
          icon: <ClassIcon />,
          route: "faqs/add",
          permissions: ["faqs.create"],
        },
        {
          name: "Listes",
          title: "Liste des FAQS",
          icon: <ListIcon />,
          route: "faqs/list",
          permissions: ["faqs.view"],
        },
      ],
    },
    {
      name: "Catégories",
      icon: <CategoryIcon />,
      title: "Catégories",
      route: "/category/list",
      isMain: true,
      permissions: ["category.create", "category.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une catégorie",
          icon: <ClassIcon />,
          route: "category/add",
          permissions: ["category.create"],
        },
        {
          name: "Listes",
          title: "Liste des catégories",
          icon: <ListIcon />,
          route: "category/list",
          permissions: ["category.view"],
        },
      ],
    },
    {
      name: "Type articles",
      icon: <FormatColorTextIcon />,
      title: "Type articles",
      route: "/item-type/list",
      isMain: true,
      permissions: ["itemType.create", "itemType.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une type article",
          icon: <ClassIcon />,
          route: "item-type/add",
          permissions: ["itemType.create"],
        },
        {
          name: "Listes",
          title: "Liste des type articles",
          icon: <ListIcon />,
          route: "item-type/list",
          permissions: ["itemType.view"],
        },
      ],
    },
    {
      name: "Type matières",
      icon: <CategoryIcon />,
      title: "Type matières",
      route: "/material-type/list",
      permissions: ["materialType.create", "materialType.view"],
      isMain: true,
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une type matière",
          icon: <ClassIcon />,
          route: "material-type/add",
          permissions: ["materialType.create"],
        },
        {
          name: "Listes",
          title: "Liste des type matières",
          icon: <ListIcon />,
          route: "material-type/list",
          permissions: ["materialType.view"],
        },
      ],
    },
    {
      name: "Emails",
      icon: <EmailIcon />,
      title: "Emails",
      route: "/email/list",
      permissions: ["email.view"],
      isMain: true,
      subMenu: [
        {
          name: "Listes",
          title: "Liste des emails",
          icon: <ListIcon />,
          route: "email/list",
          permissions: ["email.view"],
        },
      ],
    },
    {
      name: "Administrateurs",
      icon: <SupervisedUserCircleIcon />,
      title: "Liste des lieux",
      route: "/admin/list",
      isMain: true,
      permissions: ["admin.create", "admin.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un administrateur",
          icon: <PersonAddIcon />,
          route: "admin/add",
          permissions: ["admin.create"],
        },
        {
          name: "Listes",
          icon: <PeopleIcon />,
          title: "Liste des administrateurs",
          route: "admin/list",
          permissions: ["admin.create"],
        },
      ],
    },
    {
      name: "Transporteur",
      icon: <SegmentIcon />,
      title: "Transporteur",
      route: "/transporter/list",
      isMain: true,
      permissions: ["transporter.create", "transporter.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un transporteur",
          icon: <ClassIcon />,
          route: "transporter/add",
          permissions: ["transporter.create"],
        },
        {
          name: "Listes",
          title: "Liste des transporteurs",
          icon: <ListIcon />,
          route: "transporter/list",
          permissions: ["transporter.view"],
        },
      ],
    },
    {
      name: "Transporteur Zone",
      icon: <SegmentIcon />,
      title: "Transporteur Zone",
      route: "/transporter-zone/list",
      isMain: true,
      permissions: ["transporterZone.create", "transporterZone.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter une zone",
          icon: <ClassIcon />,
          route: "transporter-zone/add",
          permissions: ["transporterZone.create"],
        },
        {
          name: "Listes",
          title: "Liste des zones",
          icon: <ListIcon />,
          route: "transporter-zone/list",
          permissions: ["transporterZone.view"],
        },
      ],
    },
    {
      name: "Transporteur Tarif",
      icon: <SegmentIcon />,
      title: "Transporteur Tarif",
      route: "/transporter-price/list",
      isMain: true,
      permissions: ["transporterPrice.create", "transporterPrice.view"],
      subMenu: [
        {
          name: "Ajouter",
          title: "Ajouter un tarif",
          icon: <ClassIcon />,
          route: "transporter-price/add",
          permissions: ["transporterPrice.create"],
        },
        {
          name: "Listes",
          title: "Liste des tarifs",
          icon: <ListIcon />,
          route: "transporter-price/list",
          permissions: ["transporterPrice.view"],
        },
      ],
    },
  ]
  const handleLogout = () => {
    sessionStorage.clear()
    dispatch(setCloseAlert())
    navigate("/login")
  }

  const handleOpenDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: handleLogout,
        message: "Êtes-vous vraiment sûr de vouloir se déconnecter ?",
        isLoading: false,
      }),
    )
  }

  return (
    <React.Fragment>
      {menus.map((_el: Menu) => (
        <ListItem menu={_el} key={_el.name} />
      ))}
      <ListItemButtonStyled sx={{ mx: 1 }} onClick={() => handleOpenDialog()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary={"Déconnexion"} />
      </ListItemButtonStyled>
    </React.Fragment>
  )
}

export default ListMain
