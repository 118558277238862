import { useCallback, useMemo, useState } from "react"
import { MRT_ColumnDef } from "material-react-table"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { graphql } from "../../../../gql"
import { Pagination } from "../../../../types/Pagination"
import CustomTable from "../../../Common/CustomTable/CustomTable"
import Loader from "../../../Common/Loader/Loader"
import { CraftmanWallet, SortOrder } from "../../../../gql/graphql"
import ModifyJackpot from "./ModifyJackpot/ModifyJackpot"

export const CRAFTMANWALLETS = graphql(`
  query CraftmanActionWallets(
    $where: CraftmanWalletWhereInput
    $orderBy: [CraftmanWalletOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregateCraftmanWalletWhere2: CraftmanWalletWhereInput
  ) {
    craftmanWallets(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      price
      description
      action
      createdAt
      Craftman {
        id
        country {
          id
          currency
        }
      }
    }
    aggregateCraftmanWallet(where: $aggregateCraftmanWalletWhere2) {
      _count {
        _all
      }
    }
  }
`)
interface Props {
  id: number
  jackpot?: number
  handleRefresh: () => void
}

const ListAction = ({ id, jackpot, handleRefresh }: Props) => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  })
  const [query, setQuery] = useState("")
  const [open, setOpen] = useState(false)

  const handleAdd = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              id: {
                equals: Number(query),
              },
            },
            {
              price: {
                equals: Number(query),
              },
            },
            {
              Order: {
                is: {
                  OR: [
                    {
                      id: {
                        equals: Number(query),
                      },
                    },
                  ],
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(CRAFTMANWALLETS, {
    variables: {
      take: pagination.size,
      skip: pagination.page * pagination.size,
      orderBy: { createdAt: SortOrder.Desc },
      where: {
        craftmanId: {
          equals: id,
        },
        ...queryName(),
      },
    },
  })

  const columns = useMemo<MRT_ColumnDef<CraftmanWallet>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.createdAt
            ? moment(row.createdAt).format("DD MMM YYYY")
            : "Pas définie",
        header: "Date d'action",
        enableClickToCopy: true,
      },
      {
        accessorKey: "price",
        header: "Montant",
        enableClickToCopy: true,
      },
      {
        accessorKey: "Craftman.country.currency",
        header: "Devise",
        enableClickToCopy: true,
      },
      {
        accessorKey: "action",
        header: "Action",
        enableClickToCopy: true,
      },
      {
        accessorKey: "description",
        header: "Motifs",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    setPagination(pagination)

  const onChangeSearchValue = (value: string) => setQuery(value)

  const handleRefreshBody = () => {
    window.location.reload()
  }

  const cbSuccessJackpot = () => {
    Promise.allSettled([handleRefresh(), refetch()]).finally(() => {
      handleClose()
    })
  }

  if (loading && !query) return <Loader />

  return (
    <>
      <ModifyJackpot
        cbSuccess={cbSuccessJackpot}
        craftmanId={id}
        handleClose={handleClose}
        open={open}
        jackpot={jackpot as number}
      />
      <CustomTable
        columns={columns}
        data={data?.craftmanWallets || []}
        lableAddNew={"Modifier la cagnotte"}
        isLoading={loading}
        showProgressBars={loading}
        rowCount={data?.aggregateCraftmanWallet._count?._all || 0}
        handleChangePagination={handleChangePagination}
        pageIndex={pagination.page}
        pageSize={pagination.size}
        handleRefresh={handleRefreshBody}
        isHaveAction={false}
        onChangeSearchValue={onChangeSearchValue}
        handleAdd={handleAdd}
      />
    </>
  )
}

export default ListAction
