import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface OrderState {
  pagination: Pagination
  query: string
}

const initialState: OrderState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setOrderQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})
export const selectOrderPagination = (state: RootState) =>
  state.order.pagination
export const selectOrderQuery = (state: RootState) => state.order.query
export const { setOrderPagination, setOrderQuery } = orderSlice.actions
export default orderSlice.reducer
