import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { getUserCountryId } from "../../../utils/authToken"
import { isSuperAdmin, whereInputClause } from "../../../utils/permissions"

const LIST_MATERIALS = graphql(`
  query getMaterialTypes(
    $where: MaterialTypeWhereInput
    $orderBy: [MaterialTypeOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    materialTypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      name_fr
      id
    }
  }
`)

interface Props {
  categoryId?: number
  getMaterialTypes: (
    materialItems: InfinityListItem[] | InfinityListItem,
  ) => void
  materialTypesDefault: InfinityListItem[] | InfinityListItem
  multiple?: boolean
  craftmanId?: number
}
const CustomListMaterialType = ({
  getMaterialTypes,
  materialTypesDefault,
  categoryId,
  multiple = true,
  craftmanId,
}: Props) => {
  const [materialTypes, setMaterialTypes] = useState(materialTypesDefault)

  const countryId = getUserCountryId()
  const whereCluase = whereInputClause({
    Countries: {
      some: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const [query, setQuery] = useState("")
  const [open, setOpen] = useState(false)
  const size = 500

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name_fr: { search: query, mode: QueryMode.Insensitive } },
            { name_en: { search: query, mode: QueryMode.Insensitive } },
            { name_en: { contains: query, mode: QueryMode.Insensitive } },
            { name_en: { contains: query, mode: QueryMode.Insensitive } },
            { name_en: { endsWith: query, mode: QueryMode.Insensitive } },
            { name_en: { endsWith: query, mode: QueryMode.Insensitive } },
            { name_en: { startsWith: query, mode: QueryMode.Insensitive } },
            { name_en: { startsWith: query, mode: QueryMode.Insensitive } },
          ],
        }
      : {}
  }, [query])

  const queryCategory = useCallback(() => {
    return categoryId
      ? {
          categoryId: {
            equals: categoryId,
          },
        }
      : {}
  }, [categoryId])

  const queryCratman = useCallback(() => {
    return craftmanId
      ? {
          Users: {
            some: {
              id: {
                equals: craftmanId,
              },
            },
          },
        }
      : {}
  }, [craftmanId])

  const { loading, data, fetchMore } = useQuery(LIST_MATERIALS, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name_fr: SortOrder.Asc },
      ...((categoryId || query || !isSuperAdmin()) && {
        where: {
          ...queryName(),
          ...queryCategory(),
          ...queryCratman(),
          ...whereCluase,
        },
      }),
    },
  })

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = async () => {
    setOpen(true)
  }

  const onEndReached = () => {
    fetchMore({
      variables: {
        skip: data?.materialTypes.length,
      },
    })
  }

  useEffect(() => {
    if (materialTypesDefault) {
      setMaterialTypes(materialTypesDefault)
    }
  }, [materialTypesDefault])

  const datas = data?.materialTypes

  const materialTypesData =
    loading || !datas
      ? []
      : datas.map((el) => ({ label: el.name_fr, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleClose}
      handleOpen={handleOpen}
      open={open}
      defaultValue={materialTypesDefault}
      keyName="label"
      multiple={multiple}
      label="Type matière(s)*"
      onSearch={handleSearch}
      options={open ? materialTypesData : []}
      loading={loading}
      onChange={(value) => {
        if (value) {
          setMaterialTypes(value)
          getMaterialTypes(value)
        }
      }}
      query={query}
      value={materialTypes}
      onEndReached={onEndReached}
      required={false}
    />
  )
}

export default CustomListMaterialType
