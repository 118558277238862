import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useQuery } from "@apollo/client"
import {
  selectUserPagination,
  selectUserQuery,
  setUserPagination,
  setQuery,
} from "../../../store/features/user/userSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { Email, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"

export const LIST_EMAILS = graphql(`
  query Emails(
    $orderBy: [EmailOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $where: EmailWhereInput
    $aggregateEmailWhere2: EmailWhereInput
  ) {
    emails(orderBy: $orderBy, take: $take, skip: $skip, where: $where) {
      id
      subject
      content
      createdAt
      Sender {
        lastName
        email
      }
    }
    aggregateEmail(where: $aggregateEmailWhere2) {
      _count {
        _all
      }
    }
  }
`)

const ListAdmin = () => {
  const { page, size } = useAppSelector(selectUserPagination)
  const query = useAppSelector(selectUserQuery)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const countryId = getUserCountryId()

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ subject: { search: query } }, { content: { search: query } }],
        }
      : {}
  }, [query])

  const whereCluase = whereInputClause({
    Sender: {
      is: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const { loading, data, refetch } = useQuery(LIST_EMAILS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { createdAt: SortOrder.Desc },
      where: {
        ...whereCluase,
        ...queryName(),
      },
      aggregateEmailWhere2: {
        ...whereCluase,
        ...queryName(),
      },
    },
  })

  const columns = useMemo<MRT_ColumnDef<Email>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => moment(row.createdAt).format("DD MMM YYYY"),
        header: "Date d'envoi",
        enableClickToCopy: true,
      },
      {
        accessorKey: "subject",
        header: "Objet",
        enableClickToCopy: true,
      },
      {
        accessorKey: "content",
        header: "Extrait de contenue",
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row?.recipients?.toString() || "Admins",
        header: "Destinataires",
        enableClickToCopy: true,
      },
      {
        accessorKey: "Sender.email",
        header: "Email de l'expéditeur",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setUserPagination(pagination))

  const onChangeSearchValue = (value: string) => dispatch(setQuery(value))

  const handleView = (id: number) => {
    navigate(`/email/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.emails || []}
      lableAddNew=""
      rootLisName={""}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateEmail._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      isHaveView={havePermissions(["email.view"])}
      handleView={havePermissions(["email.view"]) ? handleView : undefined}
      handleRefresh={handleRefresh}
    />
  )
}

export default ListAdmin
