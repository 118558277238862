import { Avatar, Box, Stack, Typography } from "@mui/material"
import PreviewAudio from "./PreviewAudio/PreviewAudio"
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined"
import { red } from "@mui/material/colors"
import { green } from "@mui/material/colors"
import SendIcon from "@mui/icons-material/Send"
import { useEffect, useRef, useState } from "react"
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js"
import WaveSurfer from "wavesurfer.js"
import { useMutation } from "@apollo/client"
import { CREATE_MESSAGE } from "../Chat"
import { createFileFromBlob, uploadFile } from "../../../../../utils/uploadFile"
import { useAppDispatch } from "../../../../../store/app/hooks"
import { setOpenSnackbar } from "../../../../../store/features/snackbar/snackbarSlice"
import Loader from "../../../../Common/Loader/Loader"

interface DataAudio {
  blob: Blob
  url: string
}
interface Props {
  onClearFormAudio: () => void
  chatId: number
  receiverId: number
}

const FormAudio = ({ onClearFormAudio, chatId, receiverId }: Props) => {
  const [isRecording, setIsRecording] = useState(true)
  const [isPausePreview, setIsPausePreview] = useState(false)
  const style = { cursor: "pointer", color: red[300] }

  //Blob data of recording
  const [dataBlob, setDataBlob] = useState<DataAudio | null>(null)

  //State indicated the state od creating message loading
  const [isLoading, setIsLoading] = useState(false)

  //State indicated that user send the record audio on reconding
  const [isStop, setIsStop] = useState(false)

  //Ref of record and wavesurfer
  let record = useRef<RecordPlugin | null>(null)
  let wavesurfer = useRef<WaveSurfer | null>(null)

  //State of record time
  const [time, setTime] = useState("00:00")

  //Definition of create message mutation
  const [createMessage] = useMutation(CREATE_MESSAGE)

  const dispatch = useAppDispatch()

  //One fucntion  create message
  const onCreateMessage = (blob: Blob) => {
    setIsLoading(true)
    //Create file from Blob audio
    const file = createFileFromBlob(blob, "image.mp3")

    //Upload file firebase storage before sending message
    uploadFile({
      cbError: ({ message }) => {
        dispatch(
          setOpenSnackbar({
            message,
          }),
        )
        setIsLoading(false)
        setIsStop(false)
      },
      cbOk: (file) => {
        createMessage({
          variables: {
            chatId,
            receiverId,
            photos: [file],
            content: "",
          },
          onCompleted: () => {
            setIsLoading(false)
            setIsStop(false)
            handleClearFormAudio()
          },
        })
      },
      file,
      getPrecent: (value) => {},
    })
  }

  useEffect(() => {
    // Create an instance of WaveSurfer
    if (wavesurfer.current) {
      wavesurfer.current.destroy()
    }

    let newWavesurfer = WaveSurfer.create({
      container: "#mic",
      waveColor: "grey",
      height: 25,
      autoCenter: true,
    })

    wavesurfer.current = newWavesurfer
    //Create record plugin
    record.current = newWavesurfer.registerPlugin(
      RecordPlugin.create({ renderRecordedAudio: false, mimeType: "audio/ogg" }),
    )

    if (record.current) {
      //force recording
      record?.current
        .startRecording()
        .then(() => setIsRecording(true))
        .catch(() => setIsRecording(false))

      //Create url of audio if pause
      record.current.on("record-pause", (blob) => {
        const recordedUrl = URL.createObjectURL(blob)
        setDataBlob({
          blob,
          url: recordedUrl,
        })
        setIsRecording(false)
      })

      //Update Time of recording
      record.current.on("record-progress", (time) => {
        setTime(updateProgress(time))
      })
    }
    return () => {
      record?.current?.destroy()
      wavesurfer?.current?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, wavesurfer])

  useEffect(() => {
    if (record && record.current) {
      //send data url of audio if force send
      record.current.on("record-end", (blob) => {
        if (isStop) {
          onCreateMessage(blob)
        }
        record.current?.unAll()
      })
    }
  }, [isStop])

  const updateProgress = (time: number) => {
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":")
    return formattedTime
  }

  // Toogle play and pause on recording
  const togglePausePlay = () => {
    if (record.current) {
      if (record.current.isRecording()) {
        setIsPausePreview(false)
        record.current.pauseRecording()
        wavesurfer.current?.pause()
        setIsRecording(false)
      } else {
        setIsPausePreview(true)
        record.current.resumeRecording()
        wavesurfer.current?.play()
        setIsRecording(true)
      }
    }
  }

  const handleCreateMessage = () => {
    if (!isLoading) {
      if (dataBlob && dataBlob.blob && !isRecording) {
        // Send Directly message if not recording and Blob is avalaible
        onCreateMessage(dataBlob.blob)
      } else {
        // Force stop the recording before sending the message
        setIsStop(true)
        record.current?.stopRecording()
      }
    }
  }

  // Clear and destroy FormAudio components
  const handleClearFormAudio = () => {
    if (wavesurfer && wavesurfer.current) {
      wavesurfer.current.destroy()
    }
    if (record && record.current) {
      record.current?.destroy()
    }
    setIsRecording(false)
    onClearFormAudio()
  }

  return (
    <Stack direction="row" spacing={1} mt={2}>
      <Box>
        {/* Button that destroy FormAudio components */}
        <DeleteOutlineOutlinedIcon
          sx={style}
          onClick={handleClearFormAudio}
          fontSize="large"
        />
      </Box>
      <Box flex={1}>
        {/* Render of wavesuffer recording */}
        <Stack
          direction="row"
          spacing={1}
          mt={0.5}
          style={{
            visibility: isRecording ? "visible" : "hidden",
          }}
        >
          <Box pt={0.5}>
            <Typography variant="h5">{time}</Typography>
          </Box>
          <Box flex={1}>
            <div id="mic" />
          </Box>
        </Stack>
        {/* Render of audio preview record */}
        <PreviewAudio
          url={dataBlob?.url || ""}
          styleContainer={{
            visibility: isRecording ? "hidden" : "visible",
            marginTop: !isRecording ? "-40px" : "0px",
            height: isRecording ? "0px" : "auto",
          }}
          isPause={isPausePreview}
        />
      </Box>
      <Box>
        {/* Toogle record and preview button action */}
        {!isRecording ? (
          <KeyboardVoiceOutlinedIcon
            onClick={togglePausePlay}
            sx={style}
            fontSize="large"
          />
        ) : (
          <PauseCircleOutlinedIcon
            onClick={togglePausePlay}
            fontSize="large"
            sx={style}
          />
        )}
      </Box>
      {/* Button send message */}
      <Avatar
        onClick={handleCreateMessage}
        sx={{
          bgcolor: green[500],
          width: 32,
          height: 32,
          cursor: "pointer",
          mt: 0.3,
        }}
      >
        {isLoading ? <Loader size={20} /> : <SendIcon color="action" />}
      </Avatar>
    </Stack>
  )
}

export default FormAudio
