import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { NewFileInput, UserRoleType } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheCraftman } from "../../../caches/updateCacheCraftman"
import FormCraftman from "../FormCraftman/FormCraftman"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { uploadFiles } from "../../../utils/uploadFiles"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"

// Signin mutation
const addCraftmanMutation = graphql(`
  mutation SignupCrafman($signupInput: SignupInput!) {
    signupUser(signupInput: $signupInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        lang
        yearOfExperience
        minQuantityOrder
        ProfilePhoto {
          url
        }
        PhotoAchievements {
          url
        }
        Categories {
          id
          name_fr
        }
        ItemTypes {
          id
          name_fr
        }
        MaterialTypes {
          id
          name_fr
        }
        country {
          id
          name_fr
          currency
        }
      }
    }
  }
`)

export default function AddCraftman() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [signin, { loading }] = useMutation(addCraftmanMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [files, setFiles] = useState<File[]>([])
  const [rePassword, setRePassword] = useState<string>("")

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [category, setCategory] = useState<InfinityListItem>(defaultValue)
  const [country, setCountry] = useState<InfinityListItem>(defaultValue)
  const [itemTypes, setItemTypes] = useState<InfinityListItem[]>([])
  const [materialTypes, setMaterialTypes] = useState<InfinityListItem[]>([])

  const [user, setUser] = useState({
    email: "",
    lastName: "",
    password: "",
    role: UserRoleType.Craftman,
    phoneNumber: "",
    name: "",
    lang: "fr",
    description: "",
    minQuantityOrder: "",
    yearOfExperience: "",
  })

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleSignup = ({
    profilePhoto,
    photoAchievements,
  }: {
    profilePhoto?: NewFileInput
    photoAchievements?: NewFileInput[]
  }) => {
    signin({
      variables: {
        signupInput: {
          ...user,
          profilePhoto,
          photoAchievements,
          categoryIds: [Number(category.value)],
          countryId: Number(country.value),
          minQuantityOrder: user.minQuantityOrder
            ? Number(user.minQuantityOrder)
            : 0,
          yearOfExperience: user.yearOfExperience
            ? Number(user.yearOfExperience)
            : 0,
          itemIds: itemTypes.map((el) => Number(el.value)),
          materialIds: materialTypes.map((el) => Number(el.value)),
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message, status: "error" }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/craftman/list")
      },
      update: (cache, { data }) => {
        updateCacheCraftman({
          action: "add",
          cache,
          entryData: data?.signupUser.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (user.password !== rePassword) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez vérifier le mot de passe et confirmer le mot de passe !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    if (!files?.length) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les photos de réalisations de l'artisan !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    if (!itemTypes?.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir le(s) type(s) article(s) de l'artisan !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    if (!materialTypes.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir le(s) type(s) matière(s) de l'artisan !!!",
          open: true,
          status: "error",
        }),
      )
      return
    }
    const filesToUploads = file ? [file, ...files] : files
    setIsLoading(true)
    uploadFiles({
      files: filesToUploads,
      cbErr(err) {
        setOpenSnackbar({
          message: "Veuillez choisir des fichiers plus léger, s'il vous plait",
          open: true,
          status: "error",
        })
        setIsLoading(false)
      },
    }).then((files) => {
      const profilePhoto = files?.find((el) => el.name === file?.name)
      const photoAchievements = files?.filter((el) => el.name !== file?.name)
      handleSignup({
        profilePhoto: profilePhoto,
        photoAchievements: photoAchievements,
      })
    })
  }

  const onFileUpload = (file: File) => {
    setFile(file)
  }

  const onFilesUpload = (files: File[]) => {
    setFiles(files)
  }

  const onClearUpload = () => setFile(null)

  const onClearUploads = (file: MinTypeFile) => {
    setFiles((prev) => [...prev.filter((_) => _.name !== file.name)])
  }

  const getCategory = (category: InfinityListItem) => {
    setCategory(category)
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  const getItemTypes = (itemTypes: InfinityListItem[] | InfinityListItem) => {
    if (Array.isArray(itemTypes)) {
      setItemTypes(itemTypes)
    }
  }

  const getMaterialTypes = (
    materialTypes: InfinityListItem[] | InfinityListItem,
  ) => {
    if (Array.isArray(materialTypes)) {
      setMaterialTypes(materialTypes)
    }
  }

  useEffect(() => {
    setMaterialTypes([])
    setItemTypes([])
  }, [category])

  return (
    <FormCraftman
      getPhoneNumber={(value) => setUser({ ...user, phoneNumber: value })}
      getRePassword={(value) => setRePassword(value)}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      onClearUpload={onClearUpload}
      onFileUpload={onFileUpload}
      rePassword={rePassword}
      title={"Ajouter un artisan"}
      email={user.email}
      lastName={user.lastName}
      name={user.name}
      password={user.password}
      phoneNumber={user.phoneNumber || ""}
      categoryDefault={category}
      countryDefault={country}
      getCategory={getCategory}
      getCountry={getCountry}
      getItemTypes={getItemTypes}
      getMaterialTypes={getMaterialTypes}
      itemTypesDefault={itemTypes}
      materialTypesDefault={materialTypes}
      description={user.description || ""}
      categoryId={category.value ? parseInt(category.value) : undefined}
      onClearUploads={onClearUploads}
      onFilesUpload={onFilesUpload}
      required={false}
      yearOfExperience={user.yearOfExperience}
      minQuantityOrder={user.minQuantityOrder}
    />
  )
}
