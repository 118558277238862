import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import useImage from "../../../hooks/useImage"

export interface MinTypeFile {
  id?: number
  name: string
  url: string
}

interface Props {
  onFilesUpload: (files: File[]) => void
  onClearUpload: (file: MinTypeFile) => void
  label: string
  required?: boolean
  id: string
  defaultUrls?: MinTypeFile[]
}

const CustomUploadFiles = ({
  onFilesUpload,
  onClearUpload,
  label,
  required,
  id,
  defaultUrls,
}: Props) => {
  const [dragOver, setDragOver] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imagePreviews, setImagePreviews] = useState<MinTypeFile[]>([])
  const { getUri } = useImage()
  const refInput = useRef<HTMLInputElement>(null)

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(false)
    const files = event.dataTransfer.files
    if (files) {
      handleFilesChange(files)
    }
  }

  const handleFilesChange = (files: FileList) => {
    setLoading(true)
    const validFiles: File[] = []
    Array.from(files).forEach((file) => {
      const fileSize = file.size / 1024 / 1024 // Size in MB
      const maxSize = 1
      if (fileSize < maxSize) {
        validFiles.push(file)
      } else {
        alert(`File size exceeds the limit of ${maxSize}MB`)
      }
    })
    onFilesUpload(validFiles)
    validFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreviews((prevPreviews) => [
          ...prevPreviews,
          {
            name: file.name,
            url: reader.result as string,
          },
        ])
        setLoading(false)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      handleFilesChange(files)
    }
  }

  const handleClearImage = (file: MinTypeFile) => {
    if (refInput && refInput.current) refInput.current.value = ""
    setImagePreviews((prev) => [...prev.filter((_, i) => _.name !== file.name)])
    onClearUpload && onClearUpload(file)
  }

  useEffect(() => {
    if (defaultUrls) {
      setImagePreviews(defaultUrls)
    }
  }, [defaultUrls])

  return (
    <>
      <Box component={"label"} htmlFor={id}>
        {label} {required ? "*" : ""}
      </Box>
      <Grid container alignItems={"center"} style={{ paddingTop: 10 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={imagePreviews.length > 0 ? 3 : 12}
        >
          <Paper
            variant="outlined"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: dragOver ? "2px dashed #000" : "2px dashed #aaa",
              padding: imagePreviews.length > 0 ? 15 : 29,
              textAlign: "center",
              cursor: "pointer",
              background: dragOver ? "#eee" : "#fafafa",
              position: "relative",
            }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id={id}
              multiple
              type="file"
              required={required}
              onChange={handleChange}
              ref={refInput}
            />
            <label htmlFor={id}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon style={{ fontSize: 50 }} />
                </IconButton>
                <Typography style={{ color: "black" }}>
                  Faites glisser et déposez les images
                </Typography>
              </Box>
            </label>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Paper>
        </Grid>
        {imagePreviews.map((preview, index) => (
          <Grid item xs={3} sm={3} md={3} lg={3} ml={1} mt={1} key={index}>
            <IconButton
              onClick={() => handleClearImage(preview)}
              color="error"
              aria-label="delete picture"
              component="span"
              style={{ backgroundColor: "white", position: "absolute" }}
            >
              <DeleteForeverIcon style={{ fontSize: 20 }} />
            </IconButton>
            <Box
              component="img"
              src={getUri(preview.url as string)}
              alt="Image Preview"
              sx={{ width: "100%", height: 141, borderRadius: 2 }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default CustomUploadFiles
