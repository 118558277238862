import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"
import { NotificationDataType, PlatformType } from "../../../gql/graphql"

export interface AuthPayload {
  __typename?: "User" | undefined
  id: number
  email?: string | null | undefined
  lastName?: string | null | undefined
  name: string
}

export interface PushTokens {
  pushToken: string
  platform: PlatformType
}

interface NotificationData {
  type: NotificationDataType
  data: string
}
export interface UserState {
  pagination: Pagination
  query: string
  user: AuthPayload | null
  notificationData: NotificationData | null
  pushTokens: PushTokens
}

const initialState: UserState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
  user: null,
  pushTokens: {
    pushToken: "",
    platform: PlatformType.Web,
  },
  notificationData: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    setUser: (state, action: PayloadAction<AuthPayload>) => {
      state.user = action.payload
    },
    setPushTokens: (state, action: PayloadAction<PushTokens>) => {
      state.pushTokens = action.payload
    },
    setNotificationData: (
      state,
      action: PayloadAction<NotificationData | null>,
    ) => {
      state.notificationData = action.payload
    },
  },
})
export const selectUserPagination = (state: RootState) => state.user.pagination
export const selectUserQuery = (state: RootState) => state.user.query
export const selectUser = (state: RootState) => state.user.user
export const selectPushTokens = (state: RootState) => state.user.pushTokens
export const selectNotificationData = (state: RootState) =>
  state.user.notificationData
export const {
  setUserPagination,
  setQuery,
  setUser,
  setNotificationData,
  setPushTokens,
} = userSlice.actions
export default userSlice.reducer
