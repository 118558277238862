import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import FormTransporterZone from "../FormTransporterZone/FormTransporterZone"
import { updateCacheTransporterZone } from "../../../caches/updateCacheTransporterZone"
import { InfinityListItem } from "../../../types/InfinityListItem"

// Signin mutation
const addTransporterZoneMutation = graphql(`
  mutation addTransporterZone($data: TransporterZoneCreateInput!) {
    createOneTransporterZone(data: $data) {
      id
      name
      Countries {
        id
        name_fr
      }
    }
  }
`)

export default function AddTransporterZone() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [transporterzone, setTransporterZone] = useState({
    name: "",
  })
  const [country, setCountry] = useState<InfinityListItem[]>([])

  const [createTransporterZone, { loading }] = useMutation(
    addTransporterZoneMutation,
  )

  const handleInputChange = (event: any) => {
    setTransporterZone({
      ...transporterzone,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateTransporterZone = (e: any) => {
    e.preventDefault()
    if (!country.length) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les pays qui sont inclus dans la zone géographique",
        }),
      )
      return
    }
    createTransporterZone({
      variables: {
        data: {
          name: transporterzone.name,
          Countries: { connect: country.map((el) => ({ id: +el.value })) },
        },
      },
      onCompleted: () => {
        navigate("/transporter-zone/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporterZone({
          action: "add",
          cache,
          entryData: data?.createOneTransporterZone,
        })
      },
    })
  }

  const getCountry = (country: InfinityListItem[]) => {
    setCountry(country)
  }

  return (
    <FormTransporterZone
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateTransporterZone}
      loading={loading}
      name={transporterzone.name}
      title="Ajouter une zone de transport"
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
