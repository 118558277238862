import * as React from "react"
import Popover from "@mui/material/Popover"
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import ImageIcon from "@mui/icons-material/Image"
import VideoFileOutlinedIcon from "@mui/icons-material/VideoFileOutlined"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import { MenuItem, MenuList, Paper } from "@mui/material"
import ArchiveIcon from "@mui/icons-material/Archive"
import PaidIcon from "@mui/icons-material/Paid"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import { Status } from "../../../../../gql/graphql"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"

interface Props {
  onSelectImages: () => void
  onSelectVideos: () => void
  onAdvicePrice: () => void
  onArchiveOrder: () => void
  onDelivryOrder: () => void
  onDelivryDate: () => void
  onPaidOrder: () => void
  haveAmountNotPaid: boolean
  haveAmountPaid: boolean
  notPaid: boolean
  status: Status
}

export default function SelectFiles({
  onSelectImages,
  onSelectVideos,
  onAdvicePrice,
  onArchiveOrder,
  onPaidOrder,
  onDelivryOrder,
  haveAmountNotPaid,
  haveAmountPaid,
  onDelivryDate,
  notPaid,
  status,
}: Props) {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <AddOutlinedIcon
            sx={{ cursor: "pointer" }}
            fontSize="large"
            {...bindTrigger(popupState)}
          />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <Paper sx={{ maxWidth: 320 }}>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    onSelectImages()
                    popupState.close()
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Ajouter des photos" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onSelectVideos()
                    popupState.close()
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <VideoFileOutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Ajouter des videos" />
                </MenuItem>
                {notPaid && (
                  <MenuItem
                    onClick={() => {
                      onAdvicePrice()
                      popupState.close()
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <MonetizationOnIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Proposer un prix" />
                  </MenuItem>
                )}
                {haveAmountNotPaid && (
                  <MenuItem
                    onClick={() => {
                      onPaidOrder()
                      popupState.close()
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PaidIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Marquer comme payer" />
                  </MenuItem>
                )}
                {haveAmountPaid && status != Status.Delivry && (
                  <MenuItem
                    onClick={() => {
                      onDelivryOrder()
                      popupState.close()
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <LocalShippingIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Marquer comme liver" />
                  </MenuItem>
                )}
                {haveAmountPaid && status != Status.Delivry && (
                  <MenuItem
                    onClick={() => {
                      onDelivryDate()
                      popupState.close()
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarMonthIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Ajouter la date de livraison" />
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    onArchiveOrder()
                    popupState.close()
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ArchiveIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Archiver la commande" />
                </MenuItem>
              </MenuList>
            </Paper>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}
