import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selectItemTypePagination,
  selectItemTypeQuery,
  setItemTypePagination,
  setItemTypeQuery,
} from "../../../store/features/itemType/itemTypeSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  ItemType,
  ItemTypeWhereInput,
  QueryMode,
  SortOrder,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheItemType } from "../../../caches/updateCacheItemType"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"

export const LIST_ITEMTYPES = graphql(`
  query ItemTypes(
    $where: ItemTypeWhereInput
    $orderBy: [ItemTypeOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    itemTypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
      name_en
      weight
      Category {
        name_fr
        id
        name_en
      }
    }
    aggregateItemType {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_ITEMTYPE = graphql(`
  mutation DeleteOneItemType($where: ItemTypeWhereUniqueInput!) {
    deleteOneItemType(where: $where) {
      id
      name_fr
      name_en
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListItems {
    categories {
      id
      name_fr
    }
  }
`)

const ListItemType = () => {
  const { page, size } = useAppSelector(selectItemTypePagination)
  const query = useAppSelector(selectItemTypeQuery)
  const dispatch = useAppDispatch()
  const countryId = getUserCountryId()
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const { loading: loadingFilter, data: dataFilter } = useQuery(LIST_FILTER)

  const navigate = useNavigate()

  const getValueFilter = (el: { name_fr: string; id: number }) => {
    return {
      label: el.name_fr,
      value: el.id.toString(),
      text: el.name_fr,
    }
  }

  const getFilters: () => ItemTypeWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: ItemTypeWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Category":
              accumulator = {
                ...accumulator,
                Category: {
                  is: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "name_fr":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name_fr: {
                      search: value,
                    },
                  },
                  {
                    name_fr: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "name_en":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name_en: {
                      search: value,
                    },
                  },
                  {
                    name_en: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "weight":
              accumulator = {
                ...accumulator,
                weight: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name_fr: { contains: query, mode: QueryMode.Insensitive } },
            { name_en: { contains: query, mode: QueryMode.Insensitive } },
          ],
        }
      : {}
  }, [query])

  const [getItems, { loading, data }] = useLazyQuery(LIST_ITEMTYPES)

  const whereCluase = whereInputClause({
    Countries: {
      some: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  useEffect(() => {
    getItems({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { name_fr: SortOrder.Asc },
        where: {
          ...queryName(),
          ...getFilters(),
          ...whereCluase,
        },
      },
    })
  }, [getItems, page, query, queryName, size, getFilters])

  const [deleteItemType] = useMutation(DELETE_ITEMTYPE)

  const columns = useMemo<MRT_ColumnDef<ItemType>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "name_fr",
        header: "Nom en fr",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name_fr",
      },
      {
        accessorKey: "name_en",
        header: "Nom en en",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name_en",
      },
      {
        accessorKey: "weight",
        header: "Poids d'article",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "weight",
      },
      {
        accessorKey: "Category.name_fr",
        header: "Catégorie",
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.categories?.map((el) =>
          getValueFilter(el),
        ),
        id: "Category",
      },
    ],
    [dataFilter],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setItemTypePagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteItemType({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le type article a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheItemType({
          action: "delete",
          cache,
          entryData: data?.deleteOneItemType,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message:
          "Êtes-vous vraiment sûr de vouloir supprimer ce type article ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setItemTypeQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un type article"))
    navigate(`/item-type/edit/${id}`)
  }

  const handleView = (id: number) => {
    dispatch(setTitle("Detail d'un type article"))
    navigate(`/item-type/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.itemTypes || []}
      lableAddNew="Créer un nouveau type article"
      rootLisName={"/item-type/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateItemType._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["itemType.delete"])
          ? handleOpenDeleteDialog
          : undefined
      }
      isHaveHeaderAction={havePermissions(["itemType.create"])}
      handleEdit={havePermissions(["itemType.change"]) ? handleEdit : undefined}
      isHaveView={havePermissions(["itemType.view"])}
      handleView={havePermissions(["itemType.view"]) ? handleView : undefined}
      isHaveAction={havePermissions([
        "itemType.view",
        "itemType.change",
        "itemType.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListItemType
