import { useCallback } from "react"
import { FileType } from "../../Chat"
import useImage from "../../../../../../hooks/useImage"
import CustomGallery from "../../../../../Common/CustomGallery/CustomGallery"

interface Props {
  files: FileType[]
}

function MessageImage({ files }: Props) {
  const { getUri } = useImage()

  const images = useCallback(() => {
    return files && files.length
      ? files
          .filter((file) => file.type.includes("image"))
          .map((el) => ({ ...el, url: getUri(el.url) }))
      : []
  }, [files])

  return (
    <div className="container">
      <div className="image_grid grid">
        <CustomGallery images={images()} />
      </div>
    </div>
  )
}

export default MessageImage
