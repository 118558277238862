import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import ReactPhoneInput from "react-phone-input-material-ui"
import CustomUploadFile from "../../Common/CustomUploadFile/CustomUploadFile"
import CustomTextFieldPassword from "../../Common/CustomTextFieldPassword/CustomTextFieldPassword"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"
import TablesRoles from "./TableRoles/TablesRoles"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import { InfinityListItem } from "../../../types/InfinityListItem"
import ToggleSwitch from "./TableRoles/SwitchButton/SwitchButton"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  name: string
  lastName: string
  email: string
  loading: boolean
  title: string
  password: string
  rePassword: string
  phoneNumber: string
  defaulPhoto?: MinTypeFile
  getRePassword: (value: string) => void
  getPhoneNumber: (value: string) => void
  onClearUpload: (file: MinTypeFile) => void
  onFileUpload: (file: File) => void
  havePassword?: boolean
  permissions: string[]
  handlePermissions: (permission: string[]) => void
  getCountry: (country: InfinityListItem) => void
  countryDefault: InfinityListItem
  isActiveAdmin: boolean
  toogleActive: () => void
}

export default function FormAdmin({
  handleSubmit,
  handleInputChange,
  onFileUpload,
  onClearUpload,
  getPhoneNumber,
  getRePassword,
  name,
  lastName,
  password,
  email,
  rePassword,
  phoneNumber,
  loading,
  title,
  defaulPhoto,
  havePassword = true,
  permissions,
  countryDefault,
  getCountry,
  handlePermissions,
  isActiveAdmin,
  toogleActive,
}: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required={true}
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom"
                  fullWidth
                  variant="outlined"
                  value={name}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="lastName"
                  type="text"
                  onChange={handleInputChange}
                  label="Prénom"
                  fullWidth
                  value={lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListCountry
                  countryDefault={countryDefault}
                  getCountry={(value) => getCountry(value as InfinityListItem)}
                  required
                  label="Pays de l'Admin"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required={true}
                  label="Email"
                  name="email"
                  type="email"
                  onChange={handleInputChange}
                  fullWidth
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ReactPhoneInput
                  label="Numéro de téléphone*"
                  value={phoneNumber}
                  onChange={(value) => getPhoneNumber(value)}
                  enableSearch={true}
                  component={TextField}
                  enableAreaCodes={true}
                  enableClickOutside={true}
                  enableAreaCodeStretch={true}
                  enableTerritories={true}
                  enableLongNumbers={true}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box>
                  <Typography>Voulez vous activer ce compte ?</Typography>
                </Box>
                <Box mt={0.5}>
                  <ToggleSwitch
                    checked={isActiveAdmin}
                    handleChange={toogleActive}
                  />
                </Box>
              </Grid>
              {havePassword && (
                <Grid item xs={12} lg={6}>
                  <CustomTextFieldPassword
                    name="password"
                    onChange={handleInputChange}
                    label="Mot de passe"
                    fullWidth
                    value={password}
                    required={true}
                  />
                </Grid>
              )}
              {havePassword && (
                <Grid item xs={12} lg={6}>
                  <CustomTextFieldPassword
                    label="Confirmation de mot de passe"
                    fullWidth
                    name="rePassword"
                    value={rePassword}
                    required={true}
                    onChange={(e) => getRePassword(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUpload}
                  onFileUpload={onFileUpload}
                  label="Photo de profile"
                  id="photo-de-profile"
                  defaultUrl={defaulPhoto}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={"Permissions"} variant="h4" />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TablesRoles
                  permissions={permissions}
                  handlePermissions={handlePermissions}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
