import { useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Lightbox } from "react-modal-image"
import { File } from "../../../../gql/graphql"
import useImage from "../../../../hooks/useImage"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

interface Props {
  images: File[] | undefined
}

const OrderImages = ({ images }: Props) => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState("")
  const { getUri } = useImage()

  const handleClose = () => {
    setOpen(false)
    setUrl("")
  }
  if (!images) return null

  return (
    <>
      <Carousel responsive={responsive}>
        {images?.map((image) => (
          <div key={image.id}>
            <img
              style={{
                width: "120px",
                height: "80px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              alt={image.name}
              src={getUri(image.url)}
              onClick={() => {
                setUrl(image.url)
                setOpen(true)
              }}
            />
          </div>
        ))}
      </Carousel>
      {open && (
        <Lightbox
          small={getUri(url)}
          medium={getUri(url)}
          large={getUri(url)}
          alt=""
          //@ts-ignore
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default OrderImages
