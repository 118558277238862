import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { MinTypeFile } from "../CustomUploadFiles/CustomUploadFiles"
import useImage from "../../../hooks/useImage"

interface Props {
  onFileUpload: (file: File) => void
  onClearUpload: (file: MinTypeFile) => void
  label: string
  required?: boolean
  id: string
  defaultUrl?: MinTypeFile
}
const CustomUploadFile = ({
  onFileUpload,
  onClearUpload,
  label,
  required,
  id,
  defaultUrl,
}: Props) => {
  const [dragOver, setDragOver] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imagePreview, setImagePreview] = useState<MinTypeFile | null>(null)
  const { getUri } = useImage()
  const refInput = useRef<HTMLInputElement>(null)

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(false)
    const files = event.dataTransfer.files
    if (files && files[0]) {
      handleFileChange(files[0])
    }
  }

  const handleFileChange = (file: File) => {
    const fileSize = file.size / 1024 / 1024 // Size in MB
    const maxSize = 1
    if (fileSize < maxSize) {
      setLoading(true)
      onFileUpload(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setLoading(false)
        setImagePreview({
          name: file.name,
          url: reader.result as string,
        })
      }
      reader.readAsDataURL(file)
    } else {
      alert(`File size exceeds the limit of ${maxSize}MB`)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) {
      handleFileChange(files[0])
    }
  }
  const handleClearImage = (file: MinTypeFile) => {
    if (refInput && refInput.current) refInput.current.value = ""
    setImagePreview(null)
    onClearUpload && onClearUpload(file)
  }

  useEffect(() => {
    if (defaultUrl) {
      setImagePreview(defaultUrl)
    }
  }, [defaultUrl])

  return (
    <>
      <Box component={"label"} htmlFor={id}>
        {label} {required ? "*" : ""}
      </Box>
      <Grid container alignItems={"center"} style={{ paddingTop: 10 }}>
        <Grid item xs={12} sm={12} md={12} lg={imagePreview ? 4 : 12}>
          <Paper
            variant="outlined"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: dragOver ? "2px dashed #000" : "2px dashed #aaa",
              padding: imagePreview ? 15 : 29,
              textAlign: "center",
              cursor: "pointer",
              background: dragOver ? "#eee" : "#fafafa",
              position: "relative",
            }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id={id}
              multiple
              type="file"
              required={required}
              onChange={handleChange}
              ref={refInput}
            />
            <label htmlFor={id}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon style={{ fontSize: 50 }} />
                </IconButton>
                <Typography style={{ color: "black" }}>
                  Faites glisser et déposez l'image
                </Typography>
              </Box>
            </label>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Paper>
        </Grid>
        {imagePreview && (
          <Grid item xs={4} sm={4} md={4} lg={4} ml={1} mt={1}>
            <IconButton
              onClick={() => handleClearImage(imagePreview)}
              color="error"
              aria-label="delete picture"
              component="span"
              style={{ backgroundColor: "white", position: "absolute" }}
            >
              <DeleteForeverIcon style={{ fontSize: 20 }} />
            </IconButton>
            <Box
              component="img"
              src={getUri(imagePreview.url)}
              alt="Image Preview"
              sx={{ width: "100%", height: 141, borderRadius: 2 }}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default CustomUploadFile
