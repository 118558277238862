/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material"
import { graphql } from "../../../gql"
import { useParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import { useEffect } from "react"
import Loader from "../../Common/Loader/Loader"
import parse from "html-react-parser"

const GET_EMAIL = graphql(`
  query Email($where: EmailWhereUniqueInput!) {
    email(where: $where) {
      id
      contentHtml
      subject
    }
  }
`)

const DetailEmail = () => {
  const params = useParams()
  const id = params.id ? parseInt(params.id, 10) : 0
  const [getEmail, { data, loading }] = useLazyQuery(GET_EMAIL)

  useEffect(() => {
    getEmail({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  if (loading) return <Loader />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {parse(data?.email?.contentHtml || "")}
      </Grid>
    </Grid>
  )
}

export default DetailEmail
