import { gql } from "@apollo/client"

export const MATERIALTYPE_FRAGMENT = gql(`
  fragment MaterialTypeFragment on MaterialType {
    id
    name_fr
    name_en
    Category {
        id
        name_fr
        name_en
    }
    Countries {
      id
      name_fr
    }
  }
`)
