import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { NewFileInput, SignupInput, UserRoleType } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheAdmin } from "../../../caches/updateCacheAdmin"
import { uploadFile } from "../../../utils/uploadFile"
import FormAdmin from "../FormAdmin/FormAdmin"
import { defaultPermissions } from "../FormAdmin/roles"
import { InfinityListItem } from "../../../types/InfinityListItem"

// Signin mutation
const addAdminMutation = graphql(`
  mutation SignupUser($signupInput: SignupInput!) {
    signupUser(signupInput: $signupInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        permissions
        isActiveAdmin
        ProfilePhoto {
          name
          url
          id
        }
      }
    }
  }
`)

export default function AddAdmin() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [signin, { loading }] = useMutation(addAdminMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [rePassword, setRePassword] = useState<string>("")
  const [permissions, setPermissions] = useState<string[]>(defaultPermissions)
  const [isActiveAdmin, setIsActiveAdmin] = useState(true)
  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [country, setCountry] = useState<InfinityListItem>(defaultValue)

  const [user, setUser] = useState<SignupInput>({
    email: "",
    lastName: "",
    password: "",
    role: UserRoleType.Admin,
    phoneNumber: "",
    name: "",
    lang: "fr",
  })

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleSignup = (profilePhoto?: NewFileInput) => {
    signin({
      variables: {
        signupInput: {
          ...user,
          profilePhoto: profilePhoto,
          permissions,
          countryId: Number(country.value),
          isActiveAdmin
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/admin/list")
      },
      update: (cache, { data }) => {
        updateCacheAdmin({
          action: "add",
          cache,
          entryData: data?.signupUser.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (user.password !== rePassword) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez vérifier le mot de passe et confirmer le mot de passe !!!",
        }),
      )
      return
    }
    if (file) {
      setIsLoading(true)
      uploadFile({
        cbError: ({ message }) => {
          setOpenSnackbar({
            message,
          })
          setIsLoading(false)
        },
        cbOk: (file) => {
          handleSignup(file)
        },
        file,
        getPrecent: (value) => {},
      })
    } else {
      handleSignup()
    }
  }

  const onFileUpload = (file: File) => {
    setFile(file)
  }

  const onClearUpload = () => setFile(null)

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  const toogleActive = ()=> setIsActiveAdmin(prev =>!prev)

  return (
    <FormAdmin
      getPhoneNumber={(value) => setUser({ ...user, phoneNumber: value })}
      getRePassword={(value) => setRePassword(value)}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      onClearUpload={onClearUpload}
      onFileUpload={onFileUpload}
      rePassword={rePassword}
      title={"Ajouter un admin"}
      email={user.email}
      lastName={user.lastName}
      name={user.name}
      password={user.password}
      phoneNumber={user.phoneNumber || ""}
      handlePermissions={setPermissions}
      permissions={permissions}
      countryDefault={country}
      getCountry={getCountry}
      isActiveAdmin={isActiveAdmin}
      toogleActive={toogleActive}
    />
  )
}
