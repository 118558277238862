import { useState } from "react"
import { Lightbox } from "react-modal-image"
import { File } from "../../../../gql/graphql"
import useImage from "../../../../hooks/useImage"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

interface Props {
  images: File[] | undefined
}

const OrderImages = ({ images }: Props) => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState("")
  const { getUri } = useImage()

  const handleClose = () => {
    setOpen(false)
    setUrl("")
  }
  if (!images) return null

  return (
    <>
      <Carousel
        infiniteLoop
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        onClickItem={(index, item) => {
          setUrl(getUri(images[index].url))
          setOpen(true)
        }}
      >
        {images?.map((image) => (
          <div key={image.id}>
            <img
              style={{
                width: "100%",
                height: "100px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              alt={image.name}
              src={getUri(image.url)}
            />
          </div>
        ))}
      </Carousel>
      {open && (
        <Lightbox
          small={getUri(url)}
          medium={getUri(url)}
          large={getUri(url)}
          alt=""
          //@ts-ignore
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default OrderImages
