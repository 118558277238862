import { Box } from "@mui/material"
import { FileType } from "../../Chat"
import { useCallback } from "react"
import useImage from "../../../../../../hooks/useImage"

interface Props {
  files: FileType[]
}

const MessageAudio = ({ files }: Props) => {
  const { getUri } = useImage()

  const audios = files
    .filter((file) => file.type.includes("audio"))
    .map((el) => ({
      src: getUri(el.url),
      id: el.id,
    }))

  const src = useCallback(() => {
    return audios.length ? audios[0].src : ""
  }, [audios])

  if (!audios || (audios && !audios.length)) return null

  return (
    <Box>
      <audio controls src={src()} style={{ maxWidth: "100%"}}></audio>
    </Box>
  )
}

export default MessageAudio
