import { Button, Grid, Paper, TextField, Typography } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import CustomListcategory from "../../Common/CustomListCategory/CustomListCategory"
import CustomListItemType from "../../Common/CustomListItemType/CustomListItemType"
import CustomListMaterialType from "../../Common/CustomListMaterialType/CustomListMaterialType"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomUploadFiles, {
  MinTypeFile,
} from "../../Common/CustomUploadFiles/CustomUploadFiles"
import CustomListUser from "../../Common/CustomListUser/CustomListUser"
import { Role } from "../../../gql/graphql"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { Moment } from "moment"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  handleInputChangeDeadline: (value: Moment | null) => void
  loading: boolean
  title: string
  getCategory: (category: InfinityListItem) => void
  categoryDefault: InfinityListItem
  getCountry: (country: InfinityListItem) => void
  countryDefault: InfinityListItem
  categoryId?: number
  getItemTypes: (itemItems: InfinityListItem[] | InfinityListItem) => void
  itemTypesDefault: InfinityListItem
  getMaterialTypes: (
    materialItems: InfinityListItem[] | InfinityListItem,
  ) => void
  materialTypesDefault: InfinityListItem
  onFilesUpload: (files: File[]) => void
  onClearUploads: (file: MinTypeFile) => void
  required?: boolean
  message: string
  price: string
  quantity: string
  craftmanCustomPrice: string
  ceedowCommissionCustomPrice: string
  transporterCustomPrice: string
  deadline: Moment | null
  defaultUrls?: MinTypeFile[]
  isOffer: boolean
  isCreated?: boolean
  getCraftMan: (craftman: InfinityListItem) => void
  getCustomer: (customer: InfinityListItem) => void
  customerDefault: InfinityListItem
  craftmanDefault: InfinityListItem
}

export default function FormOrder({
  handleSubmit,
  handleInputChange,
  onClearUploads,
  loading,
  title,
  onFilesUpload,
  defaultUrls,
  required,
  categoryDefault,
  countryDefault,
  craftmanCustomPrice,
  ceedowCommissionCustomPrice,
  transporterCustomPrice,
  getCategory,
  getCountry,
  quantity,
  getItemTypes,
  getMaterialTypes,
  itemTypesDefault,
  materialTypesDefault,
  categoryId,
  message,
  price,
  isOffer,
  deadline,
  isCreated = false,
  craftmanDefault,
  customerDefault,
  getCraftMan,
  getCustomer,
  handleInputChangeDeadline,
}: Props) {
  const craftmanId = craftmanDefault.value
    ? Number(craftmanDefault.value)
    : undefined
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              {isCreated && (
                <Grid item xs={12} lg={6}>
                  <TextField
                    required={true}
                    label="Prix"
                    name="price"
                    type="number"
                    onChange={handleInputChange}
                    fullWidth
                    value={price}
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <TextField
                  required={true}
                  label="Quantité(s)"
                  name="quantity"
                  type="number"
                  onChange={handleInputChange}
                  fullWidth
                  value={quantity}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DatePicker
                  label="Delai"
                  name="deadline"
                  onChange={handleInputChangeDeadline}
                  value={deadline}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListUser
                  getUser={getCraftMan}
                  label="Artisan"
                  role={Role.Craftman}
                  required={true}
                  userDefault={craftmanDefault}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListUser
                  getUser={getCustomer}
                  label="Client"
                  required={true}
                  role={Role.Customer}
                  userDefault={customerDefault}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListcategory
                  categoryDefault={categoryDefault}
                  getCategory={getCategory}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListItemType
                  getItemTypes={getItemTypes}
                  itemTypesDefault={itemTypesDefault}
                  categoryId={categoryId}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListMaterialType
                  getMaterialTypes={getMaterialTypes}
                  materialTypesDefault={materialTypesDefault}
                  categoryId={categoryId}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required={false}
                  label="Montant artisan"
                  name="craftmanCustomPrice"
                  type="number"
                  onChange={handleInputChange}
                  fullWidth
                  value={craftmanCustomPrice}
                  variant="outlined"
                  helperText={
                    <Typography
                      variant="caption"
                      color={"white"}
                      fontSize={"11.5px"}
                    >
                      Vous pouvez mettre le prix de l'artisan ici au cas où vous
                      voulez modifier son montant.(Devise par rapport au pays de
                      l'artisan)
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required={false}
                  label="Montant transport"
                  name="transporterCustomPrice"
                  type="number"
                  onChange={handleInputChange}
                  fullWidth
                  value={transporterCustomPrice}
                  variant="outlined"
                  helperText={
                    <Typography
                      variant="caption"
                      color={"white"}
                      fontSize={"11.5px"}
                    >
                      Vous pouvez mettre le prix de transport ici au cas où vous
                      voulez personnaliser son montant.(Devise par rapport au
                      pays de l'artisan)
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required={false}
                  label="Commission Ceedow"
                  name="ceedowCommissionCustomPrice"
                  type="number"
                  onChange={handleInputChange}
                  fullWidth
                  value={ceedowCommissionCustomPrice}
                  variant="outlined"
                  helperText={
                    <Typography
                      variant="caption"
                      color={"white"}
                      fontSize={"11.5px"}
                    >
                      Vous pouvez mettre la commission ici au cas où vous voulez
                      personnaliser la commission.(Devise par rapport au pays de
                      l'artisan)
                    </Typography>
                  }
                />
              </Grid>
              {isCreated && (
                <Grid item xs={12} lg={12}>
                  <TextField
                    required={true}
                    label="Message"
                    name="message"
                    type="text"
                    onChange={handleInputChange}
                    fullWidth
                    value={message}
                    multiline
                    minRows={3}
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <CustomUploadFiles
                  id="photo-de-realisationq"
                  label="Modèle Photos*"
                  onClearUpload={onClearUploads}
                  onFilesUpload={onFilesUpload}
                  required={required}
                  defaultUrls={defaultUrls}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
