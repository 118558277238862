import React, { useState } from "react"
import { Lightbox } from "react-modal-image"
import useImage from "../../../hooks/useImage"

interface Props {
  url: string | undefined
  style?: React.CSSProperties
}

const CustomImage = ({ url, style }: Props) => {
  const [open, setOpen] = useState(false)
  const { getUri } = useImage()

  const handleClose = () => {
    setOpen(false)
  }
  if (!url) return null

  return (
    <>
      <img
        onClick={() => setOpen(true)}
        style={{
          width: "100%",
          height: "100px",
          cursor: "pointer",
          borderRadius: "5px",
          ...style,
        }}
        alt={"photo"}
        src={getUri(url)}
      />
      {open && (
        <Lightbox
          small={getUri(url)}
          medium={getUri(url)}
          large={getUri(url)}
          alt=""
          //@ts-ignore
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default CustomImage
