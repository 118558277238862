import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface SegmentationState {
  pagination: Pagination
  query: string
}

const initialState: SegmentationState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const segmentationSlice = createSlice({
  name: "segmentation",
  initialState,
  reducers: {
    setSegmentationPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setSegmentationQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})
export const selectSegmentationPagination = (state: RootState) =>
  state.segmentation.pagination
export const selectSegmentationQuery = (state: RootState) =>
  state.segmentation.query
export const { setSegmentationPagination, setSegmentationQuery } =
  segmentationSlice.actions
export default segmentationSlice.reducer
