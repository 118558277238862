import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"

const LIST_SEGMENTATIONS = graphql(`
  query segmentations_total(
    $where: SegmentationWhereInput
    $orderBy: [SegmentationOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    aggregateSegmentation {
      _count {
        _all
      }
    }
    segmentations(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
      name_en
    }
  }
`)

interface Props {
  getSegmentaion: (segmentation: InfinityListItem) => void
  segmentationDefault: InfinityListItem
}
const CustomListSegmentation = ({
  getSegmentaion,
  segmentationDefault,
}: Props) => {
  const [segmentation, setSegmentation] = useState(segmentationDefault)

  const [querySegmentation, setQuerySegmentation] = useState("")
  const [openSegmentation, setOpenSegmentation] = useState(false)
  const size = 10

  const queryName = useCallback(() => {
    return querySegmentation
      ? {
          where: {
            OR: [
              {
                name_fr: {
                  search: querySegmentation,
                  mode: QueryMode.Insensitive,
                },
              },
              {
                name_en: {
                  search: querySegmentation,
                  mode: QueryMode.Insensitive,
                },
              },
            ],
          },
        }
      : {}
  }, [querySegmentation])

  const { loading, data, fetchMore } = useQuery(LIST_SEGMENTATIONS, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name_fr: SortOrder.Asc },
      ...queryName(),
    },
  })

  const handleSearchSegmentation = (query: string) => {
    setQuerySegmentation(query)
  }

  const handleCloseSegmentation = () => {
    setOpenSegmentation(false)
  }
  const handleOpenSegmentation = async () => {
    setOpenSegmentation(true)
  }

  const onEndReachedSegmentation = () => {
    fetchMore({
      variables: {
        skip: data?.segmentations.length,
      },
    })
  }

  useEffect(() => {
    if (!segmentation?.value && segmentationDefault?.value) {
      setSegmentation(segmentationDefault)
    }
  }, [segmentation.value, segmentationDefault])

  const dataSegmentation = data?.segmentations

  const segmentations =
    loading || !dataSegmentation
      ? []
      : dataSegmentation.map((el) => ({ label: el.name_fr, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseSegmentation}
      handleOpen={handleOpenSegmentation}
      open={openSegmentation}
      defaultValue={segmentationDefault}
      keyName="label"
      label="Segmentation"
      onSearch={handleSearchSegmentation}
      options={openSegmentation ? segmentations : []}
      loading={loading}
      onChange={(value) => {
        if (value) {
          setSegmentation(value)
          getSegmentaion(value)
        }
      }}
      query={querySegmentation}
      value={segmentation}
      onEndReached={onEndReachedSegmentation}
      required={true}
    />
  )
}

export default CustomListSegmentation
