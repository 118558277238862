import { Typography, TypographyProps } from "@mui/material"

interface Props extends TypographyProps {
  title: string
}

const HeaderAdd = ({ title, ...rest }: Props) => {
  return (
    <Typography sx={{ textAlign: "center" }} variant="h1" {...rest}>
      {title}
    </Typography>
  )
}
export default HeaderAdd
