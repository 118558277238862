import "./App.css"
import React, { useEffect } from "react"
import theme from "./theme"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import CustomSnackbar from "./components/Common/CustomSnackbar/CustomSnackbar"
import CustomAlert from "./components/Common/CustomAlert/CustomAlert"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import Router from "./Router"
import { onMessage } from "firebase/messaging"
import { messaging, onForegroundMessage } from "./firebase/firebase"
import { useAppDispatch } from "./store/app/hooks"
import { setPushTokens } from "./store/features/user/userSlice"
import { PlatformType } from "./gql/graphql"
import { toast, ToastContainer } from "react-toastify"
import Message from "./components/Notification/Message"
import { getFirebaseToken } from "./firebase/serviceWorker"

type Mode = "light" | "dark"

// TODO: Dynamique mode
function App() {
  let mode = "light" as Mode
  const customTheme = React.useMemo(() => theme(mode), [mode])
  const dispatch = useAppDispatch()

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const token = await getFirebaseToken()
      dispatch(
        setPushTokens({
          platform: PlatformType.Web,
          pushToken: token,
        }),
      )
    } else if (permission === "denied") {
      alert(
        "Veuillez activer vos notifications afin de recevoir les dernières nouvelles !",
      )
    }
  }

  onMessage(messaging, (payload) => {
    toast(<Message notification={payload.notification} data={payload.data} />, {
      type: "info",
     
    })
  })

  useEffect(() => {
    requestPermission()
  }, [])

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        toast(
          <Message notification={payload.notification} data={payload.data} />,
          {
            type: "info",
          },
        )
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err,
        ),
      )
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={customTheme}>
        <Router />
        <CustomSnackbar />
        <CustomAlert />
        <ToastContainer hideProgressBar />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
