import { ApolloCache, Reference } from "@apollo/client"
import { FAQS_FRAGMENT } from "../fragment/faqs"

export const updateCacheFaqs = ({
  cache,
  entryData,
  action,
}: {
  cache: ApolloCache<any>
  entryData: any
  action: "update" | "delete" | "add"
}) => {
  return cache.modify({
    fields: {
      faqs(existing = []) {
        const newFaqsRef = cache.writeFragment({
          data: entryData,
          fragment: FAQS_FRAGMENT,
        })
        switch (action) {
          case "add":
            return [...existing, newFaqsRef]
          case "delete":
            return [
              ...existing?.filter(
                (el: Reference | undefined) =>
                  el?.__ref !== newFaqsRef?.__ref,
              ),
            ]
          case "update":
            const index = existing.findIndex(
              (el: Reference | undefined) =>
                el?.__ref !== newFaqsRef?.__ref,
            )
            const items = [...existing]
            if (index > -1) {
              items[index] = newFaqsRef
            }
            return items
        }
      },
      aggregateFaq(existing = {}) {
        let _all = existing?._count?._all
        switch (action) {
          case "add":
            return {
              ...existing,
              _count: {
                ...existing?._count,
                _all: _all ? _all + 1 : 0,
              },
            }
          case "delete":
            return {
              ...existing,
              _count: {
                ...existing?._count,
                _all: _all ? _all - 1 : 0,
              },
            }
        }
      },
    },
  })
}
