import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface TenderState {
  pagination: Pagination
  query: string
}

const initialState: TenderState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const tenderSlice = createSlice({
  name: "tender",
  initialState,
  reducers: {
    setTenderPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setTenderQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})
export const selectTenderPagination = (state: RootState) =>
  state.tender.pagination
export const selectTenderQuery = (state: RootState) => state.tender.query
export const { setTenderPagination, setTenderQuery } = tenderSlice.actions
export default tenderSlice.reducer
