import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import FormTransporterZone from "../FormTransporterZone/FormTransporterZone"
import Loader from "../../Common/Loader/Loader"
import { updateCacheTransporterZone } from "../../../caches/updateCacheTransporterZone"
import { InfinityListItem } from "../../../types/InfinityListItem"

const editTransporterZoneMutation = graphql(`
  mutation updateOneTransporterZone(
    $data: TransporterZoneUpdateInput!
    $where: TransporterZoneWhereUniqueInput!
  ) {
    updateOneTransporterZone(data: $data, where: $where) {
      id
      name
      Countries {
        id
        name_fr
      }
    }
  }
`)

const getOneTransporterZoneQuery = graphql(`
  query getOneTransporterZoneQuery($where: TransporterZoneWhereUniqueInput!) {
    transporterZone(where: $where) {
      id
      name
      Countries {
        id
        name_fr
      }
    }
  }
`)

export default function EditTransporterZone() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [country, setCountry] = useState<InfinityListItem[]>([])
  const [oldCountry, setOldCountry] = useState<InfinityListItem[]>([])

  const [transporterzone, setTransporterZone] = useState({
    name: "",
  })

  const [getTransporterZone, { loading: loadingInit, error }] = useLazyQuery(
    getOneTransporterZoneQuery,
    {
      onCompleted(data) {
        setTransporterZone({
          name: data?.transporterZone?.name || "",
        })
        if (data.transporterZone?.Countries) {
          const Countries = data.transporterZone?.Countries.map((el) => ({
            value: el.id.toString(),
            label: el.name_fr,
          }))
          setOldCountry(Countries)
          setCountry(Countries)
        }
      },
    },
  )

  const [updateTransporterZone, { loading }] = useMutation(
    editTransporterZoneMutation,
  )

  const handleInputChange = (event: any) => {
    setTransporterZone({
      ...transporterzone,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateTransporterZone = (e: any) => {
    e.preventDefault()
    if (!country.length) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les pays qui sont inclus dans la zone géographique",
        }),
      )
      return
    }
    updateTransporterZone({
      variables: {
        data: {
          name: {
            set: transporterzone.name,
          },
          Countries: {
            connect: country.map((el) => ({ id: +el.value })),
            disconnect: oldCountry.map((el) => ({ id: +el.value })),
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/transporter-zone/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporterZone({
          action: "update",
          cache,
          entryData: data?.updateOneTransporterZone,
        })
      },
    })
  }

  useEffect(() => {
    getTransporterZone({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getCountry = (country: InfinityListItem[]) => {
    setCountry(country)
  }

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormTransporterZone
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateTransporterZone}
      loading={loading}
      name={transporterzone.name}
      title="Modifier une zone de transport"
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
