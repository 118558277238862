import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectFaqsPagination,
  selectFaqsQuery,
  setFaqsPagination,
  setFaqsQuery,
} from "../../../store/features/faqs/faqsSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { Faq, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheFaqs } from "../../../caches/updateCacheFaqs"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { havePermissions } from "../../../utils/permissions"

export const LIST_FAQS = graphql(`
  query Faqs(
    $where: FaqWhereInput
    $orderBy: [FaqOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    faqs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      createdAt
      description_en
      title_en
      title_fr
      updatedAt
      description_fr
    }
    aggregateFaq {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_FAQS = graphql(`
  mutation DeleteOneFaq($where: FaqWhereUniqueInput!) {
    deleteOneFaq(where: $where) {
      id
    }
  }
`)

const ListFaqs = () => {
  const { page, size } = useAppSelector(selectFaqsPagination)
  const query = useAppSelector(selectFaqsQuery)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const queryName = useCallback(() => {
    return query
      ? {
          where: {
            OR: [
              { title_fr: { search: query } },
              { title_en: { search: query } },
              {
                id: {
                  equals: Number(query),
                },
              },
            ],
          },
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_FAQS, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { title_fr: SortOrder.Asc },
      ...queryName(),
    },
  })

  const [deleteFaqs] = useMutation(DELETE_FAQS)

  const columns = useMemo<MRT_ColumnDef<Faq>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "title_fr",
        header: "Titre en FR",
        enableClickToCopy: true,
      },
      {
        accessorKey: "title_en",
        header: "Titre en EN",
        enableClickToCopy: true,
      },
      {
        accessorKey: "description_fr",
        header: "Description en FR",
        enableClickToCopy: true,
      },
      {
        accessorKey: "description_en",
        header: "Description en EN",
        enableClickToCopy: true,
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setFaqsPagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteFaqs({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le faqs a été supprimée avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheFaqs({
          action: "delete",
          cache,
          entryData: data?.deleteOneFaq,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ce faqs ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) => dispatch(setFaqsQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un faqs"))
    navigate(`/faqs/edit/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload();
  }

  if (loading && !query && !page) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.faqs || []}
      lableAddNew="Créer un nouveau FAQS"
      rootLisName={"/faqs/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateFaq._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["faqs.delete"]) ? handleOpenDeleteDialog : undefined
      }
      isHaveHeaderAction={havePermissions(["faqs.create"])}
      handleEdit={havePermissions(["faqs.change"]) ? handleEdit : undefined}
      isHaveView={false}
      handleView={undefined}
      isHaveAction={havePermissions([
        "faqs.view",
        "faqs.change",
        "faqs.delete",
      ])}
      handleRefresh={handleRefresh}
    />
  )
}

export default ListFaqs
