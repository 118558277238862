import useImage from "../../../../../../hooks/useImage"
import { FileType } from "../../Chat"

interface Props {
  files: FileType[]
}

const MessageVideo = ({ files }: Props) => {
  const videos = files.filter((file) => file.type.includes("video"))

  const { getUri } = useImage()

  if (!videos || (videos && !videos.length)) return null

  return (
    <div style={{ width: "100%" }}>
      {videos.map((video, index) => {
        return (
          <video
            key={`video_${index}`}
            style={{
              objectFit: "cover",
              width: "100%",
              maxWidth: "100%",
              height: "200px",
              marginBottom: "5px",
              borderRadius: "10px",
            }}
            src={getUri(video.url)}
            controls
          />
        )
      })}
    </div>
  )
}

export default MessageVideo
