import React, { useState } from "react"
import { Gallery, Item } from "react-photoswipe-gallery"
import "photoswipe/dist/photoswipe.css"
import { FileType } from "../../Order/DetailOrder/Chat/Chat"

interface CustomGalleryProps {
  images: FileType[]
}

const CustomGallery: React.FC<CustomGalleryProps> = ({ images }) => {
  const [showAll, setShowAll] = useState(false)
  const displayedImages = showAll ? images : images.slice(0, 1)

  return (
    <div>
      <Gallery
        withDownloadButton
        options={{
          allowPanToNext: true, // Changed to true to allow swiping between images
          pinchToClose: false,
          initialZoomLevel: "fit",
          closeOnVerticalDrag: false,
          wheelToZoom: true,
          zoom: true,
          maxZoomLevel: "fill",
          imageClickAction: "zoom",
          counter: true,
          doubleTapAction: "zoom",
          tapAction: "zoom",
        }}
      >
        {displayedImages.map((image) => (
          <Item
            key={image.id}
            original={image.url}
            thumbnail={image.url}
            width={1024} // Assume a default width, adjust as needed
            height={768} // Assume a default height, adjust as needed
            cropped
          >
            {({ ref, open }) => (
              <div
                className={`image_grid imageContainer fullWidth`}
                ref={ref}
                onClick={(e) => open(e)} // Open gallery at the correct index
              >
                <img
                  src={image.url}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  alt={image.name}
                  className="image_grid image"
                />
              </div>
            )}
          </Item>
        ))}
        {/* Hidden items for remaining images */}
        {!showAll &&
          images.slice(1).map((image) => (
            <Item
              key={image.id}
              original={image.url}
              thumbnail={image.url}
              width={1024}
              height={768}
            >
              {({ ref }) => <div ref={ref} style={{ display: "none" }} />}
            </Item>
          ))}
      </Gallery>
      {images.length > 1 && (
        <span
          style={{
            display: "flex",
            fontSize: "12px",
            marginTop: "10px",
            cursor: "pointer",
            textDecorationLine: "underline",
            justifyContent: "end",
          }}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll
            ? "Voir moins"
            : `Voir plus (${images.length - 1} ${
                images.length - 1 === 1 ? "image" : "images"
              })`}
        </span>
      )}
    </div>
  )
}

export default CustomGallery
