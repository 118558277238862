import { PaletteMode } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { outlinedInputClasses } from "@mui/material/OutlinedInput"
declare module "@mui/material/styles" {
  interface Palette {
    customColor: {
      bodyBackground: string
      scrollColor: string
      hoverColor: string
      selectionColor: string
      chatSessionBackground: string
      chatBackground: string
    }
  }
  // allow configuration using `createTheme`
}

const theme = (mode: PaletteMode = "light") => {
  return createTheme({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            background: {
              default: "#372C08",
              paper: "#372C08",
            },
            primary: {
              main: "#372C08",
            },
            text: {
              disabled: "rgba(221, 221, 221,0.2)",
              primary: "#F8F4E8",
            },
            secondary: {
              main: "#F8F4E8",
            },
            action: {
              active: "#F8F4E8",
              hover: "rgba(221, 221, 221,0.4)",
            },
          }
        : {
            background: {
              default: "#fff",
              paper: "whitesmoke",
            },
            primary: {
              main: "#000",
            },
            text: {
              disabled: "rgba(221, 221, 221,0.2)",
              primary: "#fff",
            },
            secondary: {
              main: "#000",
            },
            action: {
              active: "#fff",
              hover: "rgba(221, 221, 221,0.2)",
            },
          }),
    },
    typography: {
      fontFamily: "Lato",
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 14,
      },
      h1: {
        fontSize: 20,
        fontWeight: 900,
      },
      h2: {
        fontSize: 18,
        fontWeight: 900,
      },
      h3: {
        fontSize: 16,
        fontWeight: 700,
      },
      h4: {
        fontSize: 14,
        fontWeight: 900,
      },
      h5: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 14,
        fontWeight: 400,
      },
      caption: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#fff",
            "--TextField-brandBorderHoverColor": "#fff",
            "--TextField-brandBorderFocusedColor": "#fff",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
            "& label.MuiFormLabel-root": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:before, &:after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  })
}

export default theme
