import { gql } from "@apollo/client"

export const TRANSPORTER_FRAGMENT = gql(`
  fragment TransporterFragment on Transporter {
    id
    name              
    phoneNumber      
    email
    webSite           
    address
    isLocal        
    TransporterZones {
      id
      name
      Countries {
        id
        name_fr
      }
    }           
  }
`)
