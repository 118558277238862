import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface ItemTypeState {
  pagination: Pagination
  query: string
}

const initialState: ItemTypeState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const itemtypeSlice = createSlice({
  name: "itemType",
  initialState,
  reducers: {
    setItemTypePagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setItemTypeQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})
export const selectItemTypePagination = (state: RootState) =>
  state.itemType.pagination
export const selectItemTypeQuery = (state: RootState) => state.itemType.query
export const { setItemTypePagination, setItemTypeQuery } = itemtypeSlice.actions
export default itemtypeSlice.reducer
