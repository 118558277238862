const env = import.meta.env

const config = {
  GRAPHQL_URL:
    env.VITE_REACT_APP_GRAPHQL_URL || "https://graphql.ceedow.world/graphql",
  GRAPHQL_WS_URL:
    env.VITE_REACT_APP_GRAPHQL_WS_URL || "wss://graphql.ceedow.world/graphql",
  encrypt: {
    secret: env.VITE_ENCRPT_SECRET,
  },
  admin: {
    email: env.VITE_DEFAULT_EMAIL,
  },
  firebase: {
    apiKey: env.VITE_API_KEY,
    authDomain: env.VITE_AUTH_DOMAIN,
    projectId: env.VITE_PROJECT_ID,
    storageBucket: env.VITE_STORAGE_BUCKET,
    messagingSenderId: env.VITE_MESSAGING_SENDER_ID,
    appId: env.VITE_APP_ID,
    measurementId: env.VITE_MEASUREMENT_ID,
  },
  isOnDev: false,
  nofitication:{
    vapidKey: env.VITE_APP_VAPID_KEY
  }
}
export default config
