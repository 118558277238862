import { Stack, Avatar, Typography } from "@mui/material"

interface Props {
  firstName: string
  lastName: string
  src: string
  direction?: "row" | "row-reverse" | "column" | "column-reverse"
}

const UserAvatar = ({ firstName, lastName, src, direction = "row" }: Props) => {
  return (
    <Stack
      className="usercard"
      direction={direction}
      spacing={1}
      sx={{ alignItems: "center", flexDirection: "" }}
    >
      <Avatar
        src={src}
        alt={firstName[0]}
        sx={{ width: "28px", height: "28px" }}
      />
      <Typography variant="subtitle2" fontSize={12}>
        {lastName || firstName}
      </Typography>
    </Stack>
  )
}

export default UserAvatar
