import React from "react"
import { Box, Typography } from "@mui/material"
import MessageImage from "../MessageImage/MessageImage"
import moment from "moment"
import { AskDeliveryMan } from "../../../../../../gql/graphql"

interface Props {
  askDeliveryMan: AskDeliveryMan
}

const MessageDelivry = ({ askDeliveryMan }: Props) => {
  return (
    <Box>
      <Box sx={{ marginY: 2 }}>
        <Typography>
          J'ai terminé la commande et voici les informations de la commande
          ainsi que les détails de la livraison.
        </Typography>
      </Box>
      <Box>
        <Box sx={{ marginY: 2 }}>
          <Typography variant="body2" fontWeight="bold">
            Date de livraison prévue:
          </Typography>
          <Typography>
            {moment(askDeliveryMan.delivryDate).format("LLL")}
          </Typography>
        </Box>
        <Box sx={{ marginY: 2 }}>
          <Typography variant="body2" fontWeight="bold">
            Adresse de livraison prévue:
          </Typography>
          <Typography>{askDeliveryMan.address}</Typography>
        </Box>
        <Box>
          <Box sx={{ marginY: 2 }}>
            <Typography variant="body2" fontWeight="bold">
              Photos des commandes:
            </Typography>
          </Box>
          <Box sx={{ marginY: 2 }}>
            <MessageImage files={askDeliveryMan.photos} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MessageDelivry
