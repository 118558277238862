import { useNavigate } from "react-router-dom"
import { NotificationDataType } from "../gql/graphql"

const useNotification = () => {
  const navigate = useNavigate()

  const handleNavigate = (data: string, type: NotificationDataType) => {
    if (data) {
      switch (type) {
        case NotificationDataType.Order:
        case NotificationDataType.Chatmessage:
          const notifcationData = JSON.parse(data) as any
          navigate(`/order/detail/${notifcationData.orderId}`)
          break
        case NotificationDataType.Newcraftman:
          const craftmanData = JSON.parse(data) as any
          navigate(`/craftman/detail/${craftmanData.craftmanId}`)
          break
        case NotificationDataType.Newcustomer:
          const customerData = JSON.parse(data) as any
          navigate(`/user/detail/${customerData.customerId}`)
          break
        case NotificationDataType.Askcraftmanpayment:
          navigate(`/?tabs=1`)
          break
        case NotificationDataType.Askdelivryman:
          navigate(`/?tabs=2`)
          break
        default:
          navigate("/")
          break
      }
    } else {
      navigate("/")
    }
  }

  return {
    handleNavigate,
  }
}

export default useNotification
