import { Outlet, useLocation, useNavigate } from "react-router-dom"
import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import DrawerBar from "./Drawer/Drawer"
import AppBarBody from "./AppBar/AppBar"
import { useMediaQuery, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../store/app/hooks"
import {
  selectMenuStatus,
  toogleOpen,
} from "../../store/features/menu/menuSlice"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { Box, Fab } from "@mui/material"
import { graphql } from "../../gql"
import { useSubscription } from "@apollo/client"
import { getUserId } from "../../utils/authToken"

const NEW_NOTIFICATION = graphql(`
  subscription NewNotificationSubcription($receiverId: Float!) {
    newNotification(receiverId: $receiverId) {
      body_en
      body_fr
      createdAt
      data
      id
      read
      receiverId
      title_en
      title_fr
      type
    }
  }
`)

export default function Layout() {
  const div = React.useRef<any>(null)
  const theme = useTheme()
  const open = useAppSelector(selectMenuStatus)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClose = () => {
    dispatch(toogleOpen())
  }

  const handleClickOutside = (event: any) => {
    if (
      div &&
      div?.current &&
      div?.current?.contains(event.target) &&
      isMobile &&
      open
    ) {
      event.stopPropagation()
      handleClose()
    }
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const isPathRoute = React.useCallback(() => {
    return location.pathname === "/" ? true : false
  }, [location])

  useSubscription(NEW_NOTIFICATION, {
    variables: {
      receiverId: getUserId(),
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (getUserId()) {
        const newNotification = subscriptionData.data?.newNotification
        if (newNotification)
          client.cache.modify({
            id: client.cache.identify({
              __typename: "User",
              id: getUserId(),
            }),
            fields: {
              countNotification(existing = 0) {
                return existing + 1
              },
              Notifications(existing = [], { toReference }) {
                return [toReference(newNotification, true), ...existing]
              },
              countNewNotification(existing = 0) {
                return existing + 1
              },
            },
          })
      }
    },
    onError(error) {
      console.log(error)
    },
  })

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarBody />
      <DrawerBar />
      <Box
        component="main"
        sx={{
          backgroundColor: "#120E02",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
        onClick={handleClickOutside}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }}>
          <div id="detail">
            <Outlet />
            {!isPathRoute() && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: isMobile ? 90 : 120,
                  right: isMobile ? "50px" : "50px",
                  zIndex: 1,
                }}
              >
                <Fab color="secondary" aria-label="add" onClick={handleGoBack}>
                  <KeyboardBackspaceIcon />
                </Fab>
              </Box>
            )}
          </div>
        </Container>
        <div
          ref={div}
          className={isMobile && open ? "bodyDrawerACtive" : "bodyDrawer"}
        />
      </Box>
    </Box>
  )
}
