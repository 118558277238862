export function isContainsEmailOrNumber(message: string) {
    // Check for email-like pattern using a basic regular expression
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  
    // Check for more than  5 successive digits
    const moreThanFiveDigitsPattern = /\d{3,}/;
  
    const containsEmail = emailPattern.test(message.trim());
    const containsMoreThanFiveDigits = moreThanFiveDigitsPattern.test(message);
  
    return containsEmail || containsMoreThanFiveDigits;
  }
  