import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useMutation } from "@apollo/client"
import { graphql } from "../../../../../gql"
import { useAppDispatch } from "../../../../../store/app/hooks"
import { setOpenSnackbar } from "../../../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../../../utils/getErrorsAsString"
import { ActionTransaction } from "../../../../../gql/graphql"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import Loader from "../../../../Common/Loader/Loader"

interface Props {
  open: boolean
  handleClose: () => void
  cbSuccess: () => void
  craftmanId: number
  jackpot: number
}

export const CREATE_JACKPOT = graphql(`
  mutation CreateCraftmanWallet(
    $action: ActionTransaction!
    $craftmanId: Float!
    $description: String
    $price: Float!
  ) {
    createCraftmanWallet(
      action: $action
      craftmanId: $craftmanId
      description: $description
      price: $price
    ) {
      id
    }
  }
`)

export default function ModifyJackpot({
  open,
  handleClose,
  craftmanId,
  jackpot,
  cbSuccess,
}: Props) {
  const [createAdvicePrice, { loading }] = useMutation(CREATE_JACKPOT)
  const [action, setAction] = React.useState("debit")

  const handleChange = (event: SelectChangeEvent) => {
    setAction(event.target.value as string)
  }

  const dispatch = useAppDispatch()
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const priceNumber = Number(formJson.price.replace(/ /g, ""))
            const description = formJson.description || ""

            if (isNaN(priceNumber)) {
              dispatch(
                setOpenSnackbar({
                  message: "Veuillez vérifier le montant, s'il vous plait.",
                }),
              )
              return
            }

            if (priceNumber > jackpot && action === "debit") {
              dispatch(
                setOpenSnackbar({
                  message:
                    "Impossible de débiter la cagnotte de l'artisan car le montant donné est supérieur à celui dans la cagnotte. Veuillez vérifier, s'il vous plaît.",
                }),
              )
              return
            }
            createAdvicePrice({
              variables: {
                action:
                  action === "debit"
                    ? ActionTransaction.Debit
                    : action === "credit"
                    ? ActionTransaction.Credit
                    : ActionTransaction.Prime,
                craftmanId,
                price: priceNumber,
                description,
              },
              onCompleted() {
                cbSuccess()
              },
              onError(err) {
                const message = getErrorsAsString(err)
                dispatch(setOpenSnackbar({ message }))
              },
            })
          },
        }}
      >
        <DialogTitle variant="h4">Modifier la cagnotte</DialogTitle>
        <DialogContent>
          <DialogContentText color={"white"}>
            Veuillez entrer dans les champs ci-dessous leurs valeurs
            respectives, puis cliquez sur le bouton 'Valider' pour confirmer.
          </DialogContentText>
          <Box mt={2}>
            <TextField
              autoFocus
              required
              id="price"
              name="price"
              margin="dense"
              label="Montant"
              placeholder="Tapez ici le montant que vous souhaitez..."
              type="number"
              fullWidth
              variant="outlined"
              helperText={
                <Typography
                  variant="caption"
                  fontSize={"12.5px"}
                  color={"white"}
                >
                  Le montant ici doit correspondre à la devise de l'artisan.
                </Typography>
              }
            />
          </Box>
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" sx={{ color: "white" }}>
                Action*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={action}
                label="Action*"
                onChange={handleChange}
              >
                <MenuItem value={"debit"}>Débit</MenuItem>
                <MenuItem value={"credit"}>Crédit</MenuItem>
                <MenuItem value={"prime"}>Prime</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <TextField
              autoFocus
              id="description"
              name="description"
              margin="dense"
              label="Motifs"
              placeholder="Tapez ici le motif de la transaction..."
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={6}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "red",
            }}
            onClick={handleClose}
            color="error"
            variant="contained"
          >
            Fermer
          </Button>
          <Button
            sx={{
              backgroundColor: "green",
            }}
            type="submit"
            color="success"
            variant="contained"
            disabled={loading}
          >
            {loading ? <Loader size={25} /> : "Valider"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
