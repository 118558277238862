import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListTransporter from "../../Common/CustomListTransporter/CustomListTransporter"
import CustomListTransporterZone from "../../Common/CustomListTransporterZone/CustomListTransporterZone"
import { useState } from "react"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  getTransporter: (transporters: InfinityListItem[]) => void
  transporterDefault: InfinityListItem[]
  getTransporterZone: (transporterZones: InfinityListItem[]) => void
  transporterZoneDefault: InfinityListItem[]
  weightMin: string
  price: string
  weightMax: string
  loading: boolean
  title: string
}

export default function FormTransporterPrice({
  handleSubmit,
  handleInputChange,
  price,
  weightMax,
  weightMin,
  loading,
  title,
  getTransporterZone,
  getTransporter,
  transporterZoneDefault,
  transporterDefault,
}: Props) {
  const [countries, setCountries] = useState<string[]>([])
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="weightMin"
                  type="number"
                  onChange={handleInputChange}
                  label="Poids minimum"
                  fullWidth
                  variant="outlined"
                  value={weightMin}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="weightMax"
                  type="number"
                  onChange={handleInputChange}
                  label="Poids maximum"
                  fullWidth
                  variant="outlined"
                  value={weightMax}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="price"
                  type="number"
                  onChange={handleInputChange}
                  label="Tarif du transport"
                  fullWidth
                  variant="outlined"
                  value={price}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomListTransporter
                  transporterDefault={transporterDefault}
                  getTransporter={(value) =>
                    getTransporter(value as InfinityListItem[])
                  }
                  multiple
                  required={false}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomListTransporterZone
                  transporterZoneDefault={transporterZoneDefault}
                  getTransporterZone={(value) =>
                    getTransporterZone(value as InfinityListItem[])
                  }
                  getCountries={(names) => {
                    const uniqueNames = new Set(names)
                    setCountries(Array.from(uniqueNames))
                  }}
                  multiple
                  required={false}
                />
              </Grid>
              {Boolean(countries.length) && (
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ textDecorationLine: "underline" }}
                    >
                      Pays inclus dans la zone:
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography>{countries.join(", ")}</Typography>
                  </Box>
                </Grid>
              )}
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
}
