import { CircularProgress } from "@mui/material"

interface Props {
  size?: number
}

const Loader = ({ size = 30 }: Props) => {
  return <CircularProgress size={size} color="secondary" />
}
export default Loader
