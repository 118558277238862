import { gql } from "@apollo/client"

export const ORDER_FRAGMENT = gql(`
  fragment OrderFragment on Order {
    id
    deadline
    status
    createdAt
    oldID
    updatedAt
    Customer {
      id
      email
      name
      lastName
    }
    Craftman {
      id
      email
      name
      lastName
    }
    Country {
      id
      name_fr
      currency
    }
    Categories {
      id
      name_fr
    }
    ItemTypes {
      id
      name_fr
    }
    MaterialTypes {
      id
      name_fr
    }
    Photos {
      id
      name
      url
    }
  }
`)
