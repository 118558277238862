import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { graphql } from "../../../../../../gql"
import { useMutation } from "@apollo/client"
import { getErrorsAsString } from "../../../../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../../../../store/features/snackbar/snackbarSlice"
import { useAppDispatch } from "../../../../../../store/app/hooks"
import Loader from "../../../../../Common/Loader/Loader"

interface Props {
  open: boolean
  handleClose: () => void
  chatId: number
  orderId: number
  receiverId: number
}

export const CREATE_ADVICE_PRICE = graphql(`
  mutation AdviceNewOrderPrice(
    $receiverId: Float!
    $price: Float!
    $orderId: Float!
    $chatId: Float!
  ) {
    adviceNewOrderPrice(
      receiverId: $receiverId
      price: $price
      orderId: $orderId
      chatId: $chatId
    ) {
      id
      chatId
      createdAt
      updatedAt
      content
      receiverId
      senderId
      contentType
      Files {
        id
        name
        url
        type
        size
      }
      Sender {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      Receiver {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      OrderPrice {
        id
        price
        status
        currenctUserPrice
        createdAt
        updatedAt
      }
    }
  }
`)

export default function FormAdvicePrice({
  open,
  handleClose,
  chatId,
  orderId,
  receiverId,
}: Props) {
  const [createAdvicePrice, { loading }] = useMutation(CREATE_ADVICE_PRICE)
  const dispatch = useAppDispatch()
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const priceNumber = Number(formJson.price.replace(/ /g, ""))

            if (priceNumber < 100 || isNaN(priceNumber)) {
              dispatch(
                setOpenSnackbar({
                  message:
                    "Veuillez noter que le prix que vous avez indiqué doit dépasser 100 CFA. Pourriez-vous vérifier et ajuster le prix si nécessaire ?",
                }),
              )
              return
            }
            createAdvicePrice({
              variables: {
                chatId,
                receiverId,
                orderId,
                price: priceNumber,
              },
              onCompleted() {
                handleClose()
              },
              onError(err) {
                const message = getErrorsAsString(err)
                dispatch(setOpenSnackbar({ message }))
              },
            })
          },
        }}
      >
        <DialogTitle variant="h4">Proposer un prix</DialogTitle>
        <DialogContent>
          <DialogContentText color={"white"}>
            Veuillez entrer dans le champ ci-dessous votre prix, puis cliquez
            sur le bouton 'Valider' pour le confirmer.
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="price"
            name="price"
            margin="dense"
            label=""
            placeholder="Tapez ici votre prix..."
            type="number"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "red",
            }}
            onClick={handleClose}
            color="error"
            variant="contained"
          >
            Fermer
          </Button>
          <Button
            sx={{
              backgroundColor: "green",
            }}
            type="submit"
            color="success"
            variant="contained"
            disabled={loading}
          >
            {loading ? <Loader size={25} /> : "Valider"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
