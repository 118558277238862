import { gql } from "@apollo/client"

export const ADMIN_FRAGMENT = gql(`
  fragment AdminFragment on User {
    id
    phoneNumber
    email
    role
    name
    lastName
    isActiveAdmin
    ProfilePhoto {
      url
      id
    }
    country {
      id
      name_fr
    }
  }
`)
