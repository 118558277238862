import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import storage from "../firebase/firebase"
import { NewFileInput } from "../gql/graphql"
import imageCompression from "browser-image-compression"
import config from "../config/config"

export const uploadFiles = async ({
  files,
  getPrecent,
  cbErr,
}: {
  files: File[]
  getPrecent?: (value: number) => void
  cbErr: (err: any) => void
}) => {
  try {
    let images: NewFileInput[] = []
    const uploadPromises = files.map(async (file, index) => {
      if (!file || file.size > 100 * 1024 * 1024) {
        return Promise.resolve()
      }
      const path = file.type.includes("image")
        ? "images/"
        : file.type.includes("audio")
        ? "audios/"
        : "videos/"

      const prePath = config.isOnDev ? "/test-" : "/"

      const storageRef = ref(storage, `${prePath}${path}${file.name}`)

      // Compress the file if it's an image
      let compressedFile = file
      if (file.type.includes("image")) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        compressedFile = await imageCompression(file, options)
      }

      const uploadTask = uploadBytesResumable(storageRef, compressedFile)

      return new Promise<void>((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            getPrecent && getPrecent(progress)
          },
          undefined,
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {
                images.push({
                  name: file.name,
                  type: file.type,
                  size: compressedFile.size,
                  url: url,
                })
                resolve()
              })
              .catch(reject)
          },
        )
      })
    })

    await Promise.all(uploadPromises)
    return images
  } catch (error) {
    cbErr(error)
  }
}
