import { Avatar, Grid, Paper } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { havePermissions } from "../../../utils/permissions"

interface Props {
  icon: React.ReactNode
  color: string
  label: string
  value: string
  route: string
  permission: string
}

const CardStat = ({ color, icon, label, value, route, permission }: Props) => {
  const navigate = useNavigate()
  const handleGo = () => {
    if (havePermissions([permission])) {
      navigate(route)
    }
  }
  if (!havePermissions([permission])) return null
  return (
    <Paper
      onClick={handleGo}
      sx={{
        p: 2,
        height: "18vh",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        cursor: havePermissions([permission]) ? "pointer" : "auto",
        backgroundColor: "#5D490E",
        "&:hover": {
          backgroundColor: "#4A3A0B",
          transition: "all 0.2s ease-in-out",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
        >
          <Avatar sx={{ bgcolor: color, width: 50, height: 50 }}>{icon}</Avatar>
        </Grid>
        <Grid item lg={12} xs={12}>
          <HeaderAdd title={value as string} variant="h1" />
        </Grid>
        <Grid item lg={12} xs={12}>
          <HeaderAdd title={label} variant="h5" />
        </Grid>
      </Grid>
    </Paper>
  )
}
export default CardStat
