import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheSegmentation } from "../../../caches/updateCacheSegmentation"
import FormSegmentation from "../FormSegmentation/FormSegmentation"
import Loader from "../../Common/Loader/Loader"

const editSegmentationMutation = graphql(`
  mutation updateOneSegmentation(
    $data: SegmentationUpdateInput!
    $where: SegmentationWhereUniqueInput!
  ) {
    updateOneSegmentation(data: $data, where: $where) {
      id
      name_fr
      name_en
    }
  }
`)

const getOneSegmentationQuery = graphql(`
  query getOneSegmentationQuery($where: SegmentationWhereUniqueInput!) {
    segmentation(where: $where) {
      id
      name_en
      name_fr
    }
  }
`)

export default function EditSegmentation() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [segmentation, setSegmentation] = useState({
    name_fr: "",
    name_en: "",
  })

  const [getSegmentation, { loading: loadingInit, error }] = useLazyQuery(
    getOneSegmentationQuery,
    {
      onCompleted(data) {
        setSegmentation({
          name_en: data?.segmentation?.name_en || "",
          name_fr: data?.segmentation?.name_fr || "",
        })
      },
    },
  )

  const [updateSegmentation, { loading }] = useMutation(
    editSegmentationMutation,
  )

  const handleInputChange = (event: any) => {
    setSegmentation({
      ...segmentation,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateSegmentation = (e: any) => {
    e.preventDefault()
    updateSegmentation({
      variables: {
        data: {
          name_en: {
            set: segmentation.name_en,
          },
          name_fr: {
            set: segmentation.name_fr,
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/segmentation/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheSegmentation({
          action: "update",
          cache,
          entryData: data?.updateOneSegmentation,
        })
      },
    })
  }

  useEffect(() => {
    getSegmentation({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormSegmentation
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateSegmentation}
      loading={loading}
      name_en={segmentation.name_en}
      name_fr={segmentation.name_fr}
      title="Modifier une segmentation"
    />
  )
}
