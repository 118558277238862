import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheMaterialType } from "../../../caches/updateCacheMaterialType"
import FormMaterialType from "../FormMaterialType/FormMaterialType"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"

const editMaterialTypeMutation = graphql(`
  mutation editMaterialTypeMutation(
    $data: MaterialTypeUpdateInput!
    $where: MaterialTypeWhereUniqueInput!
  ) {
    updateOneMaterialType(data: $data, where: $where) {
      id
      name_fr
      name_en
      Countries {
        id
        name_fr
      }
      Category {
        name_fr
        id
        name_en
      }
    }
  }
`)

const getOneMaterialTypeQuery = graphql(`
  query getOneMaterialTypeQuery($where: MaterialTypeWhereUniqueInput!) {
    materialType(where: $where) {
      id
      name_fr
      name_en
      Countries {
        id
        name_fr
      }
      Category {
        id
        name_fr
        name_en
      }
    }
  }
`)

export default function EditMaterialType() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0
  const [country, setCountry] = useState<InfinityListItem[]>([])
  const [materialType, setMaterialType] = useState({
    name_fr: "",
    name_en: "",
  })
  const [existingCountryIds, setExistingCountryIds] = useState<number[]>([])
  const [category, setCategory] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const getCategory = (value: InfinityListItem) => {
    setCategory(value)
  }

  const [getMaterialType, { loading: loadingInit, error }] = useLazyQuery(
    getOneMaterialTypeQuery,
    {
      onCompleted(data) {
        const id = data?.materialType?.Category?.id
        const countries = data.materialType?.Countries
        const countryIds = countries?.map((el) => el.id)
        if (!isSuperAdmin()) {
          if (countryIds) {
            if (!countryIds.includes(getUserCountryId())) {
              navigate("/")
            }
          }
        }
        if (countries && countries.length) {
          setCountry([
            ...countries.map((el) => ({
              label: el.name_fr,
              value: el.id.toString(),
            })),
          ])
          setExistingCountryIds([...countries.map((el) => el.id)])
        }
        setMaterialType({
          name_en: data?.materialType?.name_en || "",
          name_fr: data?.materialType?.name_fr || "",
        })
        setCategory({
          label: data?.materialType?.Category?.name_fr || "",
          value: id ? String(id) : "",
        })
      },
    },
  )

  const [updateMaterialType, { loading }] = useMutation(
    editMaterialTypeMutation,
  )

  const handleInputChange = (event: any) => {
    setMaterialType({
      ...materialType,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateMaterialType = (e: any) => {
    e.preventDefault()
    if (!country.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez préciser le pays, s'il vous plait.",
        }),
      )
      return
    }
    updateMaterialType({
      variables: {
        data: {
          name_en: {
            set: materialType.name_en,
          },
          name_fr: {
            set: materialType.name_fr,
          },
          Category: {
            connect: { id: Number(category.value) },
          },
          Countries: {
            ...(isSuperAdmin() && {
              disconnect: existingCountryIds.map((id) => ({ id })),
            }),
            connect: country.map((el) => ({ id: +el.value })),
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/material-type/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheMaterialType({
          action: "update",
          cache,
          entryData: data?.updateOneMaterialType,
        })
      },
    })
  }

  const getCountry = (value: InfinityListItem[]) => {
    if (value) {
      setCountry(value)
    } else {
      setCountry([])
    }
  }

  useEffect(() => {
    getMaterialType({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormMaterialType
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateMaterialType}
      loading={loading}
      name_en={materialType.name_en}
      name_fr={materialType.name_fr}
      title="Modifier un type matère"
      getCategory={getCategory}
      category={category}
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
