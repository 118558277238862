import * as React from "react"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { ChatMessageType } from "../MessageCard"
import { ContentMessageType, Role } from "../../../../../../gql/graphql"
import { useAppDispatch } from "../../../../../../store/app/hooks"
import {
  setCloseAlert,
  setLoadingDelete,
  setOpenAlert,
} from "../../../../../../store/features/alert/alertSlice"
import { graphql } from "../../../../../../gql"
import { useMutation } from "@apollo/client"
import { setOpenSnackbar } from "../../../../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../../../../utils/getErrorsAsString"

const ITEM_HEIGHT = 48

interface Props {
  message: ChatMessageType
}

const EDIT_MESSAGE = graphql(`
  mutation EditContentMessage(
    $chatMessageId: Float!
    $content: String
    $isDelete: Boolean
  ) {
    editContentMessage(
      chatMessageId: $chatMessageId
      content: $content
      isDelete: $isDelete
    ) {
      id
      content
      updatedAt
      createdAt
      isDelete
    }
  }
`)

export default function MenuActionMessage({ message }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useAppDispatch()
  const [updateChatMessage] = useMutation(EDIT_MESSAGE)
  const [deleteChatMessage] = useMutation(EDIT_MESSAGE)
  const [newMessage, setNewMessage] = React.useState(message?.content || "")
  const [openEdit, setOpenEdit] = React.useState(false)

  const handleClickOpenEdit = () => {
    setOpenEdit(true)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const isText =
    message.contentType === ContentMessageType.Text && message.content

  const handleValidDelete = () => {
    setAnchorEl(null)
    dispatch(setLoadingDelete(true))
    deleteChatMessage({
      variables: {
        chatMessageId: message.id,
        content: message.content,
        isDelete: true,
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le message a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update(cache, { data }) {
        const existingData = data?.editContentMessage
        cache.modify({
          id: cache.identify({
            __typename: "ChatMessage",
            id: message.id,
          }),
          fields: {
            isDelete(existingValue) {
              return Boolean(message?.isDelete)
            },
            updatedAt(existingValue) {
              return existingData?.updatedAt
            },
            content(existingValue) {
              return existingData?.content ? message?.content : existingValue
            },
          },
        })
      },
    })
  }

  const handleOpenDeleteDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ce message ?",
        isLoading: false,
      }),
    )
  }

  const handleValidEdit = () => {
    setAnchorEl(null)
    dispatch(setLoadingDelete(true))
    updateChatMessage({
      variables: {
        chatMessageId: message.id,
        content: newMessage,
        isDelete: false,
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le message a été modifié avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
        handleCloseEdit()
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        handleCloseEdit()
        dispatch(setOpenSnackbar({ message }))
      },
      update(cache, { data }) {
        const existingData = data?.editContentMessage
        cache.modify({
          id: cache.identify({
            __typename: "ChatMessage",
            id: message.id,
          }),
          fields: {
            isDelete(existingValue) {
              return Boolean(message?.isDelete)
            },
            updatedAt(existingValue) {
              return existingData?.updatedAt
            },
            content(existingValue) {
              return existingData?.content ? message?.content : existingValue
            },
          },
        })
      },
    })
  }

  const handleOpenEditDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => {
          dispatch(setCloseAlert())
          handleClickOpenEdit()
        },
        message: "Êtes-vous vraiment sûr de vouloir modifier ce message ?",
        isLoading: false,
      }),
    )
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleOpenDeleteDialog}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Supprimer
          </Typography>
        </MenuItem>
        {isText && (
          <MenuItem onClick={handleOpenEditDialog}>
            <ListItemIcon>
              <EditIcon color="warning" fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Modifier
            </Typography>
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleValidEdit()
          },
        }}
      >
        <DialogTitle>Modifiez le message</DialogTitle>
        <DialogContent>
          <DialogContentText color={"white"}>
            Veuillez saisir dans le champs ci-dessous le nouveau message que
            vous souhaitez.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="message"
            name="message"
            label="Nouveau message"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={4}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCloseEdit}>
            Annuler
          </Button>
          <Button color="success" type="submit">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
