import { styled } from "@mui/material/styles"
import Toolbar, { ToolbarProps } from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { Avatar, Box, Typography } from "@mui/material"
import { useAppDispatch } from "../../../../store/app/hooks"
import { toogleOpen } from "../../../../store/features/menu/menuSlice"
import { getUserName } from "../../../../utils/authToken"
import { spliceString } from "../../../../utils/methodeString"

const ToolbarStyled = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  px: [1],
  backgroundColor: "#5D490E",
  "&.MuiToolbar-root": {
    minHeight: "50px",
  },
}))

export default function DrawerHeader() {
  const dispatch = useAppDispatch()
  const name = getUserName() || "Admin"
  return (
    <ToolbarStyled>
      <Box>
        <Avatar sx={{ width: 35, height: 35, background: "white" }}>
          {name[0]}
        </Avatar>
      </Box>
      <Box mx={1}>
        <Typography style={{ color: "white", whiteSpace: "break-spaces" }}>
          {spliceString(name, 10)}
        </Typography>
      </Box>
      <Box>
        <IconButton color="default" onClick={() => dispatch(toogleOpen())}>
          <ChevronLeftIcon style={{ color: "white" }} />
        </IconButton>
      </Box>
    </ToolbarStyled>
  )
}
