import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import FormTransporterPrice from "../FormTransporterPrice/FormTransporterPrice"
import Loader from "../../Common/Loader/Loader"
import { updateCacheTransporterPrice } from "../../../caches/updateCacheTransporterPrice"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { selecttransporterPriceTransporters, selecttransporterPriceZones, setTransporterPriceTransporters, setTransporterPriceZones } from "../../../store/features/transporterPrice/transporterPriceSlice"

const editTransporterPriceMutation = graphql(`
  mutation updateOneTransporterPrice(
    $data: TransporterPriceUpdateInput!
    $where: TransporterPriceWhereUniqueInput!
  ) {
    updateOneTransporterPrice(data: $data, where: $where) {
      id
      weightMin
      weightMax
      price
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
      Transporters {
        id
        name
      }
    }
  }
`)

const getOneTransporterPriceQuery = graphql(`
  query getOneTransporterPriceQuery($where: TransporterPriceWhereUniqueInput!) {
    transporterPrice(where: $where) {
      id
      weightMin
      weightMax
      price
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
      Transporters {
        id
        name
      }
    }
  }
`)

export default function EditTransporterPrice() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const [transporterprice, setTransporterPrice] = useState({
    weightMin: "",
    weightMax: "",
    price: "",
  })

  const transporters = useAppSelector(selecttransporterPriceTransporters)
  const transporterZones = useAppSelector(selecttransporterPriceZones)

  const [oldTransporters, setOldTransporters] = useState<InfinityListItem[]>([])
  const [oldTransporterZones, setOldTransporterZones] = useState<
    InfinityListItem[]
  >([])

  const [getTransporterPrice, { loading: loadingInit, error }] = useLazyQuery(
    getOneTransporterPriceQuery,
    {
      onCompleted(data) {
        setTransporterPrice({
          price: data?.transporterPrice?.price?.toString() || "",
          weightMax: data?.transporterPrice?.weightMax?.toString() || "",
          weightMin: data?.transporterPrice?.weightMin?.toString() || "",
        })
        if (data.transporterPrice?.Transporters) {
          const transporters = data.transporterPrice?.Transporters.map(
            (el) => ({
              value: el.id.toString(),
              label: el.name,
            }),
          )
          setOldTransporters(transporters)
          dispatch(setTransporterPriceTransporters(transporters))
        }

        if (data.transporterPrice?.TransporterZones) {
          const transporterZones = data.transporterPrice?.TransporterZones.map(
            (el) => ({
              value: el.id.toString(),
              label: el.name,
            }),
          )
          setOldTransporterZones(transporterZones)
          dispatch(setTransporterPriceZones(transporterZones))
        }
      },
    },
  )

  const [updateTransporterPrice, { loading }] = useMutation(
    editTransporterPriceMutation,
  )

  const handleInputChange = (event: any) => {
    setTransporterPrice({
      ...transporterprice,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateTransporterPrice = (e: any) => {
    e.preventDefault()
    if (!transporters.length) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les transporteurs qui sont inclus dans ce tarif",
        }),
      )
      return
    }
    if (!transporterZones.length) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les zones qui sont inclus dans ce tarif",
        }),
      )
      return
    }
    updateTransporterPrice({
      variables: {
        data: {
          weightMin: {
            set: +transporterprice.weightMin,
          },
          weightMax: {
            set: +transporterprice.weightMax,
          },
          price: {
            set: +transporterprice.price,
          },
          Transporters: {
            connect: transporters.map((el) => ({ id: +el.value })),
            disconnect: oldTransporters.map((el) => ({ id: +el.value })),
          },
          TransporterZones: {
            connect: transporterZones.map((el) => ({ id: +el.value })),
            disconnect: oldTransporterZones.map((el) => ({ id: +el.value })),
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/transporter-price/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporterPrice({
          action: "update",
          cache,
          entryData: data?.updateOneTransporterPrice,
        })
      },
    })
  }

  useEffect(() => {
    getTransporterPrice({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getTransporter = (transporters: InfinityListItem[]) => {
    dispatch(setTransporterPriceTransporters(transporters))
  }

  const getTransporterZone = (transporterZones: InfinityListItem[]) => {
    dispatch(setTransporterPriceZones(transporterZones))
  }

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormTransporterPrice
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateTransporterPrice}
      loading={loading}
      price={transporterprice.price}
      weightMax={transporterprice.weightMax}
      weightMin={transporterprice.weightMin}
      title="Modifier un tarif de transport"
      getTransporter={getTransporter}
      getTransporterZone={getTransporterZone}
      transporterDefault={transporters}
      transporterZoneDefault={transporterZones}
    />
  )
}
