import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selectCountryPagination,
  selectCountryQuery,
  setPaginationCountry,
  setQueryCountry,
} from "../../../store/features/country/countrySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { Country, CountryWhereInput, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheCountry } from "../../../caches/updateCacheCountry"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { havePermissions } from "../../../utils/permissions"

export const LIST_COUNTRIES = graphql(`
  query Countries(
    $where: CountryWhereInput
    $orderBy: [CountryOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    countries(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
      name_en
      alpha2
      alpha3
      currency
      isOpen
      ceedowCommission
    }
    aggregateCountry {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_COUNTRY = graphql(`
  mutation DeleteOneCountry($where: CountryWhereUniqueInput!) {
    deleteOneCountry(where: $where) {
      id
      name_fr
      name_en
      alpha2
      alpha3
      currency
    }
  }
`)

const ListCountry = () => {
  const { page, size } = useAppSelector(selectCountryPagination)
  const query = useAppSelector(selectCountryQuery)
  const dispatch = useAppDispatch()

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])

  const navigate = useNavigate()

  const getFilters: () => CountryWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: CountryWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "currency":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    currency: {
                      search: value,
                    },
                  },
                  {
                    currency: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "name_fr":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name_fr: {
                      search: value,
                    },
                  },
                  {
                    name_fr: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "name_en":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name_en: {
                      search: value,
                    },
                  },
                  {
                    name_en: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "ceedowCommission":
              accumulator = {
                ...accumulator,
                ceedowCommission: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name_fr: { search: query } },
            { name_en: { search: query } },
            { name_en: { endsWith: query } },
            { name_fr: { endsWith: query } },
            { name_fr: { contains: query } },
            { name_en: { contains: query } },
            { name_fr: { startsWith: query } },
            { name_en: { startsWith: query } },
            { alpha2: { search: query } },
            { alpha2: { endsWith: query } },
            { alpha2: { contains: query } },
            { alpha2: { startsWith: query } },
          ],
        }
      : {}
  }, [query])

  const [getCountries, { loading, data, previousData }] = useLazyQuery(LIST_COUNTRIES)

  useEffect(() => {
    getCountries({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { name_fr: SortOrder.Asc },
        where: {
          ...queryName(),
          ...getFilters(),
        },
      },
    })
  }, [getCountries, page, query, queryName, size, getFilters])

  const [deleteCountry] = useMutation(DELETE_COUNTRY)

  const columns = useMemo<MRT_ColumnDef<Country>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "name_fr",
        header: "Nom en fr",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name_fr",
      },
      {
        accessorKey: "name_en",
        header: "Nom en en",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name_en",
      },
      {
        accessorKey: "currency",
        header: "Devise",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "currency",
      },
      {
        accessorKey: "ceedowCommission",
        header: "Commission de Ceedow",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "ceedowCommission",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationCountry(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteCountry({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le pays a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCountry({
          action: "delete",
          cache,
          entryData: data?.deleteOneCountry,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ce pays ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryCountry(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un pays"))
    navigate(`/country/edit/${id}`)
  }

  const handleView = (id: number) => {
    dispatch(setTitle("Detail d'un pays"))
    navigate(`/country/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading && !previousData) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.countries || []}
      lableAddNew="Créer une nouveau pays"
      rootLisName={"/country/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateCountry._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["country.delete"]) ? handleOpenDeleteDialog : undefined
      }
      isHaveHeaderAction={havePermissions(["country.create"])}
      handleEdit={havePermissions(["country.change"]) ? handleEdit : undefined}
      isHaveView={havePermissions(["country.view"])}
      handleView={havePermissions(["country.view"]) ? handleView : undefined}
      isHaveAction={havePermissions([
        "country.view",
        "country.change",
        "country.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListCountry
