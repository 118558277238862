import ListOrder from "../../Order/ListOrder/ListOrder"

const OrderNoOrderPrice = () => {
  return (
    <>
      <ListOrder
        extraQuery={{
          Prices: {
            none: {},
          },
        }}
      />
    </>
  )
}
export default OrderNoOrderPrice
