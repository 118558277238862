import { Order, OrderPrice, Status } from "../gql/graphql"

const useOrder = () => {
  const getLastOrderPriceValid = (orderPrices: OrderPrice[]) => {
    const orderPricesValid = orderPrices.filter(
      (p) => p.status === Status.Valid,
    )
    if (orderPricesValid.length) {
      return orderPricesValid[0].price
    } else if (orderPrices.length) {
      const lastOrderPrice = orderPrices[0]
      if (lastOrderPrice.status === Status.Valid) {
        return lastOrderPrice.price
      }
    }
    return "Pas définie"
  }

  const getCustomerOrderPrice = (orderPrices: OrderPrice[]) => {
    if (orderPrices.length) {
      const copyOrderPrice = [...orderPrices]
      const lastPrice = copyOrderPrice.pop()
      return lastPrice?.price
    }
    return "Pas définie"
  }
  const getOrderStatus = (orderStatus?: Status) => {
    let status = ""
    if (orderStatus) {
      switch (orderStatus) {
        case Status.Paid:
          status = "Payée"
          break
        case Status.Archive:
          status = "Archivée"
          break
        case Status.Delivry:
          status = "Livrée"
          break
        case Status.Pending:
          status = "En cours"
          break
        case Status.Valid:
          status = "Validée"
          break
      }
    }
    return status
  }
  return {
    getLastOrderPriceValid,
    getCustomerOrderPrice,
    getOrderStatus
  }
}

export default useOrder
