import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface CountryState {
  pagination: Pagination
  query: string
}

const initialState: CountryState = {
  pagination: {
    page: 0,
    size: 7,
  },
  query: "",
}

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setPaginationCountry: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setQueryCountry: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})

export const selectCountryPagination = (state: RootState) =>
  state.country.pagination
export const selectCountryQuery = (state: RootState) => state.country.query
export const { setPaginationCountry, setQueryCountry } = countrySlice.actions
export default countrySlice.reducer
