import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { getUserCountryId } from "../../../utils/authToken"
import { isSuperAdmin, whereInputClause } from "../../../utils/permissions"

const TRANSPORTERZONE = graphql(`
  query ListTransporterZones(
    $where: TransporterZoneWhereInput
    $orderBy: [TransporterZoneOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    transporterZones(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
      Countries {
        id
        name_fr
      }
    }
  }
`)

interface Props {
  getTransporterZone: (
    transporterzone: InfinityListItem | InfinityListItem[],
  ) => void
  transporterZoneDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
  getCountries?: (names: any[]) => void
}
const CustomListTransporterZone = ({
  getTransporterZone,
  transporterZoneDefault,
  label = "Zones géographiques",
  required = true,
  multiple = false,
  getCountries,
}: Props) => {
  const [transporterzone, setTransporterZone] = useState(transporterZoneDefault)
  const [queryTransporterZone, setQueryTransporterZone] = useState("")
  const [openTransporterZone, setOpenTransporterZone] = useState(false)
  const size = 500

  const countryId = getUserCountryId()
  const whereCluase = whereInputClause({
    Countries: {
      some: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        { name: { search: queryTransporterZone, mode: QueryMode.Insensitive } },
        {
          name: { endsWith: queryTransporterZone, mode: QueryMode.Insensitive },
        },
        {
          name: { contains: queryTransporterZone, mode: QueryMode.Insensitive },
        },
        {
          name: {
            startsWith: queryTransporterZone,
            mode: QueryMode.Insensitive,
          },
        },
      ],
    }
    return queryTransporterZone ? OrClause : {}
  }, [queryTransporterZone])

  const { loading, data, fetchMore } = useQuery(TRANSPORTERZONE, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name: SortOrder.Asc },
      ...((queryTransporterZone || !isSuperAdmin()) && {
        where: {
          ...queryName(),
          ...whereCluase,
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryTransporterZone(query)
  }

  const handleCloseCategory = () => {
    setOpenTransporterZone(false)
  }
  const handleOpenTransporterZone = async () => {
    setOpenTransporterZone(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.transporterZones.length,
      },
    })
  }

  useEffect(() => {
    if (transporterzone && transporterZoneDefault) {
      if (
        !Array.isArray(transporterzone) &&
        !Array.isArray(transporterZoneDefault)
      ) {
        if (!transporterzone?.value && transporterZoneDefault?.value) {
          setTransporterZone(transporterZoneDefault)
        }
      } else {
        setTransporterZone(transporterZoneDefault)
      }
    }
  }, [transporterzone, transporterZoneDefault])

  const dataTransporterZone = data?.transporterZones

  const countries =
    loading || !dataTransporterZone
      ? []
      : dataTransporterZone.map((el) => ({
          label: el.name,
          value: el.id.toString(),
          extraList: el.Countries.map((el) => el.name_fr),
        }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenTransporterZone}
      open={openTransporterZone}
      defaultValue={transporterZoneDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchCategory}
      options={openTransporterZone ? countries : []}
      loading={loading}
      multiple={multiple}
      onChange={(value: InfinityListItem | InfinityListItem[]) => {
        if (value) {
          setTransporterZone(value)
          getTransporterZone(value)
          if (getCountries) {
            if (Array.isArray(value)) {
              const countries = value
                .filter((el) => el.extraList)
                .flatMap((el) => el.extraList)
              getCountries([...countries])
            } else {
              const countries = value?.extraList
              if (countries) {
                getCountries([...countries])
              }
            }
          }
        }
      }}
      query={queryTransporterZone}
      value={transporterzone}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListTransporterZone
