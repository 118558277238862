import { Button, Grid, Paper, Typography, useTheme } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { graphql } from "../../../gql"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import { useEffect } from "react"
import { File, OrderPrice, SortOrder } from "../../../gql/graphql"
import useOrder from "../../../hooks/useOrder"
import moment from "moment"
import { getStringEmpty } from "../../../utils/methodeString"
import Loader from "../../Common/Loader/Loader"
import OrderImages from "./OrderImages/OrderImages"
import Chat from "./Chat/Chat"
import { havePermissions } from "../../../utils/permissions"

const GET_ORDER = graphql(`
  query GetOrder(
    $where: OrderWhereUniqueInput!
    $orderBy: [OrderPriceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
  ) {
    getOrder(where: $where) {
      id
      status
      createdAt
      delivryDate
      quantity
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
      Country {
        id
        name_fr
      }
      Customer {
        id
        name
        lastName
        dateOfLastConnexion
        phoneNumber
      }
      Craftman {
        id
        name
        lastName
        dateOfLastConnexion
        phoneNumber
      }
      Prices(orderBy: $orderBy) {
        id
        price
        status
        currenctUserPrice
      }
      Categories {
        id
        name_fr
      }
      ItemTypes {
        id
        name_fr
      }
      MaterialTypes {
        id
        name_fr
      }
      Photos {
        id
        url
        name
      }
      Chats(take: $take) {
        id
        orderId
      }
    }
  }
`)

const DetailOrder = () => {
  const globalTheme = useTheme()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.id ? parseInt(params.id, 10) : 0
  const [getOrder, { data, loading, refetch }] = useLazyQuery(GET_ORDER)
  const { getCustomerOrderPrice, getLastOrderPriceValid } = useOrder()

  useEffect(() => {
    getOrder({
      variables: {
        where: {
          id,
        },
        orderBy: {
          createdAt: SortOrder.Desc,
        },
        take: 1,
      },
    })
  }, [id])

  const handleGoListHistory = () => {
    navigate(`/order/edit-story/${id}`)
  }

  const chatId = data?.getOrder?.Chats.length
    ? data?.getOrder?.Chats[0].id
    : undefined

  const orderId = data?.getOrder?.Chats.length
    ? data?.getOrder?.Chats[0].orderId
    : 0

  const craftmanId = data?.getOrder?.Craftman?.id
    ? data?.getOrder?.Craftman?.id
    : 0

  const customerId = data?.getOrder?.Customer.id
    ? data?.getOrder?.Customer.id
    : 0

  const getDate = (date: any) => {
    const formattedDate =
      date && moment(date).isValid()
        ? moment(date).format("DD MMM YYYY [à] HH[h]mm")
        : "Pas définie"
    return formattedDate
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading) return <Loader />

  return (
    <Grid container spacing={2} id="container-chat-message">
      <Grid item xs={12} md={chatId ? 6 : 12}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                sx={{
                  color: "#fff",
                  background: globalTheme.palette.success.main,
                }}
                size="small"
                onClick={handleRefresh}
              >
                Actualiser
              </Button>
            </Grid>
            <Grid item xs={12}>
              <HeaderAdd
                title="Information générale de la commande"
                variant="h4"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>ID commande: </b>
                {data?.getOrder?.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Date commande client: </b>
                {getDate(data?.getOrder?.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Quantité(s): </b>
                {getStringEmpty(data?.getOrder?.quantity?.toString())}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Status: </b>
                {data?.getOrder?.status}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>ID de l'artisan: </b>
                {data?.getOrder?.Craftman?.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Nom et prénom de l'artisan: </b>
                {getStringEmpty(data?.getOrder?.Craftman?.name)}{" "}
                {getStringEmpty(data?.getOrder?.Craftman?.lastName)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Numéro artisan : </b>
                {getStringEmpty(data?.getOrder?.Craftman?.phoneNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>ID de client:</b>
                {data?.getOrder?.Customer?.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Nom et prénom de client: </b>
                {getStringEmpty(data?.getOrder?.Customer?.name)}{" "}
                {getStringEmpty(data?.getOrder?.Customer?.lastName)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Numéro client : </b>
                {getStringEmpty(data?.getOrder?.Customer?.phoneNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Catégorie: </b>
                {data?.getOrder?.Categories?.flatMap(
                  (category) => category.name_fr,
                ).toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Type article: </b>
                {data?.getOrder?.ItemTypes?.flatMap(
                  (item) => item.name_fr,
                ).toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Type matière: </b>
                {data?.getOrder?.MaterialTypes?.flatMap(
                  (m) => m.name_fr,
                ).toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Prix initial de client: </b>{" "}
                {getCustomerOrderPrice(
                  (data?.getOrder?.Prices as OrderPrice[]) || [],
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Dernier prix validé: </b>
                {getLastOrderPriceValid(
                  (data?.getOrder?.Prices as OrderPrice[]) || [],
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Pays de destination: </b>
                {getStringEmpty(data?.getOrder?.Country?.name_fr)}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Dernière connexion artisan: </b>
                {getDate(data?.getOrder?.Craftman?.dateOfLastConnexion)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>Dernière connexion client: </b>
                {getDate(data?.getOrder?.Customer.dateOfLastConnexion)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>Date de livraison: </b>
                {getDate(data?.getOrder?.delivryDate)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>Modèles photos de la commande:</b>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={chatId ? 12 : 6} md={chatId ? 12 : 8}>
              <OrderImages images={(data?.getOrder?.Photos as File[]) || []} />
            </Grid>
            {havePermissions(["order.view"]) && (
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{ background: "#5D490E" }}
                  onClick={handleGoListHistory}
                >
                  Voir les historiques de modification
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {chatId && (
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <Chat
              chatId={chatId}
              orderId={orderId}
              craftmanId={craftmanId}
              customerId={customerId}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default DetailOrder
