import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import ToggleSwitch from "./SwitchButton/SwitchButton"
import permissionsList, {
  permissionsChange,
  permissionsCreate,
  permissionsDelete,
  permissionsView,
} from "../roles"
import _ from "lodash"
import HeaderChecked from "./HeaderChecked/HeaderChecked"

interface Props {
  permissions: string[]
  handlePermissions: (permission: string[]) => void
}

export default function TablesRoles({ permissions, handlePermissions }: Props) {
  const [colCreateAll, setColCreateAll] = React.useState("yes")
  const [colViewAll, setColViewAll] = React.useState("yes")
  const [colDeleteAll, setColDeleteAll] = React.useState("yes")
  const [colUpdateAll, setColUpdateAll] = React.useState("yes")

  const isInPermission = (value: string) => permissions.includes(value)

  function removePermissions(actionType: string) {
    const newPrsmissions = permissions.filter((p) => !p.includes(actionType))
    handlePermissions(_.uniq(newPrsmissions))
  }

  const handleSelectCreateAll = (value: string) => {
    const newValue = value === "yes" ? "no" : "yes"
    setColCreateAll(newValue)
    if (newValue === "yes") {
      const newPersmissionValue = [...permissionsCreate, ...permissions]
      handlePermissions(_.uniq(newPersmissionValue))
    } else {
      removePermissions("create")
    }
  }

  const handleSelectUpdateAll = (value: string) => {
    const newValue = value === "yes" ? "no" : "yes"
    setColUpdateAll(newValue)
    if (newValue === "yes") {
      const newPersmissionValue = [...permissionsChange, ...permissions]
      handlePermissions(_.uniq(newPersmissionValue))
    } else {
      removePermissions("change")
    }
  }

  const handleSelectDeleteAll = (value: string) => {
    const newValue = value === "yes" ? "no" : "yes"
    setColDeleteAll(newValue)
    if (newValue === "yes") {
      const newPersmissionValue = [...permissionsDelete, ...permissions]
      handlePermissions(_.uniq(newPersmissionValue))
    } else {
      removePermissions("delete")
    }
  }

  const handleSelectViewAll = (value: string) => {
    const newValue = value === "yes" ? "no" : "yes"
    setColViewAll(newValue)
    if (newValue === "yes") {
      const newPersmissionValue = [...permissionsView, ...permissions]
      handlePermissions(_.uniq(newPersmissionValue))
    } else {
      removePermissions("view")
    }
  }

  const handleChangeSwitch = (value: string) => {
    if (permissions.indexOf(value) !== -1) {
      const newPermissions = permissions.filter((item) => item !== value)
      handlePermissions(newPermissions)
    } else {
      const userPermissions = [...permissions, value]
      handlePermissions(userPermissions)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Entité</TableCell>
            <TableCell sx={{ padding: 0 }}>
              <HeaderChecked
                handleUpdate={handleSelectCreateAll}
                value={colCreateAll}
                label="Créer"
              />
            </TableCell>
            <TableCell>
              <HeaderChecked
                handleUpdate={handleSelectUpdateAll}
                value={colUpdateAll}
                label="Modifier"
              />
            </TableCell>
            <TableCell>
              <HeaderChecked
                handleUpdate={handleSelectViewAll}
                value={colViewAll}
                label="Voir"
              />
            </TableCell>
            <TableCell>
              <HeaderChecked
                handleUpdate={handleSelectDeleteAll}
                value={colDeleteAll}
                label="Supprimer"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissionsList.map((el) => (
            <TableRow key={el.label}>
              <TableCell component="th" scope="row">
                {el.label}
              </TableCell>
              <TableCell>
                <ToggleSwitch
                  checked={isInPermission(el.create)}
                  handleChange={() => handleChangeSwitch(el.create)}
                />
              </TableCell>
              <TableCell>
                <ToggleSwitch
                  checked={isInPermission(el.change)}
                  handleChange={() => handleChangeSwitch(el.change)}
                />
              </TableCell>
              <TableCell>
                <ToggleSwitch
                  checked={isInPermission(el.view)}
                  handleChange={() => handleChangeSwitch(el.view)}
                />
              </TableCell>
              <TableCell>
                <ToggleSwitch
                  checked={isInPermission(el.delete)}
                  handleChange={() => handleChangeSwitch(el.delete)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
