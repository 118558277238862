import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  selectAlertStatus,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import Loader from "../Loader/Loader"

export default function CustomAlert() {
  const alert = useAppSelector(selectAlertStatus)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(setCloseAlert())
  }

  return (
    <Dialog
      open={Boolean(alert.open)}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        sx: {
          backgroundColor: "white",
          zIndex: 9999,
        },
      }}
    >
      <DialogTitle color={"black"} fontSize={19} variant="h6">
        Confirmation !!!
      </DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={16}>{alert.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{ background: "blue", color: "white" }}
        >
          NON
        </Button>
        <Button
          onClick={() => alert.handleValid()}
          autoFocus
          variant="contained"
          sx={{ background: "red" }}
        >
          {alert.isLoading ? <Loader size={30} /> : "OUI"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
