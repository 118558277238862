import { gql } from "@apollo/client"

export const COUNTRY_FRAGMENT = gql(`
  fragment CountryFragment on Country {
    id
    name_fr
    name_en
    alpha2
    alpha3
    currency
    isOpen
  }
`)
