import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface transporterZoneState {
  pagination: Pagination
  query: string
}

const initialState: transporterZoneState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const transporterZoneSlice = createSlice({
  name: "transporterZone",
  initialState,
  reducers: {
    settransporterZonePagination: (
      state,
      action: PayloadAction<Pagination>,
    ) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    settransporterZoneQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})
export const selecttransporterZonePagination = (state: RootState) =>
  state.transporterZone.pagination
export const selecttransporterZoneQuery = (state: RootState) =>
  state.transporterZone.query
export const { settransporterZonePagination, settransporterZoneQuery } =
  transporterZoneSlice.actions
export default transporterZoneSlice.reducer
