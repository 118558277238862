import { gql } from "@apollo/client"

export const FAQS_FRAGMENT = gql(`
  fragment FaqFragment on Faq {
    id
    createdAt
    description_en
    title_en
    title_fr
    updatedAt
    description_fr
  }
`)
