import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Pagination } from "../../../types/Pagination"
import { RootState } from "../../app/store"

export interface CategoryState {
  pagination: Pagination
  query: string
}

const initialState: CategoryState = {
  pagination: {
    page: 0,
    size: 10,
  },
  query: "",
}

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoryPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setCategoryQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})
export const selectCategoryPagination = (state: RootState) =>
  state.category.pagination
export const selectCategoryQuery = (state: RootState) => state.category.query
export const { setCategoryPagination, setCategoryQuery } = categorySlice.actions
export default categorySlice.reducer
