import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { useNavigate } from "react-router-dom"
import { List, styled, useMediaQuery, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../../store/app/hooks"
import {
  selectMenuStatus,
  setTitle,
  toogleOpen,
} from "../../../../../store/features/menu/menuSlice"
import { havePermissions } from "../../../../../utils/permissions"

export interface Menu {
  name: string
  icon: React.ReactNode
  route: string
  subMenu?: Menu[]
  title: string
  isMain?: boolean
  permissions: string[]
  isBoard?: boolean
}

interface Props {
  menu: Menu
  pl?: number
}

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 10,
}))

const ListItem = ({ menu, pl = 0 }: Props) => {
  const [open, setOpen] = React.useState<boolean>(true)
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const drawerStatus = useAppSelector(selectMenuStatus)
  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (isMobile && !menu.isMain) {
      dispatch(toogleOpen())
    }
    dispatch(setTitle(menu.title))
    if (menu.isMain) {
      setOpen(!open)
    } else {
      navigate(menu.route)
    }
  }
  const haveSubMenu = Boolean(menu?.subMenu?.length)
  const paddingLeft = drawerStatus ? pl * 4 : 0
  const sx = paddingLeft ? { pl: paddingLeft, mx: 1 } : { mx: 1 }

  if (!havePermissions(menu.permissions)) {
    return null
  }

  return (
    <React.Fragment>
      <ListItemButtonStyled sx={sx} onClick={() => handleClick()}>
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText primary={menu.name} />
        {haveSubMenu ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButtonStyled>
      {haveSubMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menu?.subMenu?.map((el) => (
              <ListItem pl={pl + 1} menu={el} key={el.name} />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )
}

export default ListItem
