import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheCountry } from "../../../caches/updateCacheCountry"
import FormCountry from "../FormCountry/FormCountry"

// Signin mutation
const addCountryMutation = graphql(`
  mutation addCountry($data: CountryCreateInput!) {
    createOneCountry(data: $data) {
      id
      name_fr
      name_en
      alpha2
      alpha3
      currency
      isOpen
      ceedowCommission
    }
  }
`)

export default function AddCountry() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [country, setCountry] = useState({
    name_fr: "",
    name_en: "",
    currency: "",
    alpha2: "",
    alpha3: "",
    isOpen: "no",
    minPrice: "",
    ceedowCommission: "",
  })

  const [createCountry, { loading }] = useMutation(addCountryMutation)

  const handleInputChange = (event: any) => {
    setCountry({
      ...country,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateCountry = (e: any) => {
    e.preventDefault()
    createCountry({
      variables: {
        data: {
          ...country,
          minPrice: parseInt(country.minPrice),
          isOpen: country.isOpen === "yes",
          ceedowCommission: parseInt(country.ceedowCommission),
        },
      },
      onCompleted: () => {
        navigate("/country/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCountry({
          action: "add",
          cache,
          entryData: data?.createOneCountry,
        })
      },
    })
  }
  return (
    <FormCountry
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateCountry}
      loading={loading}
      name_en={country.name_en}
      name_fr={country.name_fr}
      alpha3={country.alpha3}
      alpha2={country.alpha2}
      currency={country.currency}
      minPrice={country.minPrice}
      ceedowCommission={country.ceedowCommission}
      isOpen={country.isOpen as "yes" | "no"}
      title="Ajouter une pays"
    />
  )
}
