import { Avatar, Box, Stack, TextField } from "@mui/material"
import SelectEmoji from "../SelectEmoji/SelectEmoji"
import SelectFiles from "../SelectFiles/SelectFiles"
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined"
import { green } from "@mui/material/colors"
import SendIcon from "@mui/icons-material/Send"
import { useMutation } from "@apollo/client"
import { useRef, useState } from "react"
import { CREATE_MESSAGE } from "../Chat"
import CarouselFiles, { FilesCarousel } from "../CarouselFiles/CarouselFiles"
import { uploadFiles } from "../../../../../utils/uploadFiles"
import { useAppDispatch } from "../../../../../store/app/hooks"
import { setOpenSnackbar } from "../../../../../store/features/snackbar/snackbarSlice"
import Loader from "../../../../Common/Loader/Loader"
import { NewFileInput, Status } from "../../../../../gql/graphql"
import FormAdvicePrice from "./FormAdvicePrice/FormAdvicePrice"
import { isContainsEmailOrNumber } from "../../../../../types/validator"
import { graphql } from "../../../../../gql"
import {
  setCloseAlert,
  setLoadingDelete,
  setOpenAlert,
} from "../../../../../store/features/alert/alertSlice"
import { getErrorsAsString } from "../../../../../utils/getErrorsAsString"
import FormAddDeliveryDate from "./FormAddDeliveryDate/FormAddDeliveryDate"

const ARCHIVE_ORDER = graphql(`
  mutation toogleArchiveOrder($toogleArchiveOrderId: Float!) {
    toogleArchiveOrder(id: $toogleArchiveOrderId) {
      id
      createdAt
      status
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
    }
  }
`)

const PAID_ORDER = graphql(`
  mutation ValidOrderPaiment($orderId: Float!, $chatId: Float!) {
    validOrderPaiment(orderId: $orderId, chatId: $chatId) {
      id
      createdAt
      status
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
    }
  }
`)

const DELIVRY_ORDER = graphql(`
  mutation ValidOrderDelivry($orderId: Float!) {
    validOrderDelivry(orderId: $orderId) {
      id
      createdAt
      status
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
    }
  }
`)

interface Props {
  handleRecord: () => void
  receiverId: number
  chatId: number
  orderId: number
  craftmanId: number
  customerId: number
  haveAmountNotPaid: boolean
  haveAmountPaid: boolean
  notPaid: boolean
  status: Status
}
const FormMedia = ({
  handleRecord,
  receiverId,
  chatId,
  orderId,
  haveAmountNotPaid,
  haveAmountPaid,
  notPaid,
  status,
  craftmanId,
  customerId,
}: Props) => {
  const [newMessage, setNewMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [createMessage] = useMutation(CREATE_MESSAGE)
  const inputImages = useRef<HTMLInputElement>(null)
  const inputVideos = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<FilesCarousel[]>([])
  const [open, setOpen] = useState(false)
  const [openPutDateDelivery, setOpenPutDateDelivery] = useState(false)

  const [archiveOrder] = useMutation(ARCHIVE_ORDER)
  const [paidOrder] = useMutation(PAID_ORDER)
  const [delivryOrder] = useMutation(DELIVRY_ORDER)

  const onDelivryDate = () => {
    setOpenPutDateDelivery(true)
  }

  const handleClosePutDateDelivery = () => {
    setOpenPutDateDelivery(false)
  }

  const onAdvicePrice = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dispatch = useAppDispatch()

  const handleFilesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "image" | "video",
  ) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      if (type === "video" && files.length > 2) {
        alert("Veuillez choisir au plus deux videos")
        return
      }
      const haveMaxSize = files.filter((file) => file.size > 50 * 1024 * 1024)
      if (haveMaxSize.length) {
        alert("La taille d'un fichier maximale est 50 MB")
        return
      }
      for (let file of files) {
        const src = URL.createObjectURL(file)
        setFiles((prev) => [
          ...prev,
          {
            src,
            type,
            file,
          },
        ])
      }
      var container = document.getElementById(
        "container-chat-message",
      ) as HTMLElement
      container.scroll({
        top: container.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  const onCreateMessage = (photos: NewFileInput[]) => {
    createMessage({
      variables: {
        chatId,
        receiverId,
        photos,
        content: newMessage,
      },
      onCompleted() {
        setNewMessage("")
        setFiles([])
        setLoading(false)
      },
      onError(error) {
        dispatch(
          setOpenSnackbar({
            message: getErrorsAsString(error),
            open: true,
            status: "error",
          }),
        )
        setLoading(false)
      },
    })
  }

  const handleCreateMessage = () => {
    if (!loading) {
      if (newMessage || files.length) {
        if (isContainsEmailOrNumber(newMessage)) {
          dispatch(
            setOpenSnackbar({
              message:
                "Le message ne devrait pas contenir d'adresse e-mail ou de numéro. Veuillez les supprimer avant d'envoyer. Pour soumettre un prix, veuillez cliquer sur le bouton 'Proposer un prix'.",
              open: true,
              status: "error",
            }),
          )
          return
        }
        setLoading(true)
        if (files.length) {
          uploadFiles({
            files: files.map((f) => f.file),
            cbErr(err) {
              dispatch(
                setOpenSnackbar({
                  message:
                    "Veuillez choisir des fichiers plus léger, s'il vous plait",
                  open: true,
                  status: "error",
                }),
              )
              setLoading(false)
            },
          }).then((files) => {
            if (files) {
              onCreateMessage(files)
            }
          })
        } else {
          onCreateMessage([])
        }
      }
    }
  }

  const onSelectVideos = () => {
    inputVideos.current?.click()
  }

  const onSelectImages = () => {
    inputImages.current?.click()
  }

  const onClearFile = (file: FilesCarousel) => {
    setFiles((prev) => prev.filter((f) => f.src !== file.src))
  }

  const onArchiveOrder = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => {
          dispatch(setLoadingDelete(true))
          archiveOrder({
            variables: {
              toogleArchiveOrderId: orderId,
            },
            onCompleted: () => {
              dispatch(setLoadingDelete(false))
              dispatch(setCloseAlert())
              dispatch(
                setOpenSnackbar({
                  message: "La commande a été archivée avec succès",
                  status: "success",
                }),
              )
            },
            onError: (err) => {
              const message = getErrorsAsString(err)
              dispatch(setOpenSnackbar({ message }))
            },
          })
        },
        message:
          "Êtes-vous vraiment sûr de vouloir mettre en archive cette commander ?",
        isLoading: false,
      }),
    )
  }
  const onPaidOrder = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => {
          dispatch(setLoadingDelete(true))
          paidOrder({
            variables: {
              orderId,
              chatId,
            },
            onCompleted: () => {
              dispatch(setLoadingDelete(false))
              dispatch(setCloseAlert())
              dispatch(
                setOpenSnackbar({
                  message: "La commande a été payée avec succès",
                  status: "success",
                }),
              )
            },
            onError: (err) => {
              const message = getErrorsAsString(err)
              dispatch(setOpenSnackbar({ message }))
            },
          })
        },
        message:
          "Êtes-vous vraiment sûr de vouloir mettre comme payer cette commande ?",
        isLoading: false,
      }),
    )
  }

  const onDelivryOrder = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => {
          dispatch(setLoadingDelete(true))
          delivryOrder({
            variables: {
              orderId,
            },
            onCompleted: () => {
              dispatch(setLoadingDelete(false))
              dispatch(setCloseAlert())
              dispatch(
                setOpenSnackbar({
                  message: "La commande a été marqué comme livré avec succès",
                  status: "success",
                }),
              )
            },
            onError: (err) => {
              const message = getErrorsAsString(err)
              dispatch(setOpenSnackbar({ message }))
            },
          })
        },
        message:
          "Êtes-vous vraiment sûr de vouloir mettre comme livrer cette commande ?",
        isLoading: false,
      }),
    )
  }

  const isSend = Boolean(files.length || newMessage.length)

  return (
    <>
      {files && files.length ? (
        <Box sx={{ p: 1, background: "#5D490E", mb: 0.5, borderRadius: "5px" }}>
          <CarouselFiles files={files} onClearFile={onClearFile} />
        </Box>
      ) : null}
      <input
        type="file"
        multiple
        accept="image/*"
        style={{ display: "none" }}
        ref={inputImages}
        onChange={(e) => handleFilesChange(e, "image")}
      />
      <input
        type="file"
        multiple
        accept="video/*"
        style={{ display: "none" }}
        ref={inputVideos}
        onChange={(e) => handleFilesChange(e, "video")}
      />
      <Stack direction="row" spacing={1} mt={2}>
        <Box>
          <SelectEmoji
            handleSelectEmoji={(emoji) => {
              setNewMessage((prev) => `${prev}${emoji.emoji}`)
            }}
          />
        </Box>
        <Box>
          <SelectFiles
            onSelectImages={onSelectImages}
            onSelectVideos={onSelectVideos}
            onAdvicePrice={onAdvicePrice}
            onArchiveOrder={onArchiveOrder}
            onPaidOrder={onPaidOrder}
            haveAmountNotPaid={haveAmountNotPaid}
            haveAmountPaid={haveAmountPaid}
            notPaid={notPaid}
            onDelivryOrder={onDelivryOrder}
            status={status}
            onDelivryDate={onDelivryDate}
          />
        </Box>
        <Box flex={1}>
          <TextField
            placeholder="Ecrire votre message..."
            variant="outlined"
            fullWidth
            size="small"
            sx={{ borderColor: "#5D490E" }}
            value={newMessage}
            multiline
            minRows={1}
            autoFocus
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </Box>
        <Box>
          {isSend ? (
            <Avatar
              onClick={handleCreateMessage}
              sx={{
                bgcolor: green[500],
                width: 32,
                height: 32,
                cursor: "pointer",
                mt: 0.3,
              }}
            >
              {loading ? <Loader size={20} /> : <SendIcon color="action" />}
            </Avatar>
          ) : (
            <KeyboardVoiceOutlinedIcon
              sx={{ cursor: "pointer" }}
              fontSize="large"
              onClick={handleRecord}
            />
          )}
        </Box>
      </Stack>
      <FormAdvicePrice
        open={open}
        handleClose={handleClose}
        chatId={chatId}
        orderId={orderId}
        receiverId={receiverId}
      />
      <FormAddDeliveryDate
        chatId={chatId}
        craftmanId={craftmanId}
        customerId={customerId}
        handleClose={handleClosePutDateDelivery}
        open={openPutDateDelivery}
        orderId={orderId}
      />
    </>
  )
}

export default FormMedia
