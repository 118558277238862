import React, { useEffect, useMemo, useState } from "react"
import {
  MRT_Cell,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table"
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ThemeProvider,
  Tooltip,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { useNavigate } from "react-router-dom"
import { MRT_Localization_FR } from "material-react-table/locales/fr"
import { Pagination } from "../../../types/Pagination"
import MobileTableView from "./MobileTableView"
import ArchiveIcon from "@mui/icons-material/Archive"

interface CustomTableProps {
  columns: any
  data: any[]
  rootLisName?: string
  isLoading?: boolean
  isSaving?: boolean
  showAlertBanner?: boolean
  showProgressBars?: boolean
  lableAddNew?: string
  rowCount: number
  pageIndex: number
  pageSize: number
  isHaveView?: boolean
  isHaveAction?: boolean
  isHaveHeaderAction?: boolean
  enableColumnFilters?: boolean
  searchValue?: string
  headerMobileFilter?: React.ReactNode
  onChangeSearchValue?: (value: string) => void
  handleDelete?: (id: number) => void
  handleView?: (id: number) => void
  handleEdit?: (id: number) => void
  handleAdd?: () => void
  handleArchiveGroup?: () => void
  handleChangePagination: (pagination: Pagination) => void
  rowsPerPageOptions?: number[]
  isCustomAction?: boolean
  selectedIds?: number[]
  handleSelected?: (id: number) => void
  handleRefresh?: () => void
  renderAction?:
    | ((props: {
        cell: MRT_Cell<any, unknown>
        row: MRT_Row<any>
        table: MRT_TableInstance<any>
      }) => React.ReactNode)
    | undefined

  columnFilters?: MRT_ColumnFiltersState
  setColumnFilters?: React.Dispatch<
    React.SetStateAction<MRT_ColumnFiltersState>
  >
}

const CustomTable: React.FC<CustomTableProps> = ({
  rowCount,
  data,
  columns,
  isLoading,
  showAlertBanner,
  showProgressBars,
  isSaving,
  rootLisName,
  lableAddNew,
  pageIndex = 0,
  pageSize = 10,
  isHaveView = false,
  isHaveAction = true,
  isHaveHeaderAction = true,
  searchValue,
  enableColumnFilters = false,
  rowsPerPageOptions = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  isCustomAction,
  columnFilters = [],
  headerMobileFilter,
  renderAction,
  setColumnFilters = undefined,
  onChangeSearchValue,
  handleDelete,
  handleChangePagination,
  handleView,
  handleEdit,
  handleRefresh,
  handleAdd,
  handleSelected,
  selectedIds,
  handleArchiveGroup,
}) => {
  const navigate = useNavigate()
  const globalTheme = useTheme()
  const isMobile = useMediaQuery(globalTheme.breakpoints.down("md"))

  const [pagination, setPagination] = useState({
    pageIndex,
    pageSize,
  })

  useEffect(() => {
    handleChangePagination({
      size: pagination.pageSize,
      page: pagination.pageIndex,
    })
  }, [pagination])

  const tableTheme = useMemo(
    () =>
      createTheme({
        ...globalTheme,
        palette: {
          ...globalTheme.palette,
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary,
          info: {
            main: globalTheme.palette.background.default,
          },
          background: {
            default: globalTheme.palette.background.default,
            paper: globalTheme.palette.background.default,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                color: "white",
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              colorInfo: {
                color: "green",
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "white",
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              paper: {
                backgroundColor: globalTheme.palette.background.default,
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: globalTheme.palette.background.default,
                border: "1px solid white",
                padding: "4px",
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: "white",
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                color: "white !important",
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                backgroundColor: globalTheme.palette.primary.main,
              },
            },
          },
        },
      }),
    [globalTheme],
  )
  const table = useMaterialReactTable({
    columns,
    data,
    manualPagination: true,
    manualFiltering: true,
    pageCount: 10,
    rowCount,
    createDisplayMode: "custom",
    editDisplayMode: "row",
    enableMultiRemove: isHaveAction,
    onColumnFiltersChange: setColumnFilters,
    enableFullScreenToggle: false,
    enableEditing: isHaveAction || isCustomAction,
    getRowId: (row) => row.id,
    enableStickyHeader: true,
    enableGlobalFilter: true,
    enableColumnFilters,
    enableSorting: false,
    localization: MRT_Localization_FR,
    muiSearchTextFieldProps: {
      InputProps: {
        value: searchValue,
        onChange: (e) =>
          onChangeSearchValue && onChangeSearchValue(e.target.value),
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions,
      color: "primary",
      shape: "rounded",
      showRowsPerPage: true,
      showFirstButton: true,
      showLastButton: true,
      variant: "outlined",
    },
    paginationDisplayMode: "pages",
    positionPagination: "both",
    muiTableHeadCellProps: {
      sx: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    muiTableContainerProps: {
      sx: {
        minHeight: "auto",
      },
    },
    onPaginationChange: (value) => setPagination && setPagination(value),
    renderRowActions: isCustomAction
      ? renderAction
      : !isHaveAction
      ? undefined
      : ({ row, table }) => (
          <Box sx={{ display: "flex" }}>
            {handleSelected && (
              <Tooltip title="Sélectionner">
                <Checkbox
                  checked={selectedIds?.includes(row.original.id)}
                  onChange={() => handleSelected(row.original.id)}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                  sx={{ color: "white" }}
                />
              </Tooltip>
            )}
            {handleEdit && (
              <Tooltip title="Modifier">
                <IconButton
                  onClick={() => handleEdit && handleEdit(row.original.id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {handleDelete && (
              <Tooltip title="Supprimer">
                <IconButton
                  color="error"
                  onClick={() => handleDelete && handleDelete(row.original.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {isHaveView && (
              <Tooltip title="Voir">
                <IconButton
                  color="info"
                  onClick={() => handleView && handleView(row.original.id)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
    renderTopToolbarCustomActions: !isHaveHeaderAction
      ? undefined
      : ({ table }) =>
          (lableAddNew || handleRefresh) && (
            <Box alignItems={"center"}>
              {lableAddNew && (
                <Button
                  variant="contained"
                  sx={{ background: "white", color: "#000" }}
                  onClick={() => {
                    if (handleAdd) {
                      handleAdd()
                    } else {
                      navigate(rootLisName as string)
                    }
                  }}
                >
                  {lableAddNew}
                </Button>
              )}
              {handleRefresh && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ color: "#fff", ml: 1, mt: 0.2 }}
                  onClick={handleRefresh}
                >
                  Actualiser
                </Button>
              )}
              {Boolean(selectedIds?.length && handleArchiveGroup) && (
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: "#fff", ml: 1, mt: 0.2 }}
                  onClick={handleArchiveGroup}
                >
                  <ArchiveIcon />
                </Button>
              )}
            </Box>
          ),
    state: {
      isLoading,
      isSaving,
      pagination,
      showAlertBanner: showAlertBanner,
      showProgressBars: showProgressBars,
      showGlobalFilter: true,
      showColumnFilters: enableColumnFilters,
      columnFilters,
    },
  })

  if (isMobile) {
    return (
      <MobileTableView
        data={data}
        columns={columns}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleView={handleView}
        isHaveView={isHaveView}
        isHaveAction={isHaveAction}
        isCustomAction={isCustomAction}
        renderAction={renderAction}
        rowCount={rowCount}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        handleChangePagination={handleChangePagination}
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        handleAdd={handleAdd}
        handleRefresh={handleRefresh}
        isHaveHeaderAction={isHaveHeaderAction}
        lableAddNew={lableAddNew}
        headerFilter={headerMobileFilter}
        rootLisName={rootLisName}
        handleSelected={handleSelected}
        selectedIds={selectedIds}
        handleArchiveGroup={handleArchiveGroup}
      />
    )
  }

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  )
}

export default CustomTable
