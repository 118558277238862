import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selecttransporterZonePagination,
  selecttransporterZoneQuery,
  settransporterZonePagination,
  settransporterZoneQuery,
} from "../../../store/features/transporterZone/transporterZoneSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  TransporterZone,
  SortOrder,
  TransporterZoneWhereInput,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheTransporterZone } from "../../../caches/updateCacheTransporterZone"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import ModalCountries from "../../Common/ModalCountries/ModalCountries"
import { Box } from "@mui/material"
import { getUserCountryId } from "../../../utils/authToken"

export const list_transporterZones = graphql(`
  query transporterZones_total(
    $where: TransporterZoneWhereInput
    $orderBy: [TransporterZoneOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    aggregateTransporterZone {
      _count {
        _all
      }
    }
    transporterZones(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
      Countries {
        id
        name_fr
      }
    }
  }
`)

export const delete_transporterZone = graphql(`
  mutation DeleteOneTransporterZone($where: TransporterZoneWhereUniqueInput!) {
    deleteOneTransporterZone(where: $where) {
      id
      name
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListTransporterZone($where: CountryWhereInput) {
    countries(where: $where) {
      name_fr
      id
    }
  }
`)
const ListtransporterZone = () => {
  const { page, size } = useAppSelector(selecttransporterZonePagination)
  const query = useAppSelector(selecttransporterZoneQuery)
  const dispatch = useAppDispatch()
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const countryId = getUserCountryId()
  const whereCluase = whereInputClause({
    variables: {
      where: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const { loading: loadingFilter, data: dataFilter } = useQuery(
    LIST_FILTER,
    whereCluase,
  )
  const navigate = useNavigate()

  const getValueFilter = (el: {
    name_fr?: string
    id: number
    name?: string
  }) => {
    return {
      label: el.name_fr ? el.name_fr : el.name,
      value: el.id.toString(),
      text: el.name_fr ? el.name_fr : el.name,
    }
  }

  const getFilters: () => TransporterZoneWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: TransporterZoneWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Countries":
              accumulator = {
                ...accumulator,
                Countries: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "name":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name: {
                      search: value.trim(),
                    },
                  },
                  {
                    name: {
                      contains: value.trim(),
                    },
                  },
                ],
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name: { search: query } },
            {
              id: {
                equals: Number(query),
              },
            },
          ],
        }
      : {}
  }, [query])

  const [getZones, { loading, data }] = useLazyQuery(list_transporterZones)

  const [deletetransporterZone] = useMutation(delete_transporterZone)

  useEffect(() => {
    getZones({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { name: SortOrder.Asc },
        where: {
          ...queryName(),
          ...getFilters(),
        },
      },
    })
  }, [getZones, page, query, queryName, size, getFilters])

  const columns = useMemo<MRT_ColumnDef<TransporterZone>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "name",
        header: "Nom de zone",
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name",
      },
      {
        accessorFn: (row) => {
          if (!row?.Countries?.length) return null
          return (
            <>
              <Box>
                {row?.Countries.map((el) => el.name_fr)
                  .join(", ")
                  .slice(0, 100)}
                ...
              </Box>
              <Box>
                <ModalCountries
                  countries={row?.Countries.map((el) => el.name_fr)}
                />
              </Box>
            </>
          )
        },
        header: "Pays",
        filterVariant: "select",
        name: "Countries",
        filterSelectOptions: dataFilter?.countries?.map((el) =>
          getValueFilter(el),
        ),
      },
    ],
    [dataFilter],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(settransporterZonePagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deletetransporterZone({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La zone a été supprimée avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporterZone({
          action: "delete",
          cache,
          entryData: data?.deleteOneTransporterZone,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer cette zone ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(settransporterZoneQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier une zone de transport"))
    navigate(`/transporter-zone/edit/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.transporterZones || []}
      lableAddNew="Créer une nouvelle transporterZone"
      rootLisName={"/transporter-zone/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateTransporterZone._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["transporterZone.delete"])
          ? handleOpenDeleteDialog
          : undefined
      }
      isHaveHeaderAction={havePermissions(["transporterZone.create"])}
      handleEdit={
        havePermissions(["transporterZone.change"]) ? handleEdit : undefined
      }
      isHaveView={false}
      isHaveAction={havePermissions([
        "transporterZone.view",
        "transporterZone.change",
        "transporterZone.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListtransporterZone
