import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import { Avatar, Box, Dialog } from "@mui/material"
import { red } from "@mui/material/colors"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import ReactPlayer from "react-player/lazy"
import useImage from "../../../../../hooks/useImage"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
}

export interface FilesCarousel {
  src: string
  type: string
  file: File
}

interface Props {
  files: FilesCarousel[]
  onClearFile: (file: FilesCarousel) => void
}

interface PropsAvatar {
  file: FilesCarousel
  onClearFile: (file: FilesCarousel) => void
  onClick: (file: FilesCarousel) => void
}

const AvartarFile = ({ file, onClearFile, onClick }: PropsAvatar) => {
  const style = { height: "100px", width: "100px", borderRadius: "5px" }
  const { getUri } = useImage()
  return (
    <Box
      sx={{ mx: 1, cursor: "pointer" }}
      onClick={(e) => {
        e.stopPropagation()
        onClick(file)
      }}
    >
      <Avatar
        onClick={(e) => {
          e.stopPropagation()
          onClearFile(file)
        }}
        sx={{
          bgcolor: red[500],
          width: 20,
          height: 20,
          position: "absolute",
          top: 0,
          right: 0,
          cursor: "pointer",
          zIndex: 9999,
          p: 1.5,
        }}
      >
        <DeleteForeverOutlinedIcon color="action" />
      </Avatar>
      {file.type === "image" ? (
        <img src={getUri(file.src)} alt="preview" style={style} />
      ) : (
        <video src={getUri(file.src)} style={style} />
      )}
    </Box>
  )
}

const CarouselFiles = ({ files, onClearFile }: Props) => {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState<FilesCarousel | null>(null)

  const handleClose = () => {
    setOpen(false)
    setFile(null)
  }

  const handleOpen = (file: FilesCarousel) => {
    setFile(file)
    setOpen(true)
  }

  return (
    <>
      <Carousel responsive={responsive}>
        {files.map((file, index) => {
          return (
            <AvartarFile
              key={index.toString()}
              file={file}
              onClearFile={onClearFile}
              onClick={handleOpen}
            />
          )
        })}
      </Carousel>
      {open && file?.type === "image" && (
        <Lightbox
          small={file.src}
          medium={file.src}
          large={file.src}
          alt=""
          //@ts-ignore
          onClose={handleClose}
        />
      )}
      <Dialog
        maxWidth="md"
        onClose={handleClose}
        open={open && file?.type === "video"}
        scroll="body"
      >
        <ReactPlayer
          url={file?.src}
          controls
          light
          width={"60vw"}
          height={"60vh"}
          style={{
            overflow: "hidden",
            backgroundColor: "grey",
          }}
        />
      </Dialog>
    </>
  )
}

export default CarouselFiles
