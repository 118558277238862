import { useCallback, useEffect, useRef } from "react"
import { useWavesurfer } from "@wavesurfer/react"
import { Grid, Typography } from "@mui/material"
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined"
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined"

interface Props {
  url: string
  styleContainer?: React.CSSProperties
  isPause?: boolean
}

const PreviewAudio = ({ url, styleContainer, isPause }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const {
    wavesurfer,
    isPlaying,
    currentTime: audioTime,
  } = useWavesurfer({
    container: containerRef,
    url,
    waveColor: "#5D490E",
    height: 25,
    autoCenter: true,
  })

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause()
  }

  const style = { color: "#5D490E", cursor: "pointer" }

  const currentTime = useCallback(() => {
    if (!audioTime) return "00:00"
    const minutes = Math.floor(audioTime / 60)
    const seconds = Math.floor(audioTime % 60)
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`
  }, [audioTime])

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("finish", () => {
        wavesurfer.seekTo(0)
      })
    }
  }, [wavesurfer])

  useEffect(() => {
    if (isPause && wavesurfer && wavesurfer.isPlaying()) {
      onPlayPause()
    }
  }, [isPause])

  return (
    <Grid
      container
      alignItems={"center"}
      display={"flex"}
      justifyContent={"center"}
      spacing={1}
      bgcolor={"white"}
      borderRadius={7}
      p={0}
      style={styleContainer}
      px={1}
    >
      <Grid item lg={2}>
        {isPlaying ? (
          <PauseCircleOutlinedIcon
            onClick={onPlayPause}
            fontSize="large"
            sx={style}
          />
        ) : (
          <PlayCircleOutlineOutlinedIcon
            onClick={onPlayPause}
            sx={style}
            fontSize="large"
          />
        )}
      </Grid>
      <Grid item lg={8} flex={1}>
        <div ref={containerRef} />
      </Grid>
      <Grid item lg={2}>
        <Typography>{currentTime()}</Typography>
      </Grid>
    </Grid>
  )
}

export default PreviewAudio
