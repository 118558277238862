import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { graphql } from "../../../../../../gql"
import { useMutation } from "@apollo/client"
import { getErrorsAsString } from "../../../../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../../../../store/features/snackbar/snackbarSlice"
import { useAppDispatch } from "../../../../../../store/app/hooks"
import Loader from "../../../../../Common/Loader/Loader"

interface Props {
  open: boolean
  handleClose: () => void
  chatId: number
  orderId: number
  craftmanId: number
  customerId: number
}

const PUT_DELIVERY_DATE = graphql(`
  mutation AskDeliveryMan(
    $photos: [NewFileInput!]!
    $address: String!
    $orderId: Float!
    $delivryDate: DateTimeISO!
    $customerId: Float!
    $chatId: Float!
    $craftmanId: Float!
  ) {
    askDeliveryMan(
      photos: $photos
      address: $address
      orderId: $orderId
      delivryDate: $delivryDate
      customerId: $customerId
      chatId: $chatId
      craftmanId: $craftmanId
    ) {
      id
      chatId
      Chat {
        id
        Order {
          delivryDate
          id
        }
      }
    }
  }
`)

export default function FormAddDeliveryDate({
  open,
  handleClose,
  chatId,
  orderId,
  craftmanId,
  customerId,
}: Props) {
  const [putDeliveryAdress, { loading }] = useMutation(PUT_DELIVERY_DATE)
  const dispatch = useAppDispatch()
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const address = formJson.address
            const delivryDate = new Date(formJson.delivryDate)
            putDeliveryAdress({
              variables: {
                chatId,
                address,
                craftmanId,
                customerId,
                delivryDate,
                photos: [],
                orderId,
              },
              onCompleted() {
                handleClose()
              },
              onError(err) {
                const message = getErrorsAsString(err)
                dispatch(setOpenSnackbar({ message }))
              },
            })
          },
        }}
      >
        <DialogTitle variant="h4">Ajouter la date de livraison</DialogTitle>
        <DialogContent>
          <DialogContentText color={"white"}>
            Veuillez entrer la date et l'adresse de livraison dans les champs
            ci-dessous, s'il y en a, puis cliquez sur le bouton "Valider" pour
            confirmer.
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="delivryDate"
            name="delivryDate"
            margin="dense"
            label=""
            placeholder="Tapez ici votre date de livraison..."
            type="date"
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            id="address"
            name="address"
            margin="dense"
            label=""
            placeholder="Tapez ici votre adresse de livraison..."
            type="string"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "red",
            }}
            onClick={handleClose}
            color="error"
            variant="contained"
          >
            Fermer
          </Button>
          <Button
            sx={{
              backgroundColor: "green",
            }}
            type="submit"
            color="success"
            variant="contained"
            disabled={loading}
          >
            {loading ? <Loader size={25} /> : "Valider"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
