const permissionsList = [
  {
    label: "Clients",
    delete: "customer.delete",
    view: "customer.view",
    create: "customer.create",
    change: "customer.change",
  },
  {
    label: "Commandes",
    delete: "order.delete",
    view: "order.view",
    create: "order.create",
    change: "order.change",
  },
  {
    label: "Appels d'offres",
    delete: "tender.delete",
    view: "tender.view",
    create: "tender.create",
    change: "tender.change",
  },
  {
    label: "Artisans",
    delete: "craftman.delete",
    view: "craftman.view",
    create: "craftman.create",
    change: "craftman.change",
  },
  {
    label: "Pays",
    delete: "country.delete",
    view: "country.view",
    create: "country.create",
    change: "country.change",
  },
  {
    label: "Segmentations",
    delete: "segmentation.delete",
    view: "segmentation.view",
    create: "segmentation.create",
    change: "segmentation.change",
  },
  {
    label: "Catégories",
    delete: "category.delete",
    view: "category.view",
    create: "category.create",
    change: "category.change",
  },
  {
    label: "Types articles",
    delete: "itemType.delete",
    view: "itemType.view",
    create: "itemType.create",
    change: "itemType.change",
  },
  {
    label: "Types matières",
    delete: "materialType.delete",
    view: "materialType.view",
    create: "materialType.create",
    change: "materialType.change",
  },
  {
    label: "Emails",
    delete: "email.delete",
    view: "email.view",
    create: "email.create",
    change: "email.change",
  },
  {
    label: "Admins",
    delete: "admin.delete",
    view: "admin.view",
    create: "admin.create",
    change: "admin.change",
  },
  {
    label: "Faqs",
    delete: "faqs.delete",
    view: "faqs.view",
    create: "faqs.create",
    change: "faqs.change",
  },
  {
    label: "Trasporteurs",
    delete: "transporter.delete",
    view: "transporter.view",
    create: "transporter.create",
    change: "transporter.change",
  },
  {
    label: "Transporteur Tarif",
    delete: "transporterPrice.delete",
    view: "transporterPrice.view",
    create: "transporterPrice.create",
    change: "transporterPrice.change",
  },
  {
    label: "Transporteur Zone",
    delete: "transporterZone.delete",
    view: "transporterZone.view",
    create: "transporterZone.create",
    change: "transporterZone.change",
  },
  {
    label: "Livreurs",
    delete: "deliveryMan.delete",
    view: "deliveryMan.view",
    create: "deliveryMan.create",
    change: "deliveryMan.change",
  },
  {
    label: "Demande de paiment",
    delete: "askPaiment.delete",
    view: "askPaiment.view",
    create: "askPaiment.create",
    change: "askPaiment.change",
  },
]

export const defaultPermissions = permissionsList.flatMap((permission) => [
  permission.delete,
  permission.view,
  permission.create,
  permission.change,
])

export default permissionsList

function getPermissions(actionType: string) {
  return defaultPermissions.filter((p) => p.includes(actionType))
}

export const permissionsCreate = getPermissions("create")
export const permissionsChange = getPermissions("change")
export const permissionsDelete = getPermissions("delete")
export const permissionsView = getPermissions("view")
