/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Typography, colors } from "@mui/material"
import { graphql } from "../../../gql"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { Role, SortOrder, User } from "../../../gql/graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import Loader from "../../Common/Loader/Loader"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { useAppDispatch } from "../../../store/app/hooks"
import NoDataStatistic from "../../Common/NoDataStatistic/NoDataStatistic"
import Chart from "../../Common/Chart/Chart"
import CustomTable from "../../Common/CustomTable/CustomTable"
import { MRT_ColumnDef } from "material-react-table"
import { havePermissions } from "../../../utils/permissions"

const GET_COUNTRY = graphql(`
  query Country(
    $where: CountryWhereUniqueInput!
    $usersWhere2: UserWhereInput
  ) {
    country(where: $where) {
      id
      name_fr
      name_en
      alpha2
      alpha3
      ceedowCommission
      currency
      _count {
        Orders
        Users(where: $usersWhere2)
      }
    }
  }
`)
const GET_CRAFTMEN = graphql(`
  query getCraftmens(
    $where: UserWhereInput
    $take: Int
    $skip: Int
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $aggregateUserWhere2: UserWhereInput
  ) {
    users(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      email
      lastName
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        id
      }
    }
  }
`)

const DetailCountry = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const id = params.id ? parseInt(params.id, 10) : 0
  const size = 5

  const [getCountry, { data, loading }] = useLazyQuery(GET_COUNTRY)

  const [
    geCraftmen,
    { data: dataCraftmen, loading: loadingCraftmen, refetch },
  ] = useLazyQuery(GET_CRAFTMEN)

  const colorsGlobal = [colors.blue[400], colors.green[400]]
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(0)

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ name: { search: query } }, { email: { search: query } }],
        }
      : {}
  }, [query])

  useEffect(() => {
    geCraftmen({
      variables: {
        take: size,
        skip: page * size,
        orderBy: {
          NoticeOrders: {
            _count: SortOrder.Desc,
          },
        },
        where: {
          role: {
            equals: Role.Craftman,
          },
          countryId: {
            equals: id,
          },
          ...queryName(),
        },
        aggregateUserWhere2: {
          role: {
            equals: Role.Craftman,
          },
          countryId: {
            equals: id,
          },
          ...queryName(),
        },
      },
    })
  }, [id, page, queryName])

  useEffect(() => {
    getCountry({
      variables: {
        where: {
          id,
        },
        usersWhere2: {
          role: {
            equals: Role.Craftman,
          },
          countryId: {
            equals: id,
          },
        },
      },
    })
  }, [id])

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier une catégorie"))
    navigate(`/country/edit/${id}`)
  }

  const getStatistic = useCallback(() => {
    const value_order = data?.country?._count?.Orders || 0
    const value_craftman = data?.country?._count?.Users || 0
    return !value_order && !value_craftman
      ? []
      : [
          {
            label: "Nombre commande",
            value: value_order,
            color: colorsGlobal[0],
          },
          {
            label: "Nombre artisan",
            value: value_craftman,
            color: colorsGlobal[1],
          },
        ]
  }, [data])

  const handleGoDetailCratman = (id: number) => {
    dispatch(setTitle("Detail d'un artisan"))
    navigate(`/craftman/detail/${id}`)
  }

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      { accessorKey: "id", header: "ID", enableEditing: false, size: 80 },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "lastName",
        header: "Prénom",
      },
    ],
    [],
  )

  const handleAddCraftman = () => {
    dispatch(setTitle("Ajouter un artisan"))
    navigate(`/craftman/add`)
  }

  const handleRefresh = () => {
    window.location.reload();
  }

  if (loading && !page && !query) return <Loader />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HeaderAdd title="Information de pays" variant="h4" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Nom en fr:</b> {data?.country?.name_fr}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Nom en en:</b> {data?.country?.name_en}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>Devise: </b> {data?.country?.currency}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      {havePermissions(["country.change"]) && (
                        <Button
                          onClick={() => handleEdit(data?.country?.id || 0)}
                          sx={{ mr: 1 }}
                          color="info"
                          variant="outlined"
                          size="small"
                        >
                          Modifier
                        </Button>
                      )}
                      {havePermissions(["craftman.create"]) && (
                        <Button
                          onClick={handleAddCraftman}
                          color="success"
                          variant="outlined"
                          size="small"
                        >
                          Ajouter un artisan
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <HeaderAdd title="Statistique de pays" variant="h4" />
                </Grid>
                <Grid item xs={12}>
                  {getStatistic().length ? (
                    <Chart data={getStatistic()} />
                  ) : (
                    <NoDataStatistic />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <HeaderAdd title="Liste des artisans" variant="h4" />
            </Grid>
            <Grid item xs={12} mt={2}>
              <CustomTable
                columns={columns}
                data={dataCraftmen?.users || []}
                lableAddNew=""
                rootLisName={""}
                isLoading={loadingCraftmen}
                showProgressBars={loadingCraftmen}
                rowCount={dataCraftmen?.aggregateUser?._count?.id || 0}
                handleChangePagination={(value) => setPage(value.page)}
                pageIndex={page}
                pageSize={size}
                searchValue={query}
                onChangeSearchValue={setQuery}
                isHaveView={true}
                handleView={handleGoDetailCratman}
                rowsPerPageOptions={[size]}
                handleRefresh={handleRefresh}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DetailCountry
