import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { isSuperAdmin, whereInputClause } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"

const LIST_CATEGORIES = graphql(`
  query CategoriesList(
    $where: CategoryWhereInput
    $take: Int
    $skip: Int
    $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    categories(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name_fr
    }
  }
`)

interface Props {
  segmentationId?: number
  craftmanId?: number
  getCategory: (category: InfinityListItem) => void
  categoryDefault: InfinityListItem
}
const CustomListcategory = ({
  getCategory,
  categoryDefault,
  segmentationId,
  craftmanId,
}: Props) => {
  const [category, setcategory] = useState(categoryDefault)

  const [querycategory, setQuerycategory] = useState("")
  const [opencategory, setOpencategory] = useState(false)
  const size = 500

  const countryId = getUserCountryId()
  const whereCluase = whereInputClause({
    Countries: {
      some: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const queryName = useCallback(() => {
    return querycategory || segmentationId || craftmanId
      ? {
          ...(querycategory && {
            OR: [
              {
                name_fr: { search: querycategory, mode: QueryMode.Insensitive },
              },
              {
                name_en: { search: querycategory, mode: QueryMode.Insensitive },
              },
              {
                name_en: {
                  endsWith: querycategory,
                  mode: QueryMode.Insensitive,
                },
              },
              {
                name_fr: {
                  endsWith: querycategory,
                  mode: QueryMode.Insensitive,
                },
              },
              {
                name_fr: {
                  contains: querycategory,
                  mode: QueryMode.Insensitive,
                },
              },
              {
                name_en: {
                  contains: querycategory,
                  mode: QueryMode.Insensitive,
                },
              },
              {
                name_fr: {
                  startsWith: querycategory,
                  mode: QueryMode.Insensitive,
                },
              },
              {
                name_en: {
                  startsWith: querycategory,
                  mode: QueryMode.Insensitive,
                },
              },
            ],
          }),
          ...(segmentationId && {
            segmentationId: {
              equals: segmentationId,
            },
          }),
          ...(craftmanId && {
            Users: {
              some: {
                id: {
                  equals: craftmanId,
                },
              },
            },
          }),
        }
      : {}
  }, [querycategory, segmentationId, craftmanId, isSuperAdmin])

  const { loading, data, fetchMore } = useQuery(LIST_CATEGORIES, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name_fr: SortOrder.Asc },
      where: {
        ...whereCluase,
        ...queryName(),
      },
    },
  })

  const handleSearchcategory = (query: string) => {
    setQuerycategory(query)
  }

  const handleClosecategory = () => {
    setOpencategory(false)
  }
  const handleOpencategory = async () => {
    setOpencategory(true)
  }

  const onEndReachedcategory = () => {
    fetchMore({
      variables: {
        skip: data?.categories.length,
      },
    })
  }

  useEffect(() => {
    if (!category?.value && categoryDefault?.value) {
      setcategory(categoryDefault)
    }
  }, [category.value, categoryDefault])

  const datacategory = data?.categories

  const categorys =
    loading || !datacategory
      ? []
      : datacategory.map((el) => ({ label: el.name_fr, value: el.id }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleClosecategory}
      handleOpen={handleOpencategory}
      open={opencategory}
      defaultValue={categoryDefault}
      keyName="label"
      label="Catégorie"
      onSearch={handleSearchcategory}
      options={opencategory ? categorys : []}
      loading={loading}
      onChange={(value) => {
        if (value) {
          setcategory(value)
          getCategory(value)
        }
      }}
      query={querycategory}
      value={category}
      onEndReached={onEndReachedcategory}
      required={true}
    />
  )
}

export default CustomListcategory
