import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import FormTransporterPrice from "../FormTransporterPrice/FormTransporterPrice"
import { updateCacheTransporterPrice } from "../../../caches/updateCacheTransporterPrice"
import { InfinityListItem } from "../../../types/InfinityListItem"
import {
  selecttransporterPriceTransporters,
  selecttransporterPriceZones,
  setTransporterPriceTransporters,
  setTransporterPriceZones,
} from "../../../store/features/transporterPrice/transporterPriceSlice"

// Signin mutation
const addTransporterPriceMutation = graphql(`
  mutation addTransporterPrice($data: TransporterPriceCreateInput!) {
    createOneTransporterPrice(data: $data) {
      id
      weightMin
      weightMax
      price
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
      Transporters {
        id
        name
      }
    }
  }
`)

export default function AddTransporterPrice() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [transporterprice, setTransporterPrice] = useState({
    weightMin: "",
    weightMax: "",
    price: "",
  })

  const transporters = useAppSelector(selecttransporterPriceTransporters)
  const transporterZones = useAppSelector(selecttransporterPriceZones)

  const [createTransporterPrice, { loading }] = useMutation(
    addTransporterPriceMutation,
  )

  const handleInputChange = (event: any) => {
    setTransporterPrice({
      ...transporterprice,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateTransporterPrice = (e: any) => {
    e.preventDefault()
    if (!transporters.length) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les transporteurs qui sont inclus dans ce tarif",
        }),
      )
      return
    }
    if (!transporterZones.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir les zones qui sont inclus dans ce tarif",
        }),
      )
      return
    }
    createTransporterPrice({
      variables: {
        data: {
          weightMin: +transporterprice.weightMin,
          weightMax: +transporterprice.weightMax,
          price: +transporterprice.price,
          Transporters: {
            connect: transporters.map((el) => ({ id: +el.value })),
          },
          TransporterZones: {
            connect: transporterZones.map((el) => ({ id: +el.value })),
          },
        },
      },
      onCompleted: () => {
        navigate("/transporter-price/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporterPrice({
          action: "add",
          cache,
          entryData: data?.createOneTransporterPrice,
        })
      },
    })
  }

  const getTransporter = (transporters: InfinityListItem[]) => {
    dispatch(setTransporterPriceTransporters(transporters))
  }

  const getTransporterZone = (transporterZones: InfinityListItem[]) => {
    dispatch(setTransporterPriceZones(transporterZones))
  }

  return (
    <FormTransporterPrice
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateTransporterPrice}
      loading={loading}
      price={transporterprice.price}
      weightMax={transporterprice.weightMax}
      weightMin={transporterprice.weightMin}
      title="Ajouter un tarif de transport"
      getTransporter={getTransporter}
      getTransporterZone={getTransporterZone}
      transporterDefault={transporters}
      transporterZoneDefault={transporterZones}
    />
  )
}
