import { gql } from "@apollo/client"

export const TRANSPORTERZONE_FRAGMENT = gql(`
  fragment TransporterZoneFragment on TransporterZone {
    id
    name
    Countries {
      id
      name_fr
    }
  }
`)
