import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import FormCategory from "../FormCategory/FormCategory"
import { updateCacheCategory } from "../../../caches/updateCacheCategory"
import { InfinityListItem } from "../../../types/InfinityListItem"

const createOneCategory = graphql(`
  mutation createOneCategory($data: CategoryCreateInput!) {
    createOneCategory(data: $data) {
      id
      name_fr
      name_en
      Segmentation {
        id
        name_fr
        name_en
      }
      Countries {
        id
        name_fr
      }
    }
  }
`)

export default function AddCategory() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [country, setCountry] = useState<InfinityListItem[]>([])

  const [category, setCategory] = useState({
    name_fr: "",
    name_en: "",
  })

  const [createCategory, { loading }] = useMutation(createOneCategory)
  const [segmentation, setSegmentation] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const getSegmentaion = (value: InfinityListItem) => {
    setSegmentation(value)
  }

  const handleInputChange = (event: any) => {
    setCategory({
      ...category,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateSegmentation = (e: any) => {
    e.preventDefault()
    if (!country.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez préciser le pays, s'il vous plait.",
        }),
      )
      return
    }
    createCategory({
      variables: {
        data: {
          ...category,
          Segmentation: {
            connect: { id: Number(segmentation.value) },
          },
          Countries: {
            connect: country.map((el) => ({ id: +el.value })),
          },
        },
      },
      onCompleted: () => {
        navigate("/category/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCategory({
          action: "add",
          cache,
          entryData: data?.createOneCategory,
        })
      },
    })
  }

  const getCountry = (value: InfinityListItem[]) => {
    if (value) {
      setCountry(value)
    } else {
      setCountry([])
    }
  }
  return (
    <FormCategory
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateSegmentation}
      loading={loading}
      name_en={category.name_en}
      name_fr={category.name_fr}
      title="Ajouter une catégorie"
      getSegmentaion={getSegmentaion}
      segmentation={segmentation}
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
