import { Button, Grid, Paper, TextField } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import { InfinityListItem } from "../../../types/InfinityListItem"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  name: string
  loading: boolean
  title: string
  getCountry: (country: InfinityListItem[]) => void
  countryDefault: InfinityListItem[]
}

export default function FormTransporterZone({
  handleSubmit,
  handleInputChange,
  name,
  loading,
  title,
  countryDefault,
  getCountry,
}: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  label="Nom de zone géographique"
                  fullWidth
                  variant="outlined"
                  value={name}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomListCountry
                  countryDefault={countryDefault}
                  getCountry={(value) =>
                    getCountry(value as InfinityListItem[])
                  }
                  multiple
                  label="Pays inclus dans la zone géographique"
                  required={false}
                />
              </Grid>

              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
}
