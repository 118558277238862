import { styled } from "@mui/material"
import { CardinalDirections } from "@mui/x-charts"
import { PieChart as Pie, pieArcLabelClasses } from "@mui/x-charts/PieChart"

const StyledPieChart = styled(Pie)(({ theme }) => ({
  "& .MuiChartsLegend-root": {
    backgroundColor: "white", // Change the background color here
  },
}))

interface Data {
  label: string
  value: number
  color: string
}

interface Props {
  data: Data[]
  margin?: Partial<CardinalDirections<number>> | undefined
}

function Chart({ data, margin }: Props) {
  return (
    <StyledPieChart
      margin={{ top: 20 }}
      colors={data.map((item) => item.color)}
      series={[
        {
          arcLabel: (item) => `${item.value}`,
          arcLabelMinAngle: 10,
          data: data,
          highlightScope: { faded: "series", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "#F8F4E8" },
          type: "pie",
        },
      ]}
      slotProps={{
        legend: {
          direction: "column",
          position: {
            vertical: "top",
            horizontal: "right",
          },
          padding: 10,
          labelStyle: {
            fontSize: 14,
            fill: "#F8F4E8",
          },
          markGap: 1,
        },
      }}
      height={240}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
        },
      }}
    />
  )
}

export default Chart
