import { gql } from "@apollo/client"

export const ITEMTYPE_FRAGMENT = gql(`
  fragment ItemTypeFragment on ItemType {
    id
    name_fr
    weight
    name_en
    Category {
        id
        name_fr
        name_en
    }
    Countries {
      id
      name_fr
    }
  }
`)
