import StarIcon from "@mui/icons-material/Star"
import { Box } from "@mui/material"

const StarRating = ({ note }: { note: number }) => {
  return (
    <Box display={"flex"} flexDirection={"row"}>
      {[1, 2, 3, 4, 5].map((index) => (
        <StarIcon
          key={index}
          style={{ color: index <= note ? "gold" : "gray" }}
          fontSize="small"
        />
      ))}
    </Box>
  )
}

export default StarRating
